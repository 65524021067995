import React from "react";
import styled from "styled-components";
import HoverUpView from "../../components/hoverUp";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import { doOptions } from "../../utils/datas";
import { useNavigate } from "react-router";

const PlaceWrapper = styled.div`
  position: relative;
`;
const PlaceHeaderWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
`;
const PlaceHeaderTitleWrapper = styled.div``;
const PlaceHeaderTitle = styled.div`
  ${fontSize(20, "B", "white")}
  margin-bottom: 10px;
`;
const PlaceHeaderDesc = styled.div`
  ${fontPSize(14, "R", "white")}
`;
const PlaceContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 6px;
  align-items: center;
  justify-content: center;
`;
const PlaceContentContainer = styled.div`
  // margin: 5px;
  display: flex;
  // flex: 1 29%;
  width: 32%;
  &:nth-child(-n + 2) {
    // flex: 1 1 40%;
    width: 49%;
    & > div > div {
      padding-bottom: 100%;
    }
  }
`;
const PlaceContent = styled.div`
  position: relative;
  padding-bottom: 150%;
`;
const PlaceContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
`;
const PlaceContentText = styled.div`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  ${fontSize(20, "B", "white")}
`;
const PlaceFooterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  background: #fff;
  border-radius: 10px;
  padding: 3% 0px 1.5% 0;
  margin: 6% 0;
  align-items: center;
  text-align: center;
`;
const PlaceFooterContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > div {
    flex: 1;
    margin: 4%;
    & > pre {
      margin: 0;
      ${fontSize(14, "L", "black")}
    }
  }
`;
const PlaceFooterIcon = styled.img`
  width: 6.66vw;
  min-width: 24px;
`;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const VideoView = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -100;
`;

const HomePlace = () => {
  const navigator = useNavigate();
  return (
    <section>
      <PlaceWrapper>
        <VideoWrapper>
          {/* <VideoView src="https://d17mmzdc0h66bb.cloudfront.net/video/car.mp4" autoPlay loop muted playsInline /> */}
          <ImageView src="https://d17mmzdc0h66bb.cloudfront.net/image/car_image.jpg" />
        </VideoWrapper>
        <BackgroundView />
        <Layout style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <PlaceHeaderWrapper>
            <PlaceHeaderTitleWrapper>
              <PlaceHeaderTitle>배송지역</PlaceHeaderTitle>
              <PlaceHeaderDesc>
                세왕상사의 주류배송지역 정보를 제공합니다.
              </PlaceHeaderDesc>
            </PlaceHeaderTitleWrapper>
          </PlaceHeaderWrapper>
          <PlaceContentWrapper>
            {doOptions
              .filter((d) => d.value !== "0")
              .map((d) => (
                <PlaceContentContainer
                  key={d.value}
                  onClick={() => {
                    navigator(
                      `/contact?index=0&prov_id=${d.value}&liquors_name=전체`
                    );
                  }}
                >
                  {/* <HoverUpView> */}
                  <div style={{ flex: 1 }}>
                    <PlaceContent>
                      <PlaceContentImage src={d.imageSrc} />
                      <PlaceContentText>{d.label}</PlaceContentText>
                    </PlaceContent>
                  </div>
                  {/* </HoverUpView> */}
                </PlaceContentContainer>
              ))}
          </PlaceContentWrapper>
          <PlaceFooterWrapper>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <div>
                <PlaceFooterIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/location-on.png" />
              </div>
              <div>
                <pre>{"지역별\n담당자"}</pre>
              </div>
            </PlaceFooterContent>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <div>
                <PlaceFooterIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/liquor.png" />
              </div>
              <div>
                <pre>{"주류별\n담당자"}</pre>
              </div>
            </PlaceFooterContent>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <div>
                <PlaceFooterIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/edit-note.png" />
              </div>
              <div>
                <pre>{"배송\n상담 신청"}</pre>
              </div>
            </PlaceFooterContent>
          </PlaceFooterWrapper>
        </Layout>
      </PlaceWrapper>
    </section>
  );
};

export default HomePlace;
