import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import useCountNum from "../../hooks/useCountNum";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { numberWithCommas } from "../../utils/string";
import { apiFetch } from "../../utils/fetch";

const BottleWrapper = styled.div`
  background-color: #fff;
  font-size: 50px;
  position: relative;
  margin: 50px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 10px;
`;
const CarWrapper = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  align-items: flex-end;
  position: relative;
`;
const CountText = styled.div`
  text-wrap: nowrap;
`;

const TextWrapper = styled.div`
  // position: absolute;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  ${fontSize(30, "L", "black")}
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  ${fontSize(70, "B", "black")}
`;

const ImageWrapper = styled.div`
  margin-right: 30px;
`;
const HomeBottle = () => {
  // const animationRef = useScrollAnimation({})
  const sectionRef = useRef<HTMLInputElement | null>(null);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  const { inViewPort, percentage } = useScrollAnimation(sectionRef, {
    min: 80,
    max: 150,
  });
  const { setIsStart, count, setEnd } = useCountNum();

  useEffect(() => {
    apiFetch("api/v2.0/count", "GET").then((data) => {
      setEnd(data.result.bottleCount);
    });
    // setEnd(434615);
  }, []);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    if (inViewPort) {
      if (percentage > 0) {
        setIsStart(true);
      }
    }
  }, [inViewPort, percentage]);

  const wbwidth = (number: number) => {
    const newWidth = number + ((innerWidth - 1920) * number) / 1920;
    return newWidth > number ? number : newWidth;
  };
  // window.innerWidth > 1500 ? number : (number * window.innerWidth) / 1500;
  const carStyle: (
    _margin: number,
    percentage: number,
    initial?: boolean
  ) => CSSProperties = (_margin, percentage, initial) => {
    const margin = wbwidth(_margin);
    const change = -1 * (margin - (margin * (100 - percentage)) / 100);
    const opacity = percentage / 100;
    return {
      position: initial ? "initial" : "absolute",
      right: "0%",
      transform: `translate3d(${change}px, 0px, 0px)`,
      left: "auto",
      // marginRight: `${margin}px`,
      opacity,
    };
  };
  return (
    <section ref={sectionRef}>
      <BottleWrapper>
        <Layout>
          <ContentWrapper>
            <TextWrapper>
              <CountWrapper>
                <ImageWrapper>
                  <img
                    width={wbwidth(68)}
                    src="https://d17mmzdc0h66bb.cloudfront.net/image/bottleIcon.png"
                  />
                </ImageWrapper>
                <div>{numberWithCommas(count)}</div>
              </CountWrapper>
              <CountText>오늘의 주류 출고수</CountText>
            </TextWrapper>
            <CarWrapper>
              <div style={carStyle(750, percentage)}>
                <img
                  style={{
                    width: `calc(344px + (100vw - 1920px) * 344/1920)`,
                    maxWidth: "344px",
                  }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/t1.png"
                />
              </div>
              <div
                style={{
                  ...carStyle(600, percentage),
                  height: `calc(164px + (100vw - 1920px) * 164/1920)`,
                  maxHeight: "164px",
                }}
              >
                <img
                  style={{
                    width: `calc(284px + (100vw - 1920px) * 284/1920)`,
                    maxWidth: "284px",
                  }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/t2.png"
                />
              </div>
              <div style={carStyle(250, percentage)}>
                <img
                  style={{
                    width: `calc(494px + (100vw - 1920px) * 494/1920)`,
                    maxWidth: "494px",
                  }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/t3.png"
                />
              </div>
              <div style={carStyle(20, percentage, true)}>
                <img
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/t4.png"
                  style={{
                    width: `calc(526px + (100vw - 1920px) * 526/1920)`,
                    maxWidth: "526px",
                  }}
                />
              </div>
            </CarWrapper>
          </ContentWrapper>
        </Layout>
      </BottleWrapper>
      {/* <BottleWrapper {...animationRef}>HomeBottle</BottleWrapper> */}
    </section>
  );
};

export default HomeBottle;
