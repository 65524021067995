import React, { useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { Slide, SlideshowRef } from "react-slideshow-image";
import { imageParser } from "../../utils/string";
import useBoardStore from "../../stores/boardStore";
import { useNavigate } from "react-router";

const NoticeWrapper = styled.div`
  background-color: #f4f6fa;
  position: relative;
  padding: 50px 0;
`;
const NoticeContainer = styled.div`
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
`;
const NoticeTop = styled.div`
  display: flex;
`;
const NoticeBottom = styled.div`
  display: flex;
`;
const NoticeLeft = styled.div`
  flex: 7;
  position: relative;
  overflow: hidden;
  & ul.indicators {
    position: absolute;
    top: 1vw;
    right: 4vw;
    padding: 0px;
    justify-content: end;
  }
`;
const NoticeRight = styled.div`
  flex: 3;
  display: flex;
  align-items: end;
`;
const NoticeHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 0;
`;
const NoticeHeaderImageWrapper = styled.div`
  margin-right: 2.5vw;
  & > img {
    max-width: 100px;
    width: 7vw;
  }
`;
const NoticeHeaderTitleWrapper = styled.div``;
const NoticeHeaderTitle = styled.div`
  ${fontSize(60, "B", "black")}
`;
const NoticeHeaderDesc = styled.div`
  ${fontSize(30, "L", "black")}
`;
const CardWrapper = styled.div`
  border-radius: 30px;
  border: 1px solid rgba(119, 119, 119, 1);
  background: rgb(255, 255, 255);
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  max-height: 400px;
`;
const CardImageWrapper = styled.div`
  border-bottom: 1px solid rgba(119, 119, 119, 1);
  object-fit: cover;
  overflow: hidden;
  position: relative;
  padding-bottom: 78%;
`;
const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  height: 100%;
`;
const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw;
  justify-content: space-around;
  flex: 1;
`;
const CardTitle = styled.div`
  ${fontSize(25, "L", "black")}
  margin-bottom: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const CardDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontSize(15, "L", "#777")}
`;
const CardDate = styled.div``;
const CardLink = styled.div``;
const ArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50px;
  right: 0px;
  align-items: center;
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  border-radius: 100px;
  width: 4vw;
  max-width: 60px;
`;

const Indicators = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Indicator = styled.div`
  border: 1px solid #000;
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
  margin-right: 0.5vw;
  cursor: pointer;
  max-width: 14px;
  max-height: 14px;
  &.active {
    background: #000;
  }
`;

const LinkWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
  border: 1px solid rgb(119, 119, 119);
  background: rgb(255, 255, 255);
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 400px;
  height: 100%;
}
`;

const LinkTop = styled.div`
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: radial-gradient(
    55.93% 55.93% at 50% 60.57%,
    rgb(204, 239, 245) 20.31%,
    rgb(255, 255, 255) 100%
  );
  position: absolute;
  width: 100%;
  height: 50%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
`;
const TopText = styled.div`
  ${fontSize(40, "B", "black")}
  position: absolute;
  padding-left: 40px;
  font-size: 40px;
  font-family: SB_Aggro_B;
  color: black;
  top: 33%;
`;
const TopImage = styled.img`
  object-fit: contain;
  width: 100%;
  position: absolute;
  bottom: 0;
`;
const LinkBottom = styled.div`
  position: relative;
  flex: 1 1 0%;
  display: flex;
  height: 50%;
`;
const LinkLeft = styled.div`
  flex: 1 1 0%;
  background: #137fb9;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  width: 50%;
  left: 0;
  bottom: 0;
  /* width: 100%; */
  height: 100%;
`;
const LinkRight = styled.div`
  flex: 1 1 0%;
  background: #3ca237;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
`;
const LinkText = styled.div`
  word-break: keep-all;
  padding: 40px;
  ${fontSize(20, "L", "white")}
`;
const LinkIcon = styled.img`
  width: 2.5vw;
  max-width: 50px;
`;
const HomeNotice = () => {
  const slideRef = useRef<SlideshowRef>(null);
  const navigator = useNavigate();
  const { boards } = useBoardStore();
  const [indicator, setIndicator] = useState<number>(0);
  return (
    <section>
      <NoticeWrapper>
        <Layout>
          <NoticeContainer>
            <NoticeTop>
              <NoticeLeft>
                <NoticeHeaderWrapper>
                  <NoticeHeaderImageWrapper>
                    <img src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeIcon.png" />
                  </NoticeHeaderImageWrapper>
                  <NoticeHeaderTitleWrapper>
                    <NoticeHeaderTitle>세왕소식</NoticeHeaderTitle>
                    <NoticeHeaderDesc>
                      세왕상사의 최근 소식을 알려드립니다.
                    </NoticeHeaderDesc>
                  </NoticeHeaderTitleWrapper>
                  <ArrowWrapper>
                    <Indicators>
                      {boards.map((item: any, index: number) => (
                        <Indicator
                          key={index}
                          className={indicator === index ? "active" : ""}
                          onClick={() => {
                            slideRef.current?.goTo(index);
                          }}
                        />
                      ))}
                    </Indicators>
                    <Arrow
                      src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                      onClick={() => slideRef.current?.goBack()}
                    />
                    <Arrow
                      src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                      onClick={() => slideRef.current?.goNext()}
                    />
                  </ArrowWrapper>
                </NoticeHeaderWrapper>
              </NoticeLeft>
              <NoticeRight />
            </NoticeTop>
            <NoticeBottom>
              <NoticeLeft>
                {boards.length > 0 && (
                  <Slide
                    slidesToShow={3}
                    ref={slideRef}
                    arrows={false}
                    transitionDuration={200}
                    onChange={(from, to) => {
                      setIndicator(to);
                    }}
                    indicators={false}
                  >
                    {boards.map((d) => (
                      <CardWrapper
                        key={d.id}
                        onClick={() => {
                          navigator(`/news?id=${d.id}`);
                        }}
                      >
                        <CardImageWrapper>
                          <CardImage src={imageParser(d.desc)} />
                        </CardImageWrapper>
                        <CardTextWrapper>
                          <CardTitle>{d.title}</CardTitle>
                          <CardDescWrapper>
                            <CardDate>{d.date}</CardDate>
                            <CardLink
                              onClick={() => {
                                if (d.link_url) {
                                  window.open(d.link_url);
                                }
                              }}
                            >
                              {d.link_url && (
                                <img src="https://d17mmzdc0h66bb.cloudfront.net/image/nLink.png" />
                              )}
                            </CardLink>
                          </CardDescWrapper>
                        </CardTextWrapper>
                      </CardWrapper>
                    ))}
                  </Slide>
                )}
              </NoticeLeft>
              <NoticeRight>
                <LinkWrapper>
                  <LinkTop>
                    <TopText>
                      <div style={{ display: "inline", color: "#137FB9" }}>
                        세왕
                      </div>{" "}
                      소식통
                    </TopText>
                    <TopImage src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink1.png" />
                  </LinkTop>
                  <LinkBottom>
                    <LinkLeft>
                      <LinkText>
                        <LinkIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink2.png" />
                        <div>세왕상사 회사소개서</div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div
                            style={{
                              border: "1px solid",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                "https://d17mmzdc0h66bb.cloudfront.net/upload/%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5(2024).pdf"
                              );
                            }}
                          >
                            한국어
                          </div>
                          <div
                            style={{
                              border: "1px solid",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                "https://d17mmzdc0h66bb.cloudfront.net/upload/%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A52024(%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%8B%E1%85%A5).pdf"
                              );
                            }}
                          >
                            日本語
                          </div>
                        </div>
                      </LinkText>
                    </LinkLeft>
                    <LinkRight
                      onClick={() =>
                        window.open("https://blog.naver.com/sewang88")
                      }
                    >
                      <LinkText>
                        <LinkIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/noticeLink3.png" />
                        <div>세왕상사 공식블로그</div>
                      </LinkText>
                    </LinkRight>
                  </LinkBottom>
                </LinkWrapper>
              </NoticeRight>
            </NoticeBottom>
          </NoticeContainer>
        </Layout>
      </NoticeWrapper>
      {/* <NoticeWrapper {...animationRef}>HomeNotice</NoticeWrapper> */}
    </section>
  );
};

export default HomeNotice;
