import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { infoManagementData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: #fff;
  padding: 10% 0 0 0;
`;
const HeaderWrapper = styled.div`
  padding: 5% 20px;
`;
const Header = styled.div`
  ${fontSize(20, "M", "black")}
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const Content = styled.div`
  padding-bottom: 32%;
`;
const ContentImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;
const ContentTitle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 25px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 10px #000000cc;

  ${fontSize(20, "M", "white")}
`;

const InfoManagement = () => {
  return (
    <section>
      <PageWrapper>
        <HeaderWrapper>
          <Header>경영 방침</Header>
        </HeaderWrapper>
        <ContentWrapper>
          {infoManagementData.map((d, index) => (
            <ContentContainer key={d.id}>
              <Content className={index % 2 === 1 ? "down" : ""}>
                <ContentImage src={d.mobile_image} />
                <ContentTitle>{d.title}</ContentTitle>
              </Content>
            </ContentContainer>
          ))}
        </ContentWrapper>
      </PageWrapper>
    </section>
  );
};

export default InfoManagement;
