import React, { useEffect, useRef, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import { apiFetch, uploadFileApi } from "../../utils/fetch";
import queryString from "query-string";
import useContactStore from "../../stores/contactStore";
import useAuthStore from "../../stores/authStore";
import { MultiSelect } from "react-multi-select-component";

const PageWrapper = styled.div`
  padding: 0 0px 10% 0;
`;
const Wrapper = styled.div`
  padding-bottom: 7%;
  padding-top: 2%;
`;

const Container = styled.div`
  display: flex;
  gap: 4%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 30%;
  position: relative;
  padding-bottom: 18%;
  margin: 1.5% 0px;
  // cursor: pointer;
`;
const ContentImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 88%;
  width: 42%;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const ContentTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 88%;
  width: 93%;
  background-color: #f3f5fb;
  display: flex;
  justify-content: flex-end;
`;

const ContentTextContainer = styled.div`
  width: 43%;
  padding: 10%;
  overflow: hidden;
`;
const ContentType = styled.div`
  ${fontPSize(18, "R", "rgba(86, 86, 86, 1)")}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ContentTitle = styled.div`
  ${fontSize(20, "L", "black")}
  padding: 3% 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
`;
const ContentDesc = styled.div`
  padding-top: 18%;
  ${fontSize(18, "L", "rgba(86, 86, 86, 1)")}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  & > p {
    margin: 0;
    margin-bottom: 5%;
    word-break: break-all;
  }
`;

const PageNationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
  margin: auto;
  gap: 5%;
`;
const PageNationContent = styled.div`
  cursor: pointer;
`;
const PageNationText = styled.div`
  ${fontSize(16, "M", "#b1b1b1")}
  &.active {
    ${fontSize(16, "M", "rgba(19, 127, 185, 1)")}
  }
`;

const PageNationIcon = styled.img`
  width: 100%;

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(270deg);
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ModalButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5%;
`;
const EditButton = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 3%;
`;

const DeleteButton = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(206, 65, 106, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 3%;
`;

const ModalContainer = styled.div`
  position: absolute;
  z-index: 999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ModalBackground = styled.div`
  position: absolute;
  z-index: 999998;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 5%;
`;
const ModalWrapper = styled.div`
  z-index: 999999;
  background: #fff;
  border-radius: 5vw;
  display: flex;
  flex-direction: column;
  padding: 5%;
  position: sticky;
  top: 5%;
  bottom: 5%;
  overflow: visible;
`;
const ModalCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ModalContent = styled.div`
  overflow: visible;
  max-height: 75vh;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const ModalImageWrapper = styled.div`
  width: 18vw;
  height: 24vw;
  max-width: 210px;
  max-height: 280px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & > div {
    width: 100%;
    height: 100%;
    background: #777;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;

const ModalLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5vw;
  margin-bottom: 1vw;
  width: 100%;
`;
const ModalInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  flex: 1;
  align-items: center;
  & > .multi-select {
    flex: 1;
  }
`;
const ModalLabel = styled.div``;
const ModalInput = styled.input`
  padding: 0.8vw 0.2vw;
`;

const ModalTextWrapper = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const ContactPeople = () => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const { loginState } = useAuthStore();
  // const { showModal } = useModalStore();
  const {
    provs,
    liquors,
    setContactPeopleTotalCount,
    setContactPeoples,
    contactPeoples,
    contactPeopleTotalCount,
  } = useContactStore();
  const { search } = useLocation();
  const navigator = useNavigate();
  // const { setPeopleTotalCount, setPeoples, peoples, peopleTotalCount } =
  //   usePeopleStore();
  const [isShow, setIsShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(1); // 총페이지수
  const initData: ContactPeople = {
    email: "",
    name: "",
    id: 0,
    position: "",
    phone: "",
    photo: "",
    work_liquor: [],
    work_prov: [],
  };
  const [selectData, setSelectData] = useState<ContactPeople>(initData);

  const imageUploadFn = (e: any) => {
    // let sendData = new FormData();
    const files = e.target.files;
    // for (const file of files) {
    //   sendData.append("file", file);
    // }
    uploadFileApi("/api/upload", files).then((data) => {
      const photo = data.path;
      // const photo = data.path.replace("http://0.0.0.0:6333", "");
      setSelectData((prev) => ({ ...prev, photo }));
    });
  };

  const openFn = (_selectData?: ContactPeople) => {
    if (_selectData) {
      setSelectData(_selectData);
    } else {
      setSelectData(initData);
    }
    setIsShow(true);
  };
  const saveFn = () => {
    if (
      selectData.email !== "" &&
      selectData.position !== "" &&
      selectData.name !== "" &&
      selectData.phone !== "" &&
      selectData.photo !== "" &&
      selectData.work_liquor.length > 0 &&
      selectData.work_prov.length > 0
    ) {
      if (selectData.id === 0) {
        apiFetch("/api/v2.0/locationUsers", "POST", selectData).then(() => {
          setIsShow(false);
          window.location.reload();
        });
      } else {
        apiFetch("/api/v2.0/locationUsers", "PUT", selectData).then(() => {
          setIsShow(false);
          window.location.reload();
        });
      }
    }
  };
  const deleteFn = () => {
    apiFetch("/api/v2.0/locationUsers", "DELETE", selectData).then(() => {
      setIsShow(false);
      window.location.reload();
    });
  };
  useEffect(() => {
    const _query =
      search && search !== "" ? queryString.parse(search) : undefined;
    if (_query?.index !== undefined && _query.index !== null) {
      const _index = parseInt(_query.index.toString());
      const prov_id = _query.prov_id;
      const liquors_name = _query.liquors_name;
      apiFetch(
        `/api/v2.0/locationUsers?offset=${
          _index * 6
        }&limit=6&liquors_name=${liquors_name}&prov_id=${prov_id}`,
        "GET"
      ).then((data) => {
        setContactPeoples(data[0]);
        setContactPeopleTotalCount(data[1]);
      });
      setIndex(_index);
      window.scrollTo(0, 0);
    }
  }, [search]);

  useEffect(() => {
    setMaxIndex(Math.ceil(contactPeopleTotalCount / 6));
  }, [contactPeopleTotalCount]);

  const renderPageNation = () => {
    const page = Math.floor(index / 10);
    const result = [];
    const first = page * 10;
    const last = page * 10 + 10;
    if (index > 0) {
      result.push(
        <PageNationContent
          key={"prev"}
          onClick={() => {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: index - 1,
            };
            navigator(`/contact?${query}`);
          }}
        >
          <PageNationIcon
            className="left"
            src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
          />
        </PageNationContent>
      );
    }
    for (let i = first; i < last; i++) {
      if (i + 1 > maxIndex) break;
      result.push(
        <PageNationContent
          key={`page_${i}`}
          onClick={() => {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: i,
            };
            navigator(`/contact?${query}`);
          }}
        >
          <PageNationText className={index === i ? "active" : ""}>
            {i + 1}
          </PageNationText>
        </PageNationContent>
      );
    }
    if (index < maxIndex - 1) {
      result.push(
        <PageNationContent
          key={"next"}
          onClick={() => {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: index + 1,
            };
            navigator(`/contact?${query}`);
          }}
        >
          <PageNationIcon
            className="right"
            src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
          />
        </PageNationContent>
      );
    }

    return result;
  };
  const getText = (liquors: any[], works: any[]) => {
    const liquorText = liquors.map((liquor) => liquor.label).join("/");
    const workText = works.map((work) => work.label).join("/");
    const text = [workText, liquorText].join(",");
    return text;
  };
  return (
    <>
      <section>
        <Layout>
          <PageWrapper>
            <Wrapper>
              {loginState && (
                <ButtonWrapper>
                  <EditButton onClick={() => openFn()}>추가</EditButton>
                </ButtonWrapper>
              )}
              <Container>
                {contactPeoples.map(
                  (d) =>
                    d && (
                      <Content
                        key={d.id}
                        onClick={() => {
                          if (loginState) {
                            openFn(d);
                          }
                        }}
                      >
                        <ContentTextWrapper>
                          <ContentTextContainer>
                            <ContentTitle>{`${d.name} ${d.position}`}</ContentTitle>
                            <ContentType>
                              {getText(d.work_liquor, d.work_prov)}
                            </ContentType>
                            <ContentDesc>
                              <p>{d.phone}</p>
                              <p>{d.email}</p>
                            </ContentDesc>
                          </ContentTextContainer>
                        </ContentTextWrapper>
                        <ContentImageWrapper>
                          <img src={d.photo} />
                        </ContentImageWrapper>
                      </Content>
                    )
                )}
              </Container>
            </Wrapper>
            <PageNationWrapper>{renderPageNation()}</PageNationWrapper>
          </PageWrapper>
        </Layout>
      </section>
      {isShow && (
        <ModalContainer>
          <ModalBackground onClick={() => setIsShow(false)} />
          <ModalWrapper>
            <ModalCloseWrapper onClick={() => setIsShow(false)}>
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/close.png" />
            </ModalCloseWrapper>
            <ModalContent>
              <ModalContentWrapper>
                <ModalImageWrapper
                  onClick={() => {
                    uploadRef.current?.click();
                  }}
                >
                  {selectData?.photo !== "" ? (
                    <img src={selectData?.photo} />
                  ) : (
                    <div>
                      <div>사진을 선택해주세요</div>
                    </div>
                  )}
                  <input
                    ref={uploadRef}
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={imageUploadFn}
                    accept="image/*"
                  />
                </ModalImageWrapper>
                <ModalTextWrapper>
                  <ModalLineWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>이름</ModalLabel>
                      <ModalInput
                        defaultValue={selectData?.name}
                        onChange={(e) => {
                          setSelectData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </ModalInputWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>직급</ModalLabel>
                      <ModalInput
                        defaultValue={selectData?.position}
                        onChange={(e) => {
                          setSelectData((prev) => ({
                            ...prev,
                            position: e.target.value,
                          }));
                        }}
                      />
                    </ModalInputWrapper>
                  </ModalLineWrapper>
                  <ModalLineWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>휴대폰</ModalLabel>
                      <ModalInput
                        defaultValue={selectData?.phone}
                        onChange={(e) => {
                          setSelectData((prev) => ({
                            ...prev,
                            phone: e.target.value,
                          }));
                        }}
                      />
                    </ModalInputWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>이메일</ModalLabel>
                      <ModalInput
                        defaultValue={selectData?.email}
                        onChange={(e) => {
                          setSelectData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                      />
                    </ModalInputWrapper>
                  </ModalLineWrapper>

                  <ModalLineWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>담당지역</ModalLabel>
                      <MultiSelect
                        options={provs}
                        value={selectData.work_prov}
                        hasSelectAll={false}
                        onChange={(value: WorkType[]) => {
                          setSelectData((prev) => ({
                            ...prev,
                            work_prov: value,
                          }));
                        }}
                        labelledBy=""
                      />
                    </ModalInputWrapper>
                  </ModalLineWrapper>

                  <ModalLineWrapper>
                    <ModalInputWrapper>
                      <ModalLabel>담당주류</ModalLabel>
                      <MultiSelect
                        options={liquors}
                        value={selectData.work_liquor}
                        hasSelectAll={false}
                        onChange={(value: WorkType[]) => {
                          setSelectData((prev) => ({
                            ...prev,
                            work_liquor: value,
                          }));
                        }}
                        labelledBy=""
                      />
                    </ModalInputWrapper>
                  </ModalLineWrapper>
                </ModalTextWrapper>
                <ModalButtonWrapper>
                  {selectData.id > 0 && (
                    <DeleteButton onClick={deleteFn}>삭제</DeleteButton>
                  )}
                  <EditButton onClick={saveFn}>저장</EditButton>
                </ModalButtonWrapper>
              </ModalContentWrapper>
            </ModalContent>
          </ModalWrapper>
        </ModalContainer>
      )}
    </>
  );
};

export default ContactPeople;
