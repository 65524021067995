import { create } from "zustand";

interface ModalState {
  isShow: boolean;
  modalData: any;
  overflow?: boolean;
  showModal: (modalData: any, overflow?: boolean) => void;
  hideModal: () => void;
}

const useModalStore = create<ModalState>()((set) => ({
  isShow: false,
  modalData: null,
  showModal: (modalData, overflow?: boolean) => {
    document.body.style.overflow = "hidden";
    set(() => ({ modalData, isShow: true, overflow }));
  },
  hideModal: () => {
    document.body.style.removeProperty("overflow");
    set(() => ({ modalData: null, isShow: false, overflow: undefined }));
  },
}));

export default useModalStore;
