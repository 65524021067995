import React, { ReactNode } from "react";
import styled from "styled-components";

type HoverUpViewProps = {
  children: ReactNode;
};

const HoverUpWrapper = styled.div`
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: transform 0.2s;
  flex: 1;
  &:hover {
    transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`;

const HoverUpView = (props: HoverUpViewProps) => {
  return <HoverUpWrapper>{props.children}</HoverUpWrapper>;
};

export default HoverUpView;
