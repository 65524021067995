import React from "react";
import styled from "styled-components";
import HoverUpView from "../../components/hoverUp";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { doOptions } from "../../utils/datas";
import { useNavigate } from "react-router";

const PlaceWrapper = styled.div`
  position: relative;
`;
const PlaceHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 10px;
`;
const PlaceHeaderImageWrapper = styled.div`
  margin-right: 2.5vw;
  & > img {
    max-width: 100px;
    width: 7vw;
  }
`;
const PlaceHeaderTitleWrapper = styled.div``;
const PlaceHeaderTitle = styled.div`
  ${fontSize(60, "B", "white")}
`;
const PlaceHeaderDesc = styled.div`
  ${fontSize(30, "L", "white")}
`;
const PlaceContentWrapper = styled.div`
  display: flex;
  // max-height: 447px;
  // height: calc(100vh * 0.44);
  padding: 0 10px;
`;
const PlaceContentContainer = styled.div`
  flex: 1;
  margin: 0.5vw;
  display: flex;
  cursor: pointer;
`;
const PlaceContent = styled.div`
  position: relative;
  padding-bottom: 160%;
`;
const PlaceContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  position: absolute;
  overflow: hidden;
`;
const PlaceContentText = styled.div`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  mix-blend-mode: normal;
  padding-bottom: 40px;
  padding-left: 40px;
  line-height: 48px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
  ${fontSize(40, "B", "white")}
`;
const PlaceFooterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  background: #fff;
  border-radius: 30px;
  padding: 1vh 0px;
  margin: 20px 10px;
  align-items: center;
`;

const PlaceFooterContent = styled.div`
  display: flex;
  // flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2% 0;
  & > div {
    ${fontSize(20, "L", "black")}
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > img {
    width: 3.3vw;
    max-width: 24px;
  }
`;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const VideoView = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -100;
`;

const HomePlace = () => {
  const navigator = useNavigate();
  return (
    <section>
      <PlaceWrapper>
        <VideoWrapper>
          {/* <VideoView src="https://d17mmzdc0h66bb.cloudfront.net/video/car.mp4" autoPlay loop muted playsInline /> */}
          <ImageView src="https://d17mmzdc0h66bb.cloudfront.net/image/car_image.jpg" />
        </VideoWrapper>
        <BackgroundView />
        <Layout style={{ padding: "50px 0" }}>
          <PlaceHeaderWrapper>
            <PlaceHeaderImageWrapper>
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/placeIcon.png" />
            </PlaceHeaderImageWrapper>
            <PlaceHeaderTitleWrapper>
              <PlaceHeaderTitle>배송지역</PlaceHeaderTitle>
              <PlaceHeaderDesc>
                세왕상사의 주류배송지역 정보를 제공합니다.
              </PlaceHeaderDesc>
            </PlaceHeaderTitleWrapper>
          </PlaceHeaderWrapper>
          <PlaceContentWrapper>
            {doOptions
              .filter((d) => d.value !== "0")
              .map((d) => (
                <PlaceContentContainer
                  onClick={() => {
                    navigator(
                      `/contact?index=0&prov_id=${d.value}&liquors_name=전체`
                    );
                  }}
                  key={d.value}
                >
                  <HoverUpView>
                    <PlaceContent>
                      <PlaceContentImage src={d.imageSrc} />
                      <PlaceContentText>{d.label}</PlaceContentText>
                    </PlaceContent>
                  </HoverUpView>
                </PlaceContentContainer>
              ))}
          </PlaceContentWrapper>
          <PlaceFooterWrapper>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/location-on.png" />
              <div>지역별 담당자</div>
            </PlaceFooterContent>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/liquor.png" />
              <div>주류별 담당자</div>
            </PlaceFooterContent>
            <PlaceFooterContent
              onClick={() => {
                navigator(`/contact?index=0&prov_id=0&liquors_name=전체`);
              }}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/edit-note.png" />
              <div>배송 상담 신청</div>
            </PlaceFooterContent>
          </PlaceFooterWrapper>
        </Layout>
      </PlaceWrapper>
    </section>
  );
};

export default HomePlace;
