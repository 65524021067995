import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  @media screen and (max-width: 766px) {
    min-width: 320px;
    padding: 0 20px;
  }
  @media screen and (min-width: 767px) {
    max-width: 100%;
  }
  @media screen and (min-width: 1440px) {
    // @media screen and (min-width: 1440px) and (max-width: 1920px) {
    max-width: 1440px;
  }
  // @media screen and (min-width: 1920px) {
  //   max-width: 78%;
  // }
`;

const Layout = (props: PropsWithChildren<{ style?: any }>) => {
  return (
    <LayoutContainer style={props?.style}>{props.children}</LayoutContainer>
  );
};

export default Layout;
