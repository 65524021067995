import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import HoverZoomView from "../../components/hoverZoom";
import { infoPanoramaData } from "../../utils/datas";

const PageWrapper = styled.div`
  padding: 7% 0;
`;
const HeaderWrapper = styled.div`
  padding: 3% 0;
  ${fontSize(34, "M", "black")}
`;
const Header = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  grid-gap: 20px;
  flex-wrap: wrap;
`;
const ContentContainer = styled.div`
  width: calc(33% - 20px);
  padding-bottom: 33%;
  position: relative;
  display: flex;
`;
const Content = styled.div`
  position: absolute;
  height: 100%;
  border-radius: 6%;
  overflow: hidden;
`;
const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentText = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  text-shadow: 0px 0px 10px #000000cc;
  ${fontSize(40, "L", "white")}
`;
const InfoPanorama = () => {
  return (
    <section>
      <Layout>
        <PageWrapper>
          <HeaderWrapper>
            <Header>회사 전경</Header>
          </HeaderWrapper>
          <ContentWrapper>
            {infoPanoramaData.map((d) => (
              <ContentContainer key={d.id}>
                <Content>
                  <HoverZoomView>
                    <ContentImage src={d.image} />
                  </HoverZoomView>
                  <ContentText>{d.title}</ContentText>
                </Content>
              </ContentContainer>
            ))}
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default InfoPanorama;
