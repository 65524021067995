import React from "react";
import styled from "styled-components";
import HoverZoomView from "../../components/hoverZoom";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import useContactStore from "../../stores/contactStore";

const ItemWrapper = styled.div`
  background-color: #fff;
  padding: 50px 0;
  position: relative;
`;
const ItemHeaderWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
`;
const ItemHeaderTitleWrapper = styled.div``;
const ItemHeaderTitle = styled.div`
  ${fontSize(20, "B", "black")}
  margin-bottom: 10px;
`;
const ItemHeaderDesc = styled.div`
  ${fontPSize(14, "R", "black")}
`;
const ItemContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
`;
const ItemContent = styled.div`
  flex: 1 1 40%;
  display: flex;
  position: relative;
  padding-bottom: 49%;
  width: 49%;
`;
const ItemContentImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #000;
  position: absolute;
`;
const ItemContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ItemContentText = styled.div`
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  line-height: 48px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  ${fontSize(20, "B", "white")}
`;

const HomeItem = () => {
  const { liquors } = useContactStore();
  return (
    <section>
      <Layout>
        <ItemWrapper>
          <ItemHeaderWrapper>
            <ItemHeaderTitleWrapper>
              <ItemHeaderTitle>취급주류</ItemHeaderTitle>
              <ItemHeaderDesc>
                세왕상사의 취급주류 정보를 제공합니다.
              </ItemHeaderDesc>
            </ItemHeaderTitleWrapper>
          </ItemHeaderWrapper>
          <ItemContentWrapper>
            {liquors.length > 0 &&
              liquors
                .filter((d) => d.label !== "전체")
                .map((d) => (
                  <ItemContent key={d.value}>
                    <ItemContentImageWrapper>
                      <HoverZoomView>
                        <ItemContentImage src={d.image} />
                      </HoverZoomView>
                    </ItemContentImageWrapper>
                    <ItemContentText>{d.label}</ItemContentText>
                  </ItemContent>
                ))}
          </ItemContentWrapper>
        </ItemWrapper>
      </Layout>
    </section>
  );
};

export default HomeItem;
