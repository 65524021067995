import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";

const PageWrapper = styled.div`
  position: relative;
`;
const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -100;
`;
const HeaderWrapper = styled.div`
  padding-top: 12.5%;
  padding-bottom: 5%;
`;
const HeaderTitle = styled.div`
  ${fontSize(70, "L", "white")}
  padding: 1% 0px;
`;
const HeaderDesc = styled.div`
  ${fontSize(20, "L", "white")}
`;

const ItemHeader = () => {
  return (
    <section>
      <PageWrapper>
        <BackgroundWrapper>
          <BackgroundImage src="https://d17mmzdc0h66bb.cloudfront.net/image/item_background.png" />
        </BackgroundWrapper>
        <BackgroundView />
        <Layout>
          <HeaderWrapper>
            <HeaderTitle>상품소개</HeaderTitle>
            <HeaderDesc>세왕상사에서 취급하는 품목들을 소개합니다.</HeaderDesc>
          </HeaderWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default ItemHeader;
