import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import ToastViewer from "../../components/viewer";
import { fontPSize, fontSize } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { apiFetch } from "../../utils/fetch";
import useAuthStore from "../../stores/authStore";
import useLinkClick from "../../hooks/useLinkClick";

const PageWrapper = styled.div`
  padding-bottom: 20%;
  .rotate {
    transform: rotate(180deg);
  }
`;

const ContentHeaderWrapper = styled.div``;
const BlackBorder = styled.div`
  background: #000;
  height: 1px;
`;
const GrayBorder = styled.div`
  background: rgba(119, 119, 119, 0.5);
  height: 1px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5% 0;
`;
const Title = styled.div`
  ${fontSize(34, "M", "black")}
  flex: 6;
`;
const NextPrevWrapper = styled.div`
  ${fontSize(18, "L", "#777777")}
  display: flex;
  flex: 1;
  gap: 10%;
  align-items: center;
  justify-content: flex-end;
  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
    & > img {
      width: 32%;
    }
  }
`;
const SubtitleWrapper = styled.div`
  ${fontSize(18, "L", "#777777")}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5% 0px;
  & > div {
    display: flex;
  }
`;
const SubtitleContainer = styled.div`
  display: flex;
  flex: 10;
  gap: 1%;
`;
const LintContainer = styled.div`
  display: flex;
  gap: 13%;
  flex: 1;
  & > div {
    cursor: pointer;
    width: 23%;
    & > img {
      width: 100%;
    }
  }
`;
const ContentWrapper = styled.div`
  & p {
    ${fontPSize(20, "R", "black")}
  }
`;
const ContentFooterWrapper = styled.div`
  ${fontSize(18, "L", "#777777")}
  display: flex;
  justify-content: flex-end;
  gap: 1.5%;
  align-items: center;
  padding: 1.5% 0;
  & > div {
    display: flex;
    align-items: center;
    width: 6%;
    cursor: pointer;
    justify-content: flex-end;
    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
    $ > img {
      width: 28%;
    }
  }
`;
const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5%;
  align-items: center;
`;
const EditButton = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 3%;
`;

const DeleteButton = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(206, 65, 106, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 3%;
`;

const NewsContent = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const { loginState } = useAuthStore();
  const linkClick = useLinkClick();
  const ref = useRef<any>(null);
  const [data, setData] = useState<
    Board & { prev_id: number | null; next_id: number | null }
  >();
  useEffect(() => {
    const _query = queryString.parse(location.search);
    if (_query.id) {
      window.scrollTo(0, 0);
      setData(undefined);
      apiFetch(`/api/v2.0/boardById?id=${_query.id}`, "GET").then((_data) => {
        setData(_data);
      });
    }
  }, [location.search]);

  const deleteFn = () => {
    apiFetch("/api/v2.0/boardWrite", "DELETE", data).then((data) => {
      navigator(-1);
    });
  };

  return (
    <section>
      <Layout>
        <PageWrapper>
          {loginState && data && (
            <EditWrapper>
              <EditButton onClick={() => navigator(`/newsEdit?id=${data.id}`)}>
                수정
              </EditButton>
              <DeleteButton onClick={deleteFn}>삭제</DeleteButton>
            </EditWrapper>
          )}
          <ContentHeaderWrapper>
            <BlackBorder />
            <TitleWrapper>
              <Title>{data && data.title}</Title>
              <NextPrevWrapper>
                <div
                  className={data && data.prev_id != null ? "" : "disabled"}
                  onClick={() => {
                    if (data && data.prev_id != null)
                      navigator(`/news?id=${data.prev_id}`);
                  }}
                >
                  <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg" />
                  <div>이전글</div>
                </div>
                <div
                  className={data && data.next_id != null ? "" : "disabled"}
                  onClick={() => {
                    if (data && data.next_id != null)
                      navigator(`/news?id=${data.next_id}`);
                  }}
                >
                  <div>다음글</div>
                  <img
                    className="rotate"
                    src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg"
                  />
                </div>
              </NextPrevWrapper>
            </TitleWrapper>
            <GrayBorder />
            <SubtitleWrapper>
              <SubtitleContainer>
                <div>관리자</div>
                <div>|</div>
                <div>{data && data.date}</div>
                <div>|</div>
                <div>조회수: {data && data?.viewCount}</div>
              </SubtitleContainer>
              <LintContainer>
                <div
                  onClick={() => {
                    if (data && data.link_url) {
                      window.open(data.link_url);
                    }
                  }}
                >
                  {data && data.link_url && (
                    <img src="https://d17mmzdc0h66bb.cloudfront.net/image/nLink.png" />
                  )}
                </div>
                <div onClick={linkClick}>
                  <img src="https://d17mmzdc0h66bb.cloudfront.net/image/share.svg" />
                </div>
              </LintContainer>
            </SubtitleWrapper>
            <GrayBorder />
          </ContentHeaderWrapper>
          <ContentWrapper>
            {data && <ToastViewer content={data.desc} editorRef={ref} />}
          </ContentWrapper>
          <GrayBorder />
          <ContentFooterWrapper>
            <div
              onClick={() => {
                navigator("/news?index=0");
              }}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/reorder.svg" />
              <div>목록</div>
            </div>
            <div
              className={data && data.prev_id != null ? "" : "disabled"}
              onClick={() => {
                if (data && data.prev_id != null)
                  navigator(`/news?id=${data.prev_id}`);
              }}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg" />
              <div>이전글</div>
            </div>
            <div
              className={data && data.next_id != null ? "" : "disabled"}
              onClick={() => {
                if (data && data.next_id != null)
                  navigator(`/news?id=${data.next_id}`);
              }}
            >
              <div>다음글</div>
              <img
                className="rotate"
                src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg"
              />
            </div>
          </ContentFooterWrapper>
          <BlackBorder />
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default NewsContent;
