import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { Slide, SlideshowRef } from "react-slideshow-image";
import { partnerData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: rgba(74, 80, 107, 1);
`;
const BottomBannerWrapper = styled.div`
  padding: 45px 10px;
  position: relative;
  display: flex;
  align-items: center;
`;
const BottomBannerHeader = styled.div`
  margin-right: 120px;
`;
const BottomBannerTitle = styled.div`
  ${fontSize(25, "L", "white")}
  margin-bottom: 10px;
`;
const SlideWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;
const ItemContent = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ItemContentImage = styled.img`
  object-fit: contain;
  max-width: min(10vw, 170px);
  height: 100%;
  width: 100%;
  max-height: 30px;
`;

const ArrowWWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const Arrow = styled.img`
  margin: 0.5vw;
  cursor: pointer;
  filter: invert(1);
  width: 3vw;
  max-width: 60px;
`;

const HomeBottomBanner = () => {
  const slideRef = useRef<SlideshowRef>(null);

  return (
    <section>
      <PageWrapper>
        <Layout>
          <BottomBannerWrapper>
            <BottomBannerHeader>
              <BottomBannerTitle>고객사 및 협력사</BottomBannerTitle>
              <ArrowWWrapper>
                <Arrow
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                  onClick={() => slideRef.current?.goBack()}
                />
                <Arrow
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                  onClick={() => slideRef.current?.goNext()}
                />
              </ArrowWWrapper>
            </BottomBannerHeader>
            <SlideWrapper>
              <Slide
                ref={slideRef}
                slidesToShow={5}
                indicators={false}
                arrows={false}
                transitionDuration={200}
              >
                {partnerData().map((d) => (
                  <ItemContent key={d.id}>
                    <ItemContentImage src={d.imageSrc} />
                  </ItemContent>
                ))}
              </Slide>
            </SlideWrapper>
          </BottomBannerWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default HomeBottomBanner;
