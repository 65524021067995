import React, { KeyboardEvent, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize } from "../../styles/Font";
import Select from "../../components/select";
import { searchTypeOptions } from "../../utils/datas";
import { useNavigate } from "react-router";

const PageWrapper = styled.div``;
const HeaderWrapper = styled.div`
  padding: 2% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Header = styled.div`
  ${fontPSize(18, "R", "#777777")}
  display: flex;
  align-items: center;
`;
const SearchWrapper = styled.div`
  display: flex;
  gap: 10%;
  position: relative;
  width: 24%;
`;

const SearchTextWrapper = styled.div`
  position: relative;
  padding-bottom: 14%;
  flex: 2;
  display: flex;
  align-items: center;
`;
const SearchTextView = styled.div`
  position: absolute;
  padding: 7% 10%;
  display: flex;
  // width: 80%;
  // height: 31%;
  inset: 0px;
  border: 1px solid rgba(119, 119, 119, 0.5);
  justify-content: flex-end;
`;
const SearchInput = styled.input`
  border: none;
  padding: 0px;
  position: absolute;
  background: none;
  // width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-right: 24%;
  padding-left: 6%;
  width: 70%;
  ${fontPSize(18, "R", "#777777")}
`;
const SearchIConWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 15%;
  z-index: 1;
  cursor: pointer;
`;
const SearchIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const NewsSearch = () => {
  const navigator = useNavigate();
  const [search, setSearch] = useState({
    value: "title",
    label: "제목",
  });
  const [text, setText] = useState("");

  const searchFn = () => {
    const url = `/news?index=0&type=${search.value}&search=${text}`;
    navigator(url);
  };

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchFn();
    }
  };

  return (
    <section style={{ overflow: "visible" }}>
      <Layout>
        <PageWrapper>
          <HeaderWrapper>
            <Header>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator("/");
                }}
                src="https://d17mmzdc0h66bb.cloudfront.net/image/home.svg"
              />
              <img
                style={{
                  filter:
                    "invert(53%) sepia(3%) saturate(9%) hue-rotate(25deg) brightness(87%) contrast(91%)",
                }}
                src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
              />
              <div>세왕소식</div>
            </Header>
            <SearchWrapper>
              <Select
                search={search}
                selectOptions={searchTypeOptions}
                onChange={(option) => setSearch(option)}
              />
              <SearchTextWrapper>
                <SearchTextView>
                  <SearchInput
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyDown={handleOnKeyPress}
                  />
                  <SearchIConWrapper onClick={searchFn}>
                    <SearchIcon src="https://d17mmzdc0h66bb.cloudfront.net/image/search_icon.svg" />
                  </SearchIConWrapper>
                </SearchTextView>
              </SearchTextWrapper>
            </SearchWrapper>
          </HeaderWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default NewsSearch;
