import React from "react";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import styled from "styled-components";
import { useNavigate } from "react-router";

const HeaderWrapper = styled.div``;
const Header = styled.div`
  ${fontPSize(18, "R", "rgba(119, 119, 119, 1)")}
  padding: 2.5% 0px;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  ${fontSize(34, "M", "black")}
  padding-top: 2%;
  padding-bottom: 3%;
`;

interface Props {
  header: string;
  title: string;
}
const ContactTitle = (props: Props) => {
  const navigator = useNavigate();
  const { header, title } = props;
  return (
    <section>
      <Layout>
        <HeaderWrapper>
          <Header>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator("/");
              }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/home.svg"
            />
            {header.split(">").map((h) => (
              <>
                <img
                  style={{
                    filter:
                      "invert(53%) sepia(3%) saturate(9%) hue-rotate(25deg) brightness(87%) contrast(91%)",
                  }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
                />
                <div>{h}</div>
              </>
            ))}
          </Header>
          <Title>{title}</Title>
        </HeaderWrapper>
      </Layout>
    </section>
  );
};

export default ContactTitle;
