import { useEffect, useState, MutableRefObject } from "react";

type TOptions = {
  min: number;
  max: number;
  log?: boolean;
};

const useScrollAnimation = (
  ref: MutableRefObject<HTMLInputElement | null>,
  options?: TOptions
) => {
  const [inViewPort, setInViewPort] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);

  const handleScroll = ([entry]: any) => {
    if (entry.isIntersecting === true) setInViewPort(entry.isIntersecting);
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    const { current } = ref;

    if (current) {
      observer = new IntersectionObserver(handleScroll, {
        threshold: 0,
      });
      observer.observe(current);
    }

    return () => observer && observer.disconnect();
  }, [ref?.current !== undefined]);

  useEffect(() => {
    const { current } = ref;
    if (current && percentage >= 100) {
      if (current.getBoundingClientRect().bottom < window.scrollY) {
        setInViewPort(false);
      } else {
        setInViewPort(true);
      }
    }
  }, [percentage, ref]);

  useEffect(() => {
    let scrollEvent: (this: Window, ev: Event) => any;
    const { current } = ref;
    if (current) {
      scrollEvent = () => {
        const { top, bottom } = current.getBoundingClientRect();
        const _percentage = Math.floor(
          ((window.scrollY - (current.offsetTop - window.innerHeight)) /
            (bottom - top)) *
            100
        );
        if (options) {
          if (options.min > _percentage) {
            setPercentage(0);
          } else if (options.min <= _percentage && options.max > _percentage) {
            setPercentage(
              Math.floor(
                ((_percentage - options.min) * 100) /
                  (options.max - options.min)
              )
            );
          } else if (options.max <= _percentage) {
            setPercentage(100);
          }
        } else if (bottom - top > window.scrollY) {
          setPercentage(_percentage);
        }
      };
      if (inViewPort) {
        window.addEventListener("scroll", scrollEvent);
      }
    }
    return () =>
      scrollEvent && window.removeEventListener("scroll", scrollEvent);
  }, [inViewPort]);

  return {
    // style: {
    //   opacity: 0,
    //   // transform: handleDirection(direction),
    // },
    inViewPort,
    percentage,
  };
};

export default useScrollAnimation;
