import React, { useEffect, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import Select from "../../components/select";
import useModalStore from "../../stores/modalStore";
import { apiFetch } from "../../utils/fetch";
import queryString from "query-string";
import useContactStore from "../../stores/contactStore";

const PageWrapper = styled.div`
  padding: 0 0px 10% 0;
`;
const Wrapper = styled.div`
  padding-bottom: 7%;
  padding-top: 2%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 27%;
  margin: 1.5% 0px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(27.23, 48.44, 157.3, 0.07);
`;
const ContentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  height: 100%;
`;

const ContentTextContainer = styled.div`
  // width: 43%;
  // padding: 10%;
  // overflow: hidden;
`;
const ContentType = styled.div`
  ${fontPSize(16, "R", "rgba(86, 86, 86, 1)")}
`;
const ContentTitle = styled.div`
  ${fontSize(16, "L", "black")}
  padding: 5% 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
`;
const ContentIconWrapper = styled.div`
  width: 9%;
`;
const ContentIcon = styled.img`
  width: 100%;
  filter: invert(45%) sepia(98%) saturate(1800%) hue-rotate(155deg)
    brightness(98%) contrast(101%);
  &.right {
    transform: rotate(270deg);
  }
`;

const PageNationWrapper = styled.div`
  display: flex;
  justify-content: center;
  // width: 50%;
  align-items: center;
  margin: auto;
  // gap: 5%;
`;
const PageNationContent = styled.div<{ disabled?: boolean }>`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  background: rgba(119, 119, 119, 0.08);
  padding: 3% 0;
  ${(props) => (props.disabled ? "opacity:0.5" : "")};
`;

const PageNationTextContent = styled.div<{ disabled?: boolean }>`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  & > div {
    padding-bottom: 37%;
    & > div {
      justify-content: center;
      align-items: center;
      &:first-child {
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 5%;
        & > div {
          color: rgba(0, 175, 206, 1);
          & > img {
            width: 26%;
            filter: invert(45%) sepia(98%) saturate(1800%) hue-rotate(155deg)
              brightness(98%) contrast(101%);
          }
        }
      }
      &:last-child {
        & > div {
          align-items: center;
          width: 100%;
          justify-content: center;
          gap: 5%;
          & > img {
            width: 26%;
          }
        }
      }
    }
  }
`;
const PageNationText = styled.div`
  ${fontSize(14, "L", "#777777")}
  &.active {
    ${fontSize(16, "M", "rgba(19, 127, 185, 1)")}
  }
`;

const PageNationIcon = styled.img`
  width: 19%;

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(270deg);
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const ModalImageWrapper = styled.div`
  max-width: 33%;
  & > img {
    width: 100%;
  }
`;
const ModalTextWrapper = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ModalNameText = styled.div`
  padding: 5% 0;
  ${fontSize(16, "M", "black")}
`;
const ModalTypeText = styled.div`
  ${fontPSize(16, "R", "rgba(86, 86, 86, 1)")}
`;
const DescWrapper = styled.div`
  position: relative;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 5% 0;
  gap: 5vw;
  width: 100%;
`;

const DescContent = styled.div`
  display: flex;
  gap: 5vw;
  align-items: center;
  padding: 6%;
  background: #f4f5fc;
  border-radius: 3vw;
`;

const DescIconImage = styled.img`
  width: 17%;
`;

const DescText = styled.div`
  ${fontSize(14, "L", "black")}
`;

const ContactPeople = () => {
  const { showModal } = useModalStore();
  const { search } = useLocation();
  const navigator = useNavigate();
  const {
    setContactPeoples,
    setContactPeopleTotalCount,
    contactPeopleTotalCount,
    contactPeoples,
  } = useContactStore();
  // const { setPeopleTotalCount, setPeoples, peoples, peopleTotalCount } =
  //   usePeopleStore();
  const [index, setIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(1); // 총페이지수

  useEffect(() => {
    const _query =
      search && search !== "" ? queryString.parse(search) : undefined;
    if (_query?.index !== undefined && _query.index !== null) {
      const _index = parseInt(_query.index.toString());
      const prov_id = _query.prov_id;
      const liquors_name = _query.liquors_name;
      apiFetch(
        `/api/v2.0/locationUsers?offset=${
          _index * 6
        }&limit=6&liquors_name=${liquors_name}&prov_id=${prov_id}`,
        "GET"
      ).then((data) => {
        setContactPeoples(data[0]);
        setContactPeopleTotalCount(data[1]);
      });
      setIndex(_index);
      window.scrollTo(0, 0);
    }
  }, [search]);

  useEffect(() => {
    setMaxIndex(Math.ceil(contactPeopleTotalCount / 6));
  }, [contactPeopleTotalCount]);

  const renderPageNation = () => {
    const page = Math.floor(index / 10);
    const result = [];
    const selectData = [];
    const first = page * 10;
    const last = page * 10 + 10;
    result.push(
      <PageNationContent
        key={"prev"}
        disabled={index <= 0}
        onClick={() => {
          if (index > 0) {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: index - 1,
            };
            navigator(`/contact?${query}`);
          }
        }}
      >
        <PageNationIcon
          className="left"
          src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
        />
        <PageNationText>이전</PageNationText>
      </PageNationContent>
    );
    for (let i = first; i < last; i++) {
      if (i + 1 > maxIndex) break;
      selectData.push({
        value: i,
        label: `${i + 1} 페이지`,
      });
    }
    result.push(
      <PageNationTextContent key={"page"}>
        <Select
          borderDisabled={true}
          selectOptions={selectData}
          search={{ value: index, label: `${index + 1} 페이지` }}
          onChange={(value) => {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: value.value,
            };
            navigator(`/contact?${query}`);
          }}
        />
      </PageNationTextContent>
    );
    result.push(
      <PageNationContent
        key={"next"}
        disabled={index >= maxIndex - 1}
        onClick={() => {
          if (index < maxIndex - 1) {
            const _query = queryString.parse(search);
            const query = {
              ..._query,
              index: index + 1,
            };
            navigator(`/contact?${query}`);
          }
        }}
      >
        <PageNationText>다음</PageNationText>
        <PageNationIcon
          className="right"
          src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
        />
      </PageNationContent>
    );

    return result;
  };
  const getText = (liquors: any[], works: any[]) => {
    const liquorText = liquors.map((liquor) => liquor.label).join("/");
    const workText = works.map((work) => work.label).join("/");
    const text = [workText, liquorText].join(",");
    return text;
  };

  const ModalContent = (selectData: any) => (
    <ModalWrapper>
      <ModalImageWrapper>
        <img src={selectData.photo} />
      </ModalImageWrapper>
      <ModalTextWrapper>
        <ModalNameText>{`${selectData.name} ${selectData.position}`}</ModalNameText>
        <ModalTypeText>
          {getText(selectData.work_liquor, selectData.work_prov)}
        </ModalTypeText>
      </ModalTextWrapper>
      <DescWrapper>
        <DescContent>
          <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_1.png" />
          <DescText>{selectData.phone}</DescText>
        </DescContent>
        <DescContent>
          <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_2.png" />
          <DescText>{selectData.email}</DescText>
        </DescContent>
      </DescWrapper>
    </ModalWrapper>
  );

  return (
    <section>
      <PageWrapper>
        <Layout>
          <Wrapper>
            <Container>
              {contactPeoples.map(
                (d) =>
                  d && (
                    <Content
                      key={`contact_${d.id}`}
                      onClick={() => {
                        // setSelectData(d);
                        showModal(ModalContent(d));
                      }}
                    >
                      <ContentWrapper>
                        <ContentContainer>
                          <ContentTextContainer>
                            <ContentTitle>{`${d.name} ${d.position}`}</ContentTitle>
                            <ContentType>
                              {getText(d.work_liquor, d.work_prov)}
                            </ContentType>
                          </ContentTextContainer>
                          <ContentIconWrapper>
                            <ContentIcon
                              className="right"
                              src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
                            />
                          </ContentIconWrapper>
                        </ContentContainer>
                      </ContentWrapper>
                    </Content>
                  )
              )}
            </Container>
          </Wrapper>
        </Layout>
        <PageNationWrapper>{renderPageNation()}</PageNationWrapper>
      </PageWrapper>
    </section>
  );
};

export default ContactPeople;
