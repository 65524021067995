import React from "react";
import { Default, Mobile } from "../styles/responsive";
import MFloating from "../mobileSections/floating";
import Floating from "../sections/floating";
const FloatingButton = () => {
  return (
    <>
      <Default>
        <Floating />
      </Default>
      <Mobile>
        <MFloating />
      </Mobile>
    </>
  );
};

export default FloatingButton;
