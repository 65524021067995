import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";

const PageWrapper = styled.div`
  background: #137fb9;
  padding: 10% 0;
`;
const TopText = styled.div`
  ${fontSize(16, "L", "white")}
  padding: 5% 0;
  word-break: keep-all;
  text-align: center;
  & > span {
    ${fontSize(16, "M", "white")}
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5%;
  flex-direction: column;
  gap: 5vw;
`;
const Content = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  background: #fff;
  border-radius: 3vw;
  padding: 6%;
  gap: 5vw;
`;
const Icon = styled.img`
  width: 17%;
`;
const Desc = styled.div`
  ${fontSize(14, "L", "black")}
`;
const ItemFooter = () => {
  return (
    <section>
      <PageWrapper>
        <Layout>
          <TopText>
            <span>제품 및 계약에 관한 문의</span>가 있으실경우, 세왕상사 측으로
            문의주시기 바랍니다.
          </TopText>
          <ContentWrapper>
            <Content>
              <Icon src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_1.png" />
              <Desc>+82 2 2203 5311</Desc>
            </Content>
            <Content>
              <Icon src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_2.png" />
              <Desc>sewang88@naver.com</Desc>
            </Content>
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default ItemFooter;
