import React, { useEffect, useState } from "react";
import { Default, Mobile } from "../styles/responsive";
import { useLocation } from "react-router";
import queryString from "query-string";
import ContactHeader from "../sections/contact/contactHeader";
import ContactPlace from "../sections/contact/contactPlace";
import ContactSearch from "../sections/contact/contactSearch";
import ContactPeople from "../sections/contact/contactPeople";
import ContactTitle from "../sections/contact/contactTitle";
import MContactHeader from "../mobileSections/contact/contactHeader";
import MContactPlace from "../mobileSections/contact/contactPlace";
import MContactSearch from "../mobileSections/contact/contactSearch";
import MContactPeople from "../mobileSections/contact/contactPeople";
import MContactTitle from "../mobileSections/contact/contactTitle";
import MContactTab from "../mobileSections/contact/contactTab";

const ContactPage = () => {
  const location = useLocation();
  const [query, setQuery] = useState<any>();
  useEffect(() => {
    const _query =
      location.search && location.search !== ""
        ? queryString.parse(location.search)
        : undefined;
    setQuery(_query);
  }, [location.search]);

  return (
    <>
      <Default>
        <ContactHeader />
        <ContactTitle header={"문의하기"} title={"지역별 담당자"} />
        <ContactSearch />
        <ContactPeople />
        <ContactPlace />
      </Default>
      <Mobile>
        <MContactHeader />
        <MContactTab />
        <MContactTitle
          title={query?.page === "place" ? "오시는길" : "지역별 담당자"}
          desc={
            query?.page === "place"
              ? "지역별 담당자 및 문의처를 확인할 수 있습니다."
              : "지역별 담당자 및 문의처를 확인할 수 있습니다."
          }
          search={query?.page === "place" ? false : true}
        />
        {query?.page === "place" ? <MContactPlace /> : <MContactPeople />}
      </Mobile>
    </>
  );
};

export default ContactPage;
