import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";

const PageWrapper = styled.div`
  background: #1d192f;
`;
const FooterWrapper = styled.div`
  padding: 45px 10px 90px 10px;
  position: relative;
  // display: flex;
  align-items: center;
`;
const FooterDivider = styled.div`
  margin: 10px 0;
  height: 1px;
  background: rgba(164, 174, 198, 0.2);
`;
const FooterHeader = styled.div`
  ${fontSize(14, "B", "white")}
`;
const FooterContentWrapper = styled.div``;
const FooterContent = styled.div`
  ${fontSize(12, "L", "white")}
  margin-bottom: 10px;
`;
const HomeFooter = () => {
  return (
    <section>
      <PageWrapper>
        <Layout>
          <FooterWrapper>
            <FooterHeader>세왕상사</FooterHeader>
            <FooterDivider />
            <FooterContentWrapper>
              <FooterContent>
                서울 강동구 진황도로61길 60 세왕상사(주)
              </FooterContent>
              <FooterContent>대표이사 : 정민성</FooterContent>
              <FooterContent>사업자번호 : 219-81-19529</FooterContent>
              <FooterContent>TEL. 02. 2203. 5311 </FooterContent>
              <FooterContent>Email. sewang88@naver.com</FooterContent>
              <FooterContent>
                Copyright(C) 2023 SEWANG.corp. All right reserved
              </FooterContent>
            </FooterContentWrapper>
          </FooterWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default HomeFooter;
