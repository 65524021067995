import React, { ReactNode } from "react";
import styled from "styled-components";

type HoverZoomViewProps = {
  children: ReactNode;
};

const HoverZoomWrapper = styled.div`
  height: 100%;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: transform 0.2s;
  &:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`;

const HoverZoomView = (props: HoverZoomViewProps) => {
  return <HoverZoomWrapper>{props.children}</HoverZoomWrapper>;
};

export default HoverZoomView;
