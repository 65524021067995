import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { SlideshowRef, Fade } from "react-slideshow-image";
import { fontSize } from "../../styles/Font";
import { videoOptions } from "../../utils/datas";

const BannerWrapper = styled.div`
  height: 85vh;
  position: relative;
`;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  & > div {
    height: 100%;
    & > div.react-slideshow-container {
      height: 100%;
      & > div.react-slideshow-fadezoom-wrapper {
        height: 100%;
        & > div.react-slideshow-fadezoom-images-wrap {
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
`;
const VideoView = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`;
const BannerTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  // background-color: rgba(13, 114, 169, 0.4);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  align-items: center;
  display: flex;
  z-index: 1;
`;

const BannerTextContainer = styled.div`
  height: auto;

  @media screen and (min-width: 1440px) {
    // @media screen and (min-width: 1440px) and (max-width: 1920px) {
    max-width: 1500px;
  }
  // @media screen and (min-width: 1920px) {
  //   max-width: 78%;
  // }
  min-height: 0;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: 70px;
  color: white;
`;

const TextGridContainer = styled.div`
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 160px;
  padding-bottom: 170px;
  display: grid;
  align-content: center;
  align-items: stretch;
  padding-top: 0;
  padding-bottom: 0;
`;

const TextGrid = styled.div`
  grid-area: span 1 / span 1 / span 1 / span 1;
  height: 100%;
  padding-right: 0px;
  position: relative;
`;

const TextTop = styled.div`
  ${fontSize(70, "L", "white")}
`;

const TextBottom = styled.div`
  ${fontSize(70, "B", "white")}
`;
const LogoWrapper = styled.div`
  margin-top: 2vw;
  & > img {
    width: 11vw;
  }
`;
const Logo = styled.img`
  width: 35%;
  max-width: 218px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 40%;
  -webkit-box-align: center;
  align-items: center;
  z-index: 10;
  /* width: 100%; */
  /* -webkit-box-pack: center; */
  /* justify-content: center; */
  right: 10%;
  flex-direction: column;
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  border-radius: 100px;
  width: 4vw;
  max-width: 60px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
    brightness(110%) contrast(100%);
`;
const IndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 50px;
  -webkit-box-align: center;
  align-items: center;
  z-index: 10;
  width: 100%;
  justify-content: center;
`;
const Indicators = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Indicator = styled.div`
  border: 1px solid #fff;
  width: 1vw;
  height: 1vw;
  border-radius: 100%;
  margin-right: 0.5vw;
  cursor: pointer;
  max-width: 14px;
  max-height: 14px;
  &.active {
    background: #fff;
  }
`;
const PauseAndPlay = styled.img`
  cursor: pointer;
  width: 1vw;
  height: 1vw;
  margin-left: 1vw;
  max-width: 20px;
  max-height: 20px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
    brightness(110%) contrast(100%);
`;
const HomeBanner = () => {
  const slideRef = useRef<SlideshowRef>(null);
  const videoRef = useRef<HTMLVideoElement[]>([]);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [play, setPlay] = useState<boolean>(true);
  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.goTo(slideIndex);
    }
    if (videoRef.current[slideIndex]) {
      if (play) {
        videoRef.current[slideIndex].play();
      } else {
        videoRef.current[slideIndex].pause();
        // videoRef.current[slideIndex].currentTime = 0;
      }

      const resetTimeout = setTimeout(() => {
        videoRef.current.map((vRef, index) => {
          if (index !== slideIndex) {
            vRef.pause();
            vRef.currentTime = 0;
          }
        });
      }, 400);
      return () => {
        clearTimeout(resetTimeout);
      };
    }
  }, [slideIndex, videoRef, slideRef, play]);
  return (
    <section>
      <BannerWrapper>
        <VideoWrapper>
          <Fade
            ref={slideRef}
            arrows={false}
            autoplay={false}
            transitionDuration={400}
            indicators={false}
          >
            {videoOptions.map((d, index) => (
              <VideoView
                key={d.id}
                ref={(el) => {
                  if (el) videoRef.current[index] = el;
                }}
                poster={d.poster}
                src={d.src}
                onEnded={() => {
                  setSlideIndex((prev) => (prev + 1) % videoOptions.length);
                }}
                muted
                playsInline
              />
            ))}
          </Fade>
        </VideoWrapper>
        <Layout style={{ padding: 0, position: "relative", height: "100%" }}>
          <IndicatorWrapper>
            <Indicators>
              <Indicators>
                {videoOptions.map((item: any, index: number) => (
                  <Indicator
                    key={index}
                    className={slideIndex === index ? "active" : ""}
                    onClick={() => {
                      setSlideIndex(index);
                    }}
                  />
                ))}
              </Indicators>
              <PauseAndPlay
                src={
                  play
                    ? "https://d17mmzdc0h66bb.cloudfront.net/image/pause.svg"
                    : "https://d17mmzdc0h66bb.cloudfront.net/image/play.svg"
                }
                onClick={() => setPlay((prev) => !prev)}
              />
            </Indicators>
          </IndicatorWrapper>
          <ArrowWrapper>
            <Arrow
              src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
              onClick={() => {
                if (slideIndex > 0) {
                  setSlideIndex((prev) => (prev - 1) % videoOptions.length);
                } else {
                  setSlideIndex(videoOptions.length - 1);
                }
              }}
            />
            <Arrow
              src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
              onClick={() =>
                setSlideIndex((prev) => (prev + 1) % videoOptions.length)
              }
            />
          </ArrowWrapper>
        </Layout>
        <BannerTextWrapper>
          <Layout style={{ padding: 0 }}>
            <BannerTextContainer>
              <TextGridContainer>
                <TextGrid>
                  <TextTop>고객이 신뢰할 수 있는</TextTop>
                  <TextBottom>주류 유통 전문 기업</TextBottom>
                  {/* <LogoWrapper> */}
                  <Logo src="https://d17mmzdc0h66bb.cloudfront.net/image/logo_white.png" />
                  {/* </LogoWrapper> */}
                </TextGrid>
              </TextGridContainer>
            </BannerTextContainer>
          </Layout>
        </BannerTextWrapper>
      </BannerWrapper>
    </section>
  );
};

export default HomeBanner;
