import React from "react";
import { Default, Mobile } from "../styles/responsive";
import ContactHeader from "../sections/contact/contactHeader";
import ContactTitle from "../sections/contact/contactTitle";
import ContactEdit from "../sections/contact/contactEdit";

const ContactEditPage = () => {
  return (
    <>
      <Default>
        <ContactHeader />
        <ContactTitle header={"문의하기>문의작성"} title={"문의 작성"} />
        <ContactEdit />
      </Default>
      <Mobile>
        <section></section>
      </Mobile>
    </>
  );
};

export default ContactEditPage;
