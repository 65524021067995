import React from "react";
import styled from "styled-components";
import { fontPSize, fontSize } from "../../styles/Font";
import Layout from "../../containers/layout";
import { itemContentData } from "../../utils/datas";
import { useNavigate } from "react-router";

const PageWrapper = styled.div`
  padding: 0% 0px 5% 0;
`;
const HeaderWrapper = styled.div`
  padding: 2.5% 0px;
  display: flex;
  align-items: center;
`;
const Header = styled.div`
  ${fontPSize(18, "R", "#777777")}
`;
const BackgroundView = styled.div<{ opacity?: number }>`
  position: absolute;
  width: 100%;
  height: 99%;
  background: ${(props) => `rgba(0, 0, 0, ${props.opacity || 0.2})`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TopText = styled.div`
  ${fontSize(34, "L", "black")}
  padding: 0 0 4% 0;
  & > span {
    ${fontSize(34, "M", "black")}
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  width: 84%;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  position: relative;
`;
const ContentContainer = styled.div`
  position: relative;
  height: fit-content;
  width: calc(50% - 24px);
  display: flex;
  height: fit-content;
  padding-bottom: 33%;
`;
const Content = styled.div`
  position: absolute;
  overflow: hidden;
`;
const ContentImage = styled.img`
  width: 100%;
`;
const ContentTextWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  left: 5%;
`;
const ContentTitle = styled.div`
  ${fontSize(22, "M", "white")}
  margin-bottom: 5%;
`;
const ContentDesc = styled.div`
  ${fontSize(20, "L", "white")}
`;
const ItemContent = () => {
  const navigator = useNavigate();
  return (
    <section>
      <Layout>
        <PageWrapper>
          <HeaderWrapper>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator("/");
              }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/home.svg"
            />
            <img
              style={{
                filter:
                  "invert(53%) sepia(3%) saturate(9%) hue-rotate(25deg) brightness(87%) contrast(91%)",
              }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
            />
            <Header>상품소개</Header>
          </HeaderWrapper>
          <Wrapper>
            <TopText>
              저희 세왕상사 에서는 <span>주류 전 제품 판매</span>가 가능합니다
            </TopText>
            <ContentWrapper>
              {itemContentData.map((d) => (
                <ContentContainer key={d.id}>
                  <Content style={d.style}>
                    <BackgroundView opacity={d.opacity} />
                    <ContentImage src={d.image} />
                    <ContentTextWrapper>
                      <ContentTitle>{d.title}</ContentTitle>
                      <ContentDesc>{d.desc}</ContentDesc>
                    </ContentTextWrapper>
                  </Content>
                </ContentContainer>
              ))}
            </ContentWrapper>
          </Wrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default ItemContent;
