import React from "react";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import styled from "styled-components";
import useBottomSheetStore from "../../stores/bottomSheetStore";
import ContactSearch from "./contactSearch";

const PageWrapper = styled.div`
  padding: 8% 0px 4% 0px;
`;
const HeaderWrapper = styled.div`
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
`;
const HeaderTitle = styled.div`
  ${fontSize(25, "M", "black")}
`;
const HeaderDesc = styled.div`
  padding: 2% 0;
  ${fontSize(14, "L", "black")}
`;
const SearchWrapper = styled.div`
  display: flex;
  gap: 10%;
  position: relative;
  width: 6%;
  justify-content: flex-end;
`;

const SearchIcon = styled.img`
  width: 100%;
  height: 100%;
`;

interface Props {
  desc: string;
  title: string;
  search: boolean;
}
const ContactTitle = (props: Props) => {
  const { desc, title, search } = props;
  const { showBottomSheet } = useBottomSheetStore();
  return (
    <section>
      <Layout>
        <HeaderWrapper>
          <Header>
            <HeaderTitle>{title}</HeaderTitle>
            {search && (
              <SearchWrapper>
                <SearchIcon
                  onClick={() => {
                    showBottomSheet(<ContactSearch />);
                  }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/search_icon.svg"
                />
              </SearchWrapper>
            )}
          </Header>
          <HeaderDesc>{desc}</HeaderDesc>
        </HeaderWrapper>
      </Layout>
    </section>
  );
};

export default ContactTitle;
