import React from "react";
import { Default, Mobile } from "../styles/responsive";
import MHomeFooter from "../mobileSections/home/homeFooter";
import HomeFooter from "../sections/home/homeFooter";
const Footer = () => {
  return (
    <>
      <Default>
        <HomeFooter />
      </Default>
      <Mobile>
        <MHomeFooter />
      </Mobile>
    </>
  );
};

export default Footer;
