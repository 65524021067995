import { useCallback } from "react";
import { toast } from "react-toastify";

const useLinkClick = () => {
  const handleShareClick = useCallback(async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          url: window.location.href,
        });
      } else if (navigator.clipboard) {
        // TODO : do something else like copying the data to the clipboard
        navigator.clipboard.writeText(window.location.href);
        toast("링크가 복사되었습니다.");
        // alert("링크가 복사되었습니다.");
      } else {
        const $text = document.createElement("textarea");
        document.body.appendChild($text);
        $text.value = window.location.href;
        $text.select();
        document.execCommand("copy");
        document.body.removeChild($text);
        toast("링크가 복사되었습니다.");
      }
    } catch (e) {
      toast("공유 중 오류가 발생했습니다. 다시 시도해주세요!");
      // alert("공유 중 오류가 발생했습니다. 다시 시도해주세요!");
    }
  }, []);
  return handleShareClick;
};

export default useLinkClick;
