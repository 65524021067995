import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { infoMottoData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: #f4f4f4;
  padding: 10% 0;
`;
const HeaderWrapper = styled.div`
  padding: 5% 0;
`;
const Header = styled.div`
  ${fontSize(20, "M", "black")}
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 4px 10px #1b309d12;
`;
const Content = styled.div`
  display: flex;
  padding: 6%;
  align-items: center;
  justify-content: space-between;
`;
const ContentTitle = styled.div`
  ${fontSize(16, "M", "#137fb9")}
  &.color {
    ${fontSize(16, "M", "#00afce")}
  }
`;
const ContentDesc = styled.div`
  display: flex;
  ${fontPSize(16, "R", "black")}
  ${lineHeight(25)}
  max-height: 1000px;
  transition: all 0.2s;
  overflow: hidden;
  &.close {
    max-height: 0px;
  }
  & > div {
    padding: 6%;
  }
`;
const ArrowWrapper = styled.div<{ close: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 10%;
  & > img {
    width: 100%;
    filter: invert(26%) sepia(49%) saturate(5214%) hue-rotate(184deg)
      brightness(99%) contrast(85%);
    &.color {
      filter: invert(55%) sepia(96%) saturate(1851%) hue-rotate(148deg)
        brightness(89%) contrast(102%);
    }
    ${(props) => (props.close ? "" : "transform: rotate(180deg);")}
  }
  ${(props) => (props.close ? "" : "")}
`;
const InfoMotto = () => {
  const [openId, setOpenId] = useState(0);

  return (
    <section>
      <PageWrapper>
        <Layout>
          <HeaderWrapper>
            <Header>회사 사훈</Header>
          </HeaderWrapper>
          <ContentWrapper>
            {infoMottoData.map((d, index) => {
              const close = d.id !== openId;
              return (
                <ContentContainer
                  key={d.id}
                  onClick={() => (close ? setOpenId(d.id) : setOpenId(0))}
                >
                  <Content>
                    <ContentTitle
                      className={index % 2 == 1 ? "color" : ""}
                    >{`${d.id}. ${d.title}`}</ContentTitle>
                    <ArrowWrapper close={close}>
                      <img
                        className={index % 2 == 1 ? "color" : ""}
                        src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
                      />
                    </ArrowWrapper>
                  </Content>
                  <ContentDesc className={close ? "close" : ""}>
                    <div>{d.desc}</div>
                  </ContentDesc>
                </ContentContainer>
              );
            })}
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoMotto;
