import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const TabWrapper = styled.div`
  padding: 5% 0px;
`;
const TabContainer = styled.div<{ active?: boolean }>`
  padding: 10% 0;
  ${(props) =>
    props.active
      ? fontSize(14, "M", "rgba(19, 127, 185, 1)")
      : fontSize(14, "L", "rgba(119, 119, 119, 1)")};
  ${(props) =>
    props.active ? "border-bottom: 2px solid rgba(19, 127, 185, 1);" : ""}
`;
const TabText = styled.div``;
const ContactTab = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const [query, setQuery] = useState<any>();
  useEffect(() => {
    const _query =
      location.search && location.search !== ""
        ? queryString.parse(location.search)
        : undefined;
    setQuery(_query);
  }, [location.search]);
  return (
    <section>
      <Layout>
        <PageWrapper>
          <TabWrapper>
            <TabContainer
              onClick={() => {
                navigator("/contact?index=0&prov_id=0&liquors_name=");
              }}
              active={query?.page === "place" ? false : true}
            >
              <TabText>지역별 담당자</TabText>
            </TabContainer>
          </TabWrapper>
          <TabWrapper>
            <TabContainer
              onClick={() => {
                navigator("/contact?page=place");
              }}
              active={query?.page === "place" ? true : false}
            >
              <TabText>오시는길</TabText>
            </TabContainer>
          </TabWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default ContactTab;
