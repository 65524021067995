import React, { useEffect } from "react";
import HomeBanner from "../sections/home/homaBanner";
import HomeBottle from "../sections/home/homeBottle";
import HomePlace from "../sections/home/homePlace";
import HomeItem from "../sections/home/homeItem";
import HomeDesc from "../sections/home/homeDesc";
import HomeSlide from "../sections/home/homeSlide";
import HomeNotice from "../sections/home/homeNotice";
import HomeBottomBanner from "../sections/home/homeBottomBanner";
import MHomeBanner from "../mobileSections/home/homaBanner";
import MHomeBottle from "../mobileSections/home/homeBottle";
import MHomePlace from "../mobileSections/home/homePlace";
import MHomeItem from "../mobileSections/home/homeItem";
import MHomeDesc from "../mobileSections/home/homeDesc";
import MHomeSlide from "../mobileSections/home/homeSlide";
import MHomeNotice from "../mobileSections/home/homeNotice";
import MHomeBottomBanner from "../mobileSections/home/homeBottomBanner";
import { Default, Mobile } from "../styles/responsive";
import useBoardStore from "../stores/boardStore";
import { apiFetch } from "../utils/fetch";
import useContactStore from "../stores/contactStore";

const HomePage = () => {
  const { setBoards, setBoardTotalCount } = useBoardStore();
  const { setLiquors } = useContactStore();
  useEffect(() => {
    apiFetch(
      "/api/v2.0/boardPageApi?offset=0&limit=6&type=&search=",
      "GET"
    ).then((data) => {
      setBoards(data[0]);
      setBoardTotalCount(data[1]);
    });
    apiFetch("/api/v2.0/liquors", "GET").then((data) => {
      setLiquors(data);
    });
  }, []);
  return (
    <>
      <Default>
        <HomeBanner />
        <HomeBottle />
        <HomePlace />
        <HomeItem />
        <HomeDesc />
        <HomeSlide />
        <HomeNotice />
        <HomeBottomBanner />
      </Default>
      <Mobile>
        <MHomeBanner />
        <MHomeBottle />
        <MHomePlace />
        <MHomeItem />
        <MHomeDesc />
        <MHomeSlide />
        <MHomeNotice />
        <MHomeBottomBanner />
      </Mobile>
    </>
  );
};

export default HomePage;
