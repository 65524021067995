import React, { useEffect, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontSize } from "../../styles/Font";
import Select from "../../components/select";
import useContactStore from "../../stores/contactStore";
import { apiFetch } from "../../utils/fetch";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 3%;
  gap: 3%;
  border-bottom: 1px solid rgba(86, 86, 86, 1);
`;
const RightWrapper = styled.div`
  // display: flex;
  // align-items: center;
  width: 14%;
`;
const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 7%;
`;
const SelectLabel = styled.div`
  ${fontSize(20, "M", "black")}
  padding: 6% 0;
`;
const SelectContainer = styled.div`
  width: 30%;
`;
const SearchButton = styled.div`
  ${fontSize(20, "M", "white")}
  background: rgba(0, 175, 206, 1);
  padding: 10% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ContactSearch = () => {
  const { search } = useLocation();
  const navigator = useNavigate();
  const {
    provs,
    dos,
    sigungoos,
    liquors,
    setProvs,
    setDos,
    setSigungoos,
    setLiquors,
  } = useContactStore();
  const [sigungoo, setSigungoo] = useState({
    value: "0",
    label: "전지역",
  });
  const [liquor, setLiquor] = useState({
    value: "0",
    label: "전체",
  });
  const [selectDo, setSelectDo] = useState({ value: "0", label: "전지역" });
  useEffect(() => {
    apiFetch("/api/v2.0/province", "GET").then((data) => {
      setProvs(data);
      const _dos = _.filter(data, (prov) => prov.value.length <= 2);
      setDos(_dos);
    });
    apiFetch("/api/v2.0/liquors", "GET").then((data) => {
      setLiquors(data);
    });
  }, []);
  useEffect(() => {
    const _query = queryString.parse(search);
    if (_query.prov_id) {
      const _prov_id = _query.prov_id;
      if (_prov_id.length <= 2) {
        const _selectDo = provs.find((prov) => prov.value === _prov_id);
        _selectDo && setSelectDo(_selectDo);
      } else {
        const _selectDo = provs.find(
          (prov) => prov.value === _prov_id.slice(0, 2)
        );
        _selectDo && setSelectDo(_selectDo);
        const _sigungoo = provs.find((prov) => prov.value === _prov_id);
        _sigungoo && setSigungoo(_sigungoo);
      }
    }
    if (_query.liquors_name) {
      const liquor_name = _query.liquors_name;
      const _liquor = liquors.find((liquor) => liquor.label === liquor_name);
      _liquor && setLiquor(_liquor);
    }
  }, [search, provs, liquors]);

  useEffect(() => {
    const _sigungoo = _.filter(
      provs,
      (prov) => prov.value.indexOf(selectDo.value) === 0 || prov.value === "0"
    );
    setSigungoos(_sigungoo);
  }, [selectDo, provs]);

  const searchFn = () => {
    const url = `/contact?index=0&prov_id=${
      sigungoo.value !== "0" ? sigungoo.value : selectDo.value
    }&liquors_name=${liquor.label !== "전체" ? liquor.label : ""}`;
    navigator(url);
  };

  return (
    <section style={{ overflow: "visible" }}>
      <Layout>
        <SearchWrapper>
          <LeftWrapper>
            <SelectWrapper>
              <SelectLabel>도 구분</SelectLabel>
              <SelectContainer>
                <Select
                  borderDisabled={true}
                  imageWidth={"27%"}
                  height={"40%"}
                  selectOptions={dos}
                  search={selectDo}
                  onChange={(option) => {
                    setSelectDo(option);
                    setSigungoo({ value: "0", label: "전지역" });
                  }}
                />
              </SelectContainer>
            </SelectWrapper>
            <SelectWrapper>
              <SelectLabel>시/군/구 구분</SelectLabel>
              <SelectContainer>
                <Select
                  borderDisabled={true}
                  height={"40%"}
                  imageWidth={"27%"}
                  selectOptions={sigungoos}
                  search={sigungoo}
                  onChange={(option) => setSigungoo(option)}
                />
              </SelectContainer>
            </SelectWrapper>
            <SelectWrapper>
              <SelectLabel>주종 구분</SelectLabel>
              <SelectContainer>
                <Select
                  borderDisabled={true}
                  height={"40%"}
                  imageWidth={"27%"}
                  selectOptions={liquors}
                  search={liquor}
                  onChange={(option) => setLiquor(option)}
                />
              </SelectContainer>
            </SelectWrapper>
          </LeftWrapper>
          <RightWrapper>
            <SearchButton onClick={searchFn}>담당자 검색</SearchButton>
          </RightWrapper>
        </SearchWrapper>
      </Layout>
    </section>
  );
};

export default ContactSearch;
