import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";

const PageWrapper = styled.div`
  background: #137fb9;
  padding: 4.6% 0;
`;
const TopText = styled.div`
  ${fontSize(34, "L", "white")}
  padding-bottom: 2%;
  text-align: center;
  & > span {
    ${fontSize(34, "M", "white")}
  }
`;
const ContentWrapper = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  gap: 5%;
  padding: 2% 0;
`;
const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  &:last-child {
    justify-content: flex-start;
  }
`;
const Icon = styled.img`
  background: #00afce;
  padding: 1%;
  width: 3.5%;
  border-radius: 100%;
  margin-right: 2%;
`;
const Title = styled.div`
  margin-right: 5%;
  ${fontPSize(20, "M", "#00afce")}
`;
const Desc = styled.div`
  ${fontPSize(20, "R", "black")}
`;
const ItemFooter = () => {
  return (
    <section>
      <PageWrapper>
        <Layout>
          <TopText>
            <span>제품 및 계약에 관한 문의</span>가 있으실경우, 세왕상사 측으로
            문의주시기 바랍니다.
          </TopText>
          <ContentWrapper>
            <Content>
              <Icon src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place_icon_2.svg" />
              <Title>문의전화</Title>
              <Desc>+82 2 2203 5311</Desc>
            </Content>
            <Content>
              <Icon src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place_icon_3.svg" />
              <Title>문의메일</Title>
              <Desc>sewang88@naver.com</Desc>
            </Content>
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default ItemFooter;
