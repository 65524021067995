import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyBjTi87RE1dyZ3xU01wXIl6v9wHfT_9xk4",
  version: "weekly",
  libraries: ["places"],
});

const mapOptions = {
  center: {
    lat: 37.5278025,
    lng: 127.1462271,
  },
  zoom: 17,
};
const PageWrapper = styled.div`
  padding: 3% 0;
`;
const HeaderWrapper = styled.div`
  padding: 1.5% 0;
  ${fontSize(34, "M", "black")}
`;
const Header = styled.div``;
const MapWrapper = styled.div`
  padding-bottom: 33%;
`;
const MapImage = styled.img`
  width: 100%;
`;
const ContentWrapper = styled.div`
  margin: 1.5% 0;
  padding: 3% 11%;
  background: rgba(119, 119, 119, 0.05);
`;
const ContentTitle = styled.div`
  ${fontPSize(22, "B", "black")}
`;
const DescWrapper = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 80%;
  // position: relative;
  position: relative;
  margin-top: 3%;
`;

const DescContent = styled.div`
  // display: flex;
  // grid-gap: 30px;
  // align-items: center;
  display: flex;
  gap: 30px;
  align-items: center;
  position: relative;
  // padding-bottom: 7%;
  width: 100%;
`;

const DescIconWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
`;

const DescIconImageContainer = styled.div`
  // background: #00afce;
  // padding: 10%;
  // border-radius: 100%;
  background: rgb(0, 175, 206);
  padding: 1%;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  width: 2%;
`;
const DescIconImage = styled.img`
  background: rgb(0, 175, 206);
  padding: 1%;
  width: 2.5%;
  border-radius: 100%;
`;
const DescIconText = styled.div`
  ${fontPSize(20, "M", "#00afce")}
  position: absolute;
  left: 5%;
`;
const DescText = styled.div`
  ${fontPSize(20, "R", "black")}
  position: absolute;
  left: 15%;
`;
const ContactPlace = () => {
  useEffect(() => {
    loader
      .importLibrary("maps")
      .then(({ Map }) => {
        const map = new Map(document.getElementById("map"), mapOptions);
        return map;
      })
      .then((map) => {
        loader.importLibrary("marker").then((Marker) => {
          new Marker.Marker({
            position: mapOptions.center,
            map,
          });
        });
      });
  }, []);
  return (
    <section>
      <Layout>
        <PageWrapper>
          <HeaderWrapper>
            <Header>오시는 길</Header>
          </HeaderWrapper>
          <MapWrapper id="map">
            {/* <MapImage src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place.png" /> */}
          </MapWrapper>
          <ContentWrapper>
            <ContentTitle>(주) 세왕상사</ContentTitle>
            <DescWrapper>
              <DescContent>
                {/* <DescIconWrapper> */}
                {/* <DescIconImageContainer> */}
                <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place_icon_1.svg" />
                {/* </DescIconImageContainer> */}
                <DescIconText>오시는 길</DescIconText>
                {/* </DescIconWrapper> */}
                <DescText>
                  서울 강동구 진황도로61길 60 (지하철 9호선 보훈병원역 1번출구
                  1분거리 위치)
                </DescText>
              </DescContent>
            </DescWrapper>
            <DescWrapper>
              <DescContent>
                {/* <DescIconWrapper> */}
                {/* <DescIconImageContainer> */}
                <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place_icon_2.svg" />
                {/* </DescIconImageContainer> */}
                <DescIconText>문의전화</DescIconText>
                {/* </DescIconWrapper> */}
                <DescText>+82 2 2203 5311</DescText>
                {/* </DescContent>
              <DescContent> */}
                {/* <DescIconWrapper> */}
                {/* <DescIconImageContainer style={{ left: "35%" }}> */}
                <DescIconImage
                  style={{ position: "absolute", left: "35%" }}
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/info_place_icon_3.svg"
                />
                {/* </DescIconImageContainer> */}
                <DescIconText style={{ left: "40%" }}>문의메일</DescIconText>
                {/* </DescIconWrapper> */}
                <DescText style={{ left: "50%" }}>sewang88@naver.com</DescText>
              </DescContent>
            </DescWrapper>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default ContactPlace;
