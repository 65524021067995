import React, { useEffect, useState } from "react";
import { Default, Mobile } from "../styles/responsive";
import { useLocation } from "react-router";
import NewsHeader from "../sections/news/newsHeader";
import NewsContentView from "../sections/news/newsContentView";
import NewsSearch from "../sections/news/newsSearch";
import NewsContent from "../sections/news/newsContent";
import MNewsHeader from "../mobileSections/news/newsHeader";
import MNewsContentView from "../mobileSections/news/newsContentView";
import MNewsSearch from "../mobileSections/news/newsSearch";
import MNewsContent from "../mobileSections/news/newsContent";
import queryString from "query-string";
const NewsPage = () => {
  const location = useLocation();
  const [query, setQuery] = useState<any>();
  useEffect(() => {
    const _query =
      location.search && location.search !== ""
        ? queryString.parse(location.search)
        : undefined;
    setQuery(_query);
  }, [location.search]);

  return (
    <>
      <Default>
        <NewsHeader />
        <NewsSearch />
        {query?.id && <NewsContent />}
        {query?.index != null && query?.index >= 0 && <NewsContentView />}
      </Default>
      <Mobile>
        <MNewsHeader />
        {query?.id && <MNewsContent />}
        {query?.index != null && query?.index >= 0 && (
          <>
            <MNewsSearch />
            <MNewsContentView />
          </>
        )}
      </Mobile>
    </>
  );
};

export default NewsPage;
