type CRUD = "GET" | "POST" | "PUT" | "DELETE";
export const apiFetch = (url: string, method: CRUD, sendData?: any) => {
  const options = sendData
    ? {
        method,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendData),
      }
    : {
        method,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      if (response.status === 401 || response.status === 419) {
        localStorage.setItem("id_token", "");
      }
      return response.json().then((resJson) => {
        return Promise.reject(resJson);
      });
    }
    return response.json();
  });
};

export const uploadFileApi = (url: string, files: File[]) => {
  const postBody = createMultiUploadBody(files);
  const options = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: postBody,
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      return response.json().then((resJson) => {
        return Promise.reject(resJson.message);
      });
    }
    return response.json();
  });
};

// export const getConfig = () => {
//   return {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     }
//   };
// };

// export const getConfigBody = getBody => {
//   return {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     body: getBody
//   };
// };

// export const putConfig = putBody => {
//   return {
//     method: "PUT",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//     body: putBody
//   };
// };

// export const jsonPutConfig = putBody => {
//   return {
//     method: "PUT",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     body: putBody
//   };
// };

// export const postConfig = postBody => {
//   return {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//     body: postBody
//   };
// };

// export const jsonPostConfig = postBody => {
//   return {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token"),
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     body: postBody
//   };
// };

// export const deleteConfig = () => {
//   return {
//     method: "DELETE",
//     mode: "cors",
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("id_token")
//     }
//   };
// };

// export const uploadConfig = postBody => {
//   return {
//     method: "POST",
//     mode: "cors",
//     body: postBody
//   };
// };

// export const createBody = datas => {
//   let body = "";

//   for (let key in datas) {
//     if (datas[key] || Number.isInteger(datas[key])) {
//       if (body !== "") {
//         body += "&";
//       }
//       body += encodeURIComponent(key) + "=" + encodeURIComponent(datas[key]);
//     }
//   }
//   return body;
// };

// export const createUploadBody = (file: File) => {
//   let data = new FormData();
//   data.append("file", file);
//   return data;
// };

export const createMultiUploadBody = (files: File[]) => {
  let data = new FormData();
  for (const file of files) {
    data.append("file", file);
  }
  return data;
};
