import { useEffect, useState } from "react";

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

export default function useCountNum(duration = 2000) {
  const start = 0;
  const [end, setEnd] = useState(start);
  const [count, setCount] = useState(start);
  const [isStart, setIsStart] = useState<boolean>(false);
  const frameRate = 1000 / 60;
  const totalFrame = Math.round(duration / frameRate);

  useEffect(() => {
    if (isStart) {
      let currentNumber = start;
      const counter = setInterval(() => {
        const progress = easeOutExpo(++currentNumber / totalFrame);
        setCount(Math.round(end * progress));

        if (progress === 1) {
          clearInterval(counter);
        }
      }, frameRate);
    }
  }, [end, frameRate, start, totalFrame, isStart]);

  return { count, setIsStart, setEnd };
}
