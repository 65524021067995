import { create } from "zustand";

interface BottomSheetState {
  isShow: boolean;
  bottomSheetData: any;
  showBottomSheet: (bottomSheetData: any) => void;
  hideBottomSheet: () => void;
}

const useBottomSheetStore = create<BottomSheetState>()((set) => ({
  isShow: false,
  bottomSheetData: null,
  showBottomSheet: (bottomSheetData) => {
    // document.body.style.overflow = "hidden";
    set(() => ({ bottomSheetData, isShow: true }));
  },
  hideBottomSheet: () => {
    // document.body.style.removeProperty("overflow");
    set(() => ({ bottomSheetData: null, isShow: false }));
  },
}));

export default useBottomSheetStore;
