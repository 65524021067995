import React, { ChangeEventHandler, useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import Select from "../../components/select";
import useContactStore from "../../stores/contactStore";
import { apiFetch } from "../../utils/fetch";
import useModalStore from "../../stores/modalStore";
import _ from "lodash";
import { toast } from "react-toastify";

const PageWrapper = styled.div`
  padding: 4% 0;
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftWrapper = styled.div`
  width: 9%;
  margin-right: 7%;
`;

const GrayBorder = styled.div`
  background: rgba(119, 119, 119, 0.5);
  height: 1px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 3%;
  gap: 3%;
`;
const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7%;
  width: 25%;
`;
const SelectLabel = styled.div`
  ${fontSize(20, "M", "black")}
  padding: 6% 0;
  flex: 1;
`;
const SelectContainer = styled.div`
  // width: 30%;
  flex: 2;
`;
const SearchButton = styled.div`
  ${fontSize(18, "L", "rgba(0, 175, 206, 1)")}
  border: 1px solid rgba(0, 175, 206, 1);
  padding: 4% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > img {
    width: 19%;
    margin-right: 8%;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0;
`;
const RowLabel = styled.div`
  ${fontSize(18, "L", "#777777")}
`;
const RowInput = styled.input`
  ${fontSize(18, "L", "black")}
  width: 90%;
  border: 1px solid rgba(119, 119, 119, 0.5);
  padding: 0.5%;
`;
const RowArea = styled.textarea`
  ${fontSize(18, "L", "black")}
  width:100%;
  margin: 2% 0;
  border: 1px solid rgba(119, 119, 119, 0.5);
  resize: none;
  padding: 0.5%;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1%;
  align-items: center;
  margin: 1% 0;
`;

const ModalWrapper = styled.div``;
const ModalHeaderWrapper = styled.div`
  border-left: 5px solid rgba(0, 175, 206, 1);
  padding-left: 2%;
  margin: 3% 0;
`;
const ModalHeader = styled.div`
  ${fontSize(36, "M", "black")}
`;
const ModalSubtitleWrapper = styled.div``;
const ModalSubtitle = styled.div`
  ${fontSize(16, "L", "rgba(0, 175, 206, 1)")}
`;
const ModalTextWrapper = styled.div`
  margin: 1% 0;
`;
const ModalText = styled.div`
  ${fontSize(15, "L", "#444")}
`;

const ContactEdit = () => {
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [liquor, setLiquor] = useState({
    value: "0",
    label: "전체",
  });
  const [selectDo, setSelectDo] = useState({ value: "1", label: "전체" });
  const { showModal } = useModalStore();
  const { liquors, dos, setProvs, setDos, setLiquors } = useContactStore();
  useEffect(() => {
    apiFetch("/api/v2.0/province", "GET").then((data) => {
      setProvs(data);
      const _dos = _.filter(data, (prov) => prov.value.length <= 2);
      setDos(_dos);
    });
    apiFetch("/api/v2.0/liquors", "GET").then((data) => {
      setLiquors(data);
    });
  }, []);
  const handleNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    var str = value;
    str = str.replace(/[^0-9]/g, "");
    var tmp = "";
    if (str.length < 4) {
      setPhone(str);
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3);
      setPhone(tmp);
      return tmp;
    } else if (str.length < 11) {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3, 3);
      tmp += "-";
      tmp += str.substr(6);
      setPhone(tmp);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3, 4);
      tmp += "-";
      tmp += str.substr(7);
      setPhone(tmp);
      return tmp;
    }
  };

  const reset = () => {
    setTitle("");
    setMessage("");
    setEmail("");
    setPhone("");
    setSelectDo({
      value: "0",
      label: "전체",
    });
    setLiquor({
      value: "1",
      label: "전체",
    });
  };

  const send = () => {
    if (!check) {
      toast("개인정보 이용동의를 체크해주세요");
    } else if (title !== "" && message !== "" && email !== "" && phone !== "") {
      const sendData = {
        subject: `${title} - 지역:${selectDo.label}/문의 주류:${liquor.label}`,
        phone,
        email,
        message,
      };
      apiFetch("/api/v2.0/contact/send", "POST", sendData).then((data) => {
        if (data.result === "email_error") {
          toast("이메일 전송에 실패했습니다.");
        } else {
          alert("문의를 주셔서 감사합니다. 빠른 시일 안에 답변을 드리겠습니다");
          window.history.back();
        }
      });
    } else {
      toast("항목을 모두 입력해주세요");
    }
  };

  const modalContent = (
    <ModalWrapper>
      <ModalHeaderWrapper>
        <ModalHeader>개인정보수집 및 이용동의</ModalHeader>
      </ModalHeaderWrapper>
      <ModalTextWrapper>
        <ModalText>
          세왕상사(주)는 다음과 같이 개인정보를 수집하여 이용합니다.
        </ModalText>
      </ModalTextWrapper>
      <ModalSubtitleWrapper>
        <ModalSubtitle>1. 정보 수집 항목</ModalSubtitle>
      </ModalSubtitleWrapper>
      <ModalTextWrapper>
        <ModalText>- 성명</ModalText>
        <ModalText>- 연락처(전화번호, e-mail등)</ModalText>
      </ModalTextWrapper>
      <ModalSubtitleWrapper>
        <ModalSubtitle>2. 수집정보 활용 및 목적</ModalSubtitle>
      </ModalSubtitleWrapper>
      <ModalTextWrapper>
        <ModalText>- ① 문의 사항 상담 및 회신</ModalText>
        <ModalText>- ② 기타 문의내용 처리와 관련한 일체의 행위</ModalText>
      </ModalTextWrapper>
      <ModalSubtitleWrapper>
        <ModalSubtitle>3. 수집정보의 보유 및 이용기간</ModalSubtitle>
      </ModalSubtitleWrapper>
      <ModalTextWrapper>
        <ModalText>- 상담 종료 후 즉시 파기</ModalText>
      </ModalTextWrapper>
      <ModalTextWrapper>
        <ModalText>
          ※ 정보주체는 위 개인정보의 수집 및 활용을 거부할 수 있으며, 거부시
          문의 내용에 대한 회신 및 상담이 불가 합니다.
        </ModalText>
      </ModalTextWrapper>
    </ModalWrapper>
  );

  const openModal = () => {
    showModal(modalContent);
  };

  return (
    <section>
      <Layout>
        <PageWrapper>
          <GrayBorder />
          <SearchWrapper>
            <LeftWrapper>
              <SearchButton onClick={send}>
                <img src="https://d17mmzdc0h66bb.cloudfront.net/image/contact_mail.svg" />
                <span>보내기</span>
              </SearchButton>
            </LeftWrapper>
            <RightWrapper>
              <SelectWrapper>
                <SelectLabel>담당자</SelectLabel>
                <SelectContainer>
                  <Select
                    borderDisabled={true}
                    height={"40%"}
                    imageWidth={"17%"}
                    selectOptions={dos}
                    search={selectDo}
                    onChange={(option) => setSelectDo(option)}
                  />
                </SelectContainer>
              </SelectWrapper>
              <SelectWrapper>
                <SelectLabel>문의 주류</SelectLabel>
                <SelectContainer>
                  <Select
                    borderDisabled={true}
                    height={"40%"}
                    imageWidth={"17%"}
                    selectOptions={liquors}
                    search={liquor}
                    onChange={(option) => setLiquor(option)}
                  />
                </SelectContainer>
              </SelectWrapper>
            </RightWrapper>
          </SearchWrapper>
          <GrayBorder />
          <RowWrapper>
            <RowLabel>제목</RowLabel>
            <RowInput
              onChange={(e) => setTitle(e.target.value)}
              placeholder="제목을 입력해주세요"
            />
          </RowWrapper>
          <RowWrapper>
            <RowArea
              onChange={(e) => setMessage(e.target.value)}
              rows={8}
              placeholder="문의하실 내용을 입력해주세요"
            />
          </RowWrapper>
          <RowWrapper>
            <RowLabel>메일</RowLabel>
            <RowInput
              onChange={(e) => setEmail(e.target.value)}
              placeholder="이메일을 입력해주세요"
            />
          </RowWrapper>
          <RowWrapper>
            <RowLabel>핸드폰번호</RowLabel>
            <RowInput
              value={phone || ""}
              maxLength={13}
              placeholder="핸드폰 번호를 입력해주세요"
              onChange={handleNumber}
            />
          </RowWrapper>
          <BottomWrapper>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={check}
              onChange={(e) => setCheck(e.target.checked)}
            />
            <RowLabel
              style={{ cursor: "pointer" }}
              onClick={() => setCheck((prev) => !prev)}
            >
              개인정보 이용 동의
            </RowLabel>
            <SearchButton
              style={{
                padding: "1% 2%",
              }}
              onClick={openModal}
            >
              전문보기
            </SearchButton>
          </BottomWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default ContactEdit;
