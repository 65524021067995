import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { infoMottoData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: #f4f4f4;
  padding: 50px 0;
`;
const HeaderWrapper = styled.div`
  margin: 40px 0;
`;
const Header = styled.div`
  ${fontSize(34, "M", "black")}
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  padding-bottom: 14%;
  background: #137fb9;
  border-radius: 30px;
  &.color {
    background: #00afce;
  }
`;
const ContentSubTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 1% 2%;
  border-radius: 0 0 30px 0;
  ${fontSize(25, "M", "#137fb9")}
`;
const ContentTitle = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 30%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3%;
  padding-top: 1.5%;
  ${fontSize(40, "M", "white")}
`;
const ContentDesc = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 64%;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: flex-start;
  ${fontPSize(22, "R", "white")}
  ${lineHeight(36)}
  padding: 0 3%;
`;
const InfoMotto = () => {
  return (
    <section>
      <PageWrapper>
        <Layout>
          <HeaderWrapper>
            <Header>회사 사훈</Header>
          </HeaderWrapper>
          <ContentWrapper>
            {infoMottoData.map((d, index) => (
              <ContentContainer key={d.id}>
                <Content className={index % 2 == 1 ? "color" : ""}>
                  <ContentSubTitle>{d.subTitle}</ContentSubTitle>
                  <ContentTitle>{d.title}</ContentTitle>
                  <ContentDesc>{d.desc}</ContentDesc>
                </Content>
              </ContentContainer>
            ))}
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoMotto;
