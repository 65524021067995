import React, { useEffect, useRef, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import Select from "../../components/select";
import useBottomSheetStore from "../../stores/bottomSheetStore";
import NewsContentSearch from "./newsContentSearch";

const PageWrapper = styled.div`
  padding: 8% 0px 4% 0px;
`;
const HeaderWrapper = styled.div`
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
`;
const HeaderTitle = styled.div`
  ${fontSize(25, "M", "black")}
`;
const HeaderDesc = styled.div`
  padding: 2% 0;
  ${fontSize(14, "L", "black")}
`;
const SearchWrapper = styled.div`
  display: flex;
  gap: 10%;
  position: relative;
  width: 6%;
  justify-content: flex-end;
`;

const SearchIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const NewsSearch = () => {
  const dropMenuRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { showBottomSheet } = useBottomSheetStore();

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
      if (isOpen && !dropMenuRef.current?.contains(e.target)) setIsOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isOpen]);

  return (
    <section style={{ overflow: "visible" }}>
      <Layout>
        <PageWrapper>
          <HeaderWrapper>
            <Header>
              <HeaderTitle>세왕 소식</HeaderTitle>
              <SearchWrapper>
                <SearchIcon
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/search_icon.svg"
                  onClick={() => {
                    showBottomSheet(<NewsContentSearch />);
                  }}
                />
              </SearchWrapper>
            </Header>
            <HeaderDesc>세왕상사의 공지사항 및 안내 소식입나다.</HeaderDesc>
          </HeaderWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default NewsSearch;
