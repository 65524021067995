import react, { KeyboardEvent, useRef } from "react";
import Layout from "./layout";
import styled from "styled-components";
import { apiFetch } from "../utils/fetch";
import useAuthStore from "../stores/authStore";
import { fontSize } from "../styles/Font";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const Header = styled.div`
  ${fontSize(40, "M", "black")}
`;
const InputWrapper = styled.div`
  display: flex;
  width: 25%;
  gap: 5%;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.div``;
const Input = styled.input`
  border: 1px solid #777777;
  padding: 5% 3%;
`;
const Button = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 2%;
`;
const LoginPage = () => {
  const idRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { setLoginState } = useAuthStore();
  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      loginFn();
    }
  };
  const loginFn = () => {
    if (idRef.current?.value && passwordRef.current?.value) {
      apiFetch("/api/v2.0/auth", "POST", {
        username: idRef.current?.value,
        password: passwordRef.current?.value,
      }).then((data) => {
        if (data.login === true) {
          setLoginState(data.login);
          localStorage.setItem("loginState", data.login);
          window.location.href = "/";
        } else {
          alert(data.message);
        }
      });
    }
  };
  return (
    <section>
      <Layout>
        <PageWrapper>
          <Header>관리자 로그인</Header>
          <InputWrapper>
            <Label>아이디</Label>
            <Input type="text" ref={idRef} />
          </InputWrapper>
          <InputWrapper>
            <Label>비밀번호</Label>
            <Input
              onKeyDown={handleOnKeyPress}
              type="password"
              ref={passwordRef}
            />
          </InputWrapper>
          <Button onClick={loginFn}>로그인</Button>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default LoginPage;
