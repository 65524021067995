import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyBjTi87RE1dyZ3xU01wXIl6v9wHfT_9xk4",
  version: "weekly",
  libraries: ["places"],
});

const mapOptions = {
  center: {
    lat: 37.5278025,
    lng: 127.1462271,
  },
  zoom: 16,
};

const PageWrapper = styled.div`
  padding: 10% 0;
`;
const HeaderWrapper = styled.div`
  padding: 5% 0;
  ${fontSize(20, "M", "black")}
`;
const Header = styled.div`
  margin-bottom: 2%;
`;
const HeaderDesc = styled.div`
  ${fontSize(14, "L", "black")}
`;
const MapWrapper = styled.div`
  padding-bottom: 45%;
`;
const MapImage = styled.img`
  width: 100%;
`;
const ContentWrapper = styled.div`
  padding: 3% 0%;
`;
const ContentTitle = styled.div`
  ${fontPSize(16, "B", "black")}
  ${lineHeight(30)}
  padding: 3% 0;
`;

const ContentText = styled.div`
  ${fontPSize(16, "R", "black")}
  ${lineHeight(25)}
`;
const DescWrapper = styled.div`
  position: relative;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 5% 0;
  gap: 5vw;
`;

const DescContent = styled.div`
  display: flex;
  gap: 5vw;
  align-items: center;
  padding: 6%;
  background: #f4f5fc;
  border-radius: 3vw;
`;

const DescIconImage = styled.img`
  width: 17%;
`;

const DescText = styled.div`
  ${fontSize(14, "L", "black")}
`;
const InfoPlace = () => {
  useEffect(() => {
    loader
      .importLibrary("maps")
      .then(({ Map }) => {
        const map = new Map(document.getElementById("map"), mapOptions);
        return map;
      })
      .then((map) => {
        loader.importLibrary("marker").then((Marker) => {
          new Marker.Marker({
            position: mapOptions.center,
            map,
          });
        });
      });
  }, []);
  return (
    <section>
      <PageWrapper>
        <Layout>
          <HeaderWrapper>
            <Header>오시는 길</Header>
            <HeaderDesc>
              지역별 담당자 및 문의처를 확인할 수 있습니다.
            </HeaderDesc>
          </HeaderWrapper>
        </Layout>
        <MapWrapper id="map"></MapWrapper>
        <Layout>
          <ContentWrapper>
            <ContentTitle>(주) 세왕상사</ContentTitle>
            <ContentText>서울 강동구 진황도로61길 60</ContentText>
            <ContentText>
              지하철 9호선 보훈병원역 1번출구 1분거리 위치
            </ContentText>
            <DescWrapper>
              <DescContent>
                <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_1.png" />
                <DescText>+82 2 2203 5311</DescText>
              </DescContent>
              <DescContent>
                <DescIconImage src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_place_icon_2.png" />
                <DescText>sewang88@naver.com</DescText>
              </DescContent>
            </DescWrapper>
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoPlace;
