import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import ToastEditor from "../../components/editor";
import ToastViewer from "../../components/viewer";
import { fontPSize, fontSize } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { apiFetch } from "../../utils/fetch";
import useLinkClick from "../../hooks/useLinkClick";

const PageWrapper = styled.div`
  padding-top: 10%;
  padding-bottom: 20%;
  .rotate {
    transform: rotate(180deg);
  }
`;

const ContentHeaderWrapper = styled.div``;
const BlackBorder = styled.div`
  background: #000;
  height: 1px;
`;
const GrayBorder = styled.div`
  background: rgba(119, 119, 119, 0.5);
  height: 1px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5% 0;
`;
const Title = styled.div`
  ${fontSize(25, "M", "black")}
  flex: 6;
`;
const NextPrevWrapper = styled.div`
  ${fontSize(18, "L", "#777777")}
  display: flex;
  flex: 1;
  gap: 10%;
  align-items: center;
  justify-content: flex-end;
  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    & > img {
      width: 32%;
    }
  }
`;
const SubtitleWrapper = styled.div`
  ${fontSize(14, "L", "#777777")}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 0px;
  & > div {
    display: flex;
  }
`;
const SubtitleContainer = styled.div`
  display: flex;
  flex: 10;
  gap: 1%;
`;
const LintContainer = styled.div`
  display: flex;
  gap: 3%;
  flex: 1;
  & > div {
    cursor: pointer;
    width: 10%;
    & > img {
      width: 100%;
    }
  }
`;
const ContentWrapper = styled.div`
  & p {
    ${fontPSize(14, "R", "black")}
  }
`;
const ContentFooterWrapper = styled.div`
  ${fontSize(18, "L", "#777777")}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5% 0;
  padding-top: 10%;
  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    flex: 1;
    justify-content: center;
    gap: 3%;
    padding: 3% 0;
    background: rgba(119, 119, 119, 0.08);
    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
    $ > img {
      width: 28%;
    }
  }
`;

const NewsContent = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const linkClick = useLinkClick();
  const ref = useRef<any>(null);
  const [data, setData] = useState<
    Board & { prev_id: number | null; next_id: number | null }
  >();
  useEffect(() => {
    const _query = queryString.parse(location.search);
    if (_query.id) {
      window.scrollTo(0, 0);
      setData(undefined);
      apiFetch(`/api/v2.0/boardById?id=${_query.id}`, "GET").then((_data) => {
        setData(_data);
      });
    }
  }, [location.search]);
  // const data = {
  //   id: 1,
  //   date: "2022-12-12 12:00",
  //   title: "테스트",
  //   desc: "<img src='image/image.jpeg' /><p>2020년 5월 1일 세왕상사 주식회사의 제2대 정민성 대표이사 취임식을 진행하였습니다. </p><p>바쁘신 일정에도 행사에 참석하시어 자리를 빛내주신 각 제조사 임직원분들께 감사의 인사를 전합니다. </p><p>새로 취임하신 정민성 대표이사님과 발전해 가는 세왕상사(주)가 되겠습니다.</p>",
  // };

  return (
    <section>
      <PageWrapper>
        <Layout>
          <ContentHeaderWrapper>
            <TitleWrapper>
              <Title>{data && data.title}</Title>
              {/* <NextPrevWrapper>
                <div>
                  <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg" />
                  <div>이전글</div>
                </div>
                <div>
                  <div>다음글</div>
                  <img className="rotate" src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg" />
                </div>
              </NextPrevWrapper> */}
            </TitleWrapper>
            {/* <GrayBorder /> */}
            <SubtitleWrapper>
              <SubtitleContainer>
                <div>관리자</div>
                <div>|</div>
                <div>{data && data.date}</div>
                <div>|</div>
                <div>조회수: {data && data.viewCount}</div>
              </SubtitleContainer>
            </SubtitleWrapper>
            <SubtitleWrapper>
              <LintContainer>
                <div
                  onClick={() => {
                    if (data && data.link_url) {
                      window.open(data.link_url);
                    }
                  }}
                >
                  {data && data.link_url && (
                    <img src="https://d17mmzdc0h66bb.cloudfront.net/image/nLink.png" />
                  )}
                </div>
                <div onClick={linkClick}>
                  <img src="https://d17mmzdc0h66bb.cloudfront.net/image/share.svg" />
                </div>
              </LintContainer>
            </SubtitleWrapper>
          </ContentHeaderWrapper>
          <ContentWrapper>
            {data && <ToastViewer content={data.desc} editorRef={ref} />}
          </ContentWrapper>
        </Layout>
        <ContentFooterWrapper>
          <div
            className={data && data.prev_id != null ? "" : "disabled"}
            onClick={() => {
              if (data && data.prev_id != null)
                navigator(`/news?id=${data.prev_id}`);
            }}
          >
            <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg" />
            <div>이전글</div>
          </div>
          <div
            className={data && data.next_id != null ? "" : "disabled"}
            onClick={() => {
              if (data && data.next_id != null)
                navigator(`/news?id=${data.next_id}`);
            }}
          >
            <div>다음글</div>
            <img
              className="rotate"
              src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right_small.svg"
            />
          </div>
        </ContentFooterWrapper>
      </PageWrapper>
    </section>
  );
};

export default NewsContent;
