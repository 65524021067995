import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Link,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import EditPage from "./containers/edit";
import styled from "styled-components";
import HomePage from "./containers/home";
import { fontSize } from "./styles/Font";
import ViewLayout from "./containers/layout";
import "react-slideshow-image/dist/styles.css";
import { Default, Mobile } from "./styles/responsive";
import InfoPage from "./containers/info";
import ItemPage from "./containers/item";
import Modal from "./containers/modal";
import Footer from "./containers/footer";
import NewsPage from "./containers/news";
import ContactPage from "./containers/contact";
import ContactEditPage from "./containers/contactEdit";
import FloatingButton from "./containers/floating";
import BottomSheet from "./containers/bottomSheet";
import LoginPage from "./containers/login";
import { apiFetch } from "./utils/fetch";
import useAuthStore from "./stores/authStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteChangeTracker from "./routeChangeTracker";

const StickyHeader = styled.div`
  width: 100%;
  z-index: 1999;
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;
const HeaderContainer = styled.nav`
  float: right;
  position: relative;
  width: 100%;
`;
const HeaderListWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const HeaderList = styled.ul`
  width: 100%;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  justify-content: center;
  padding-left: 0;
  display: flex;
  ${fontSize(20, "L", "black")}
  @media screen and (max-width: 1440px) {
    justify-content: flex-end;
    margin-right: 20px;
  }
`;

const HeaderListItem = styled.li`
  list-style: none;
  color: #000;
`;
const HeaderListLink = styled(Link)`
  color: #343f52;
  // width: 100%;
  padding: 25px 0;
  display: flex;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  transition-property: none;
  &:hover {
    color: #137fb9;
  }
  &.active {
    color: #137fb9;
  }
`;

const ToggleWrapper = styled.div`
  background: rgb(0, 175, 206);
  display: flex;
  align-items: center;
  width: 15%;
  & > img {
    width: 100%;
  }
`;

const MobileHeaderList = styled.ul<{ isOpen: boolean }>`
  width: 100%;
  padding-left: 0;
  display: flex;
  position: absolute;
  left: -20px;
  width: 100vw;
  height: 100vh;
  background: #fff;
  margin: 0;
  flex-direction: column;
  transition: all 0.2s;
  visibility: hidden;
  ${fontSize(20, "M", "black")}
  &.isOpen {
    top: 0;
    visibility: visible;
    // animation: App-logo-spin normal 0.2s linear;
  }
  &.isClose {
    top: -100vh;
    visibility: hidden;
    // animation: App-logo-spin2 normal 0.2s linear;
  }
`;

const MobileHeaderListItem = styled.li`
  list-style: none;
  color: #000;
  // background: rgb(0, 175, 206);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
const MobileHeaderBorder = styled.div`
  position: absolute;
  height: 100%;
  width: 2%;
  left: 0;
  top: 0;
  background: rgb(0, 175, 206);
`;
const MobileHeaderListLink = styled(Link)`
  width: 100%;
  padding: 25px 0;
  display: flex;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  transition-property: none;
  justify-content: center;
`;

const MobileCloseWrapper = styled.div`
  text-align: end;
  padding: 20px;
  cursor: pointer;
`;

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

function Root() {
  RouteChangeTracker();
  const { setLoginState, checkAuth } = useAuthStore();
  useEffect(() => {
    if (localStorage.getItem("loginState")) {
      setLoginState(true);
      checkAuth();
    }
  }, [localStorage.getItem("loginState")]);
  return (
    <>
      <Mobile>
        <Routes>
          <Route path="/" element={<MobileLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/item" element={<ItemPage />} />
            <Route path="/news" element={<NewsPage />} />
            {/* <Route path="/newsEdit" element={<EditPage />} /> */}
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/contactEdit" element={<ContactEditPage />} />
          </Route>
        </Routes>
        <Modal />
        <BottomSheet />
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />
      </Mobile>
      <Default>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/item" element={<ItemPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/newsEdit" element={<EditPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/contactEdit" element={<ContactEditPage />} />
          </Route>
          <Route path="/adminLogin" element={<LoginPage />} />
          {/* <Route path="/admin" element={<AdminLayout />}></Route> */}
        </Routes>
        <Modal />
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />
      </Default>
    </>
  );
}

function MobileLayout() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuOpen(false);
  }, [pathname]);
  const { loginState, setLoginState } = useAuthStore();
  const logout = () => {
    apiFetch("/api/v2.0/auth", "GET").then((data) => {
      setLoginState(false);
      localStorage.removeItem("loginState");
      if (data.message) {
        alert(data.message);
      }
      window.location.href = "/";
    });
  };
  return (
    <>
      <StickyHeader>
        <ViewLayout
          style={{
            background: "#FFF",
            borderBottom: "1px solid rgba(0, 175, 206, 1)",
          }}
        >
          <HeaderContainer>
            <HeaderListWrapper>
              <HeaderListLink style={{ flex: 1 }} to="/">
                <img
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/logo.png"
                  style={{ width: "80%" }}
                />
              </HeaderListLink>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                onClick={() => setMenuOpen((prev) => !prev)}
              >
                <ToggleWrapper>
                  <img src="https://d17mmzdc0h66bb.cloudfront.net/image/toggle.png" />
                </ToggleWrapper>
              </div>
              <MobileHeaderList
                className={menuOpen ? "isOpen" : "isClose"}
                isOpen={menuOpen}
              >
                <MobileHeaderListItem>
                  <MobileCloseWrapper
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    X
                  </MobileCloseWrapper>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  {pathname === "/" && <MobileHeaderBorder />}
                  <MobileHeaderListLink
                    className={pathname === "/" ? "active" : ""}
                    to="/"
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    홈
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  {pathname === "/info" && <MobileHeaderBorder />}
                  <MobileHeaderListLink
                    className={pathname === "/info" ? "active" : ""}
                    to="/info"
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    회사소개
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  {pathname === "/item" && <MobileHeaderBorder />}
                  <MobileHeaderListLink
                    className={pathname === "/item" ? "active" : ""}
                    to="/item"
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    상품소개
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  {pathname === "/news" && <MobileHeaderBorder />}
                  <MobileHeaderListLink
                    className={pathname === "/news" ? "active" : ""}
                    to="/news?index=0&type=&search="
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    세왕소식
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  {pathname === "/contact" && <MobileHeaderBorder />}
                  <MobileHeaderListLink
                    className={pathname === "/contact" ? "active" : ""}
                    to="/contact?index=0&prov_id=0&liquors_name="
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    문의하기
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                <MobileHeaderListItem>
                  <MobileHeaderListLink
                    to="https://gw.sewang.co.kr"
                    onClick={() => setMenuOpen((prev) => !prev)}
                  >
                    전자결재
                  </MobileHeaderListLink>
                </MobileHeaderListItem>
                {loginState && (
                  <MobileHeaderListItem>
                    <MobileHeaderListLink to="#" onClick={logout}>
                      로그아웃
                    </MobileHeaderListLink>
                  </MobileHeaderListItem>
                )}
              </MobileHeaderList>
            </HeaderListWrapper>
          </HeaderContainer>
        </ViewLayout>
      </StickyHeader>
      <Outlet />
      <Footer />
      <FloatingButton />
    </>
  );
}

function Layout() {
  const { pathname } = useLocation();
  const { loginState, setLoginState } = useAuthStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const logout = () => {
    apiFetch("/api/v2.0/auth", "GET").then((data) => {
      setLoginState(false);
      localStorage.removeItem("loginState");
      if (data.message) {
        alert(data.message);
      }
      window.location.href = "/";
    });
  };
  return (
    <>
      <StickyHeader style={{ borderBottom: "1px solid rgba(0, 175, 206, 1)" }}>
        <ViewLayout>
          <HeaderContainer>
            <HeaderListWrapper>
              <HeaderListLink style={{ margin: "10px 0" }} to="/">
                <img
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/logo.png"
                  style={{ width: "calc(250*100vw/1920)", maxWidth: "250px" }}
                />
              </HeaderListLink>
              <HeaderList>
                <HeaderListItem>
                  <HeaderListLink
                    className={pathname === "/info" ? "active" : ""}
                    to="/info"
                  >
                    회사소개
                  </HeaderListLink>
                </HeaderListItem>
                <HeaderListItem>
                  <HeaderListLink
                    className={pathname === "/item" ? "active" : ""}
                    to="/item"
                  >
                    상품소개
                  </HeaderListLink>
                </HeaderListItem>
                <HeaderListItem>
                  <HeaderListLink
                    className={pathname === "/news" ? "active" : ""}
                    to="/news?index=0&type=&search="
                  >
                    세왕소식
                  </HeaderListLink>
                </HeaderListItem>
                <HeaderListItem>
                  <HeaderListLink
                    className={pathname === "/contact" ? "active" : ""}
                    to="/contact?index=0&prov_id=0&liquors_name="
                  >
                    문의하기
                  </HeaderListLink>
                </HeaderListItem>
                <HeaderListItem>
                  <HeaderListLink to="https://gw.sewang.co.kr">
                    전자결재
                  </HeaderListLink>
                </HeaderListItem>
                {loginState && (
                  <HeaderListItem>
                    <HeaderListLink to="#" onClick={logout}>
                      로그아웃
                    </HeaderListLink>
                  </HeaderListItem>
                )}
              </HeaderList>
            </HeaderListWrapper>
          </HeaderContainer>
        </ViewLayout>
      </StickyHeader>
      <Outlet />
      <Footer />
      <FloatingButton />
    </>
  );
}

export default App;
