import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize } from "../../styles/Font";
import { useNavigate } from "react-router";

const PageWrapper = styled.div`
  position: relative;
  background: rgba(8, 84, 124, 1);
`;
const HeaderWrapper = styled.div`
  ${fontPSize(14, "R", "white")}
  padding: 2% 0;
  display: flex;
  align-items: center;
`;

const ContactHeader = () => {
  const navigator = useNavigate();
  return (
    <section>
      <PageWrapper>
        <Layout>
          <HeaderWrapper>
            <img
              style={{
                cursor: "pointer",
                filter:
                  "invert(100%) sepia(0%) saturate(7450%) hue-rotate(191deg) brightness(300%) contrast(96%)",
              }}
              onClick={() => {
                navigator("/");
              }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/home.svg"
            />
            <img
              style={{ filter: "brightness(100)" }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
            />
            <div>문의하기</div>
          </HeaderWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default ContactHeader;
