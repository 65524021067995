import React from "react";
import styled from "styled-components";
import { fontSize } from "../styles/Font";
import { useNavigate } from "react-router";

const Wrapper = styled.div`
  width: 5%;
  max-width: 100px;
  display: flex;
  position: fixed;
  bottom: 3%;
  right: 0;
  z-index: 2;
  flex-direction: column;
  align-items: flex-end;
`;
const Container = styled.div<{ background?: string }>`
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  z-index: 10;
  cursor: pointer;
  border: 1px solid rgba(119, 119, 119, 0.5);
  ${(props) => (props.background ? `background: ${props.background};` : "")}
`;
const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Text = styled.div<{ color?: string }>`
  ${(props) =>
    props.color ? fontSize(12, "M", props.color) : fontSize(12, "M", "white")}
`;
const IconWrapper = styled.div`
  width: 25%;
  & > img {
    width: 100%;
  }
`;

const FloatingButton = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Container
        background={"rgba(0, 175, 206, 1)"}
        onClick={() => {
          navigate("/contactEdit");
        }}
      >
        <Content>
          <IconWrapper>
            <img src="https://d17mmzdc0h66bb.cloudfront.net/image/send.svg" />
          </IconWrapper>
          <Text color={"rgba(255, 255, 255, 1)"}>문의하기</Text>
        </Content>
      </Container>
      <Container
        background={"rgba(255, 255, 255, 1)"}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Content>
          <IconWrapper>
            <img src="https://d17mmzdc0h66bb.cloudfront.net/image/vertical_align_top.svg" />
          </IconWrapper>
          <Text color={"rgba(119, 119, 119, 1)"}>TOP</Text>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default FloatingButton;
