import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import useBottomSheetStore from "../stores/bottomSheetStore";
import "react-spring-bottom-sheet/dist/style.css";

const BottomSheetContainer = () => {
  const { isShow, hideBottomSheet, bottomSheetData } = useBottomSheetStore();

  return (
    <BottomSheet
      scrollLocking={false}
      open={isShow}
      onDismiss={() => hideBottomSheet()}
    >
      {bottomSheetData}
    </BottomSheet>
  );
};

export default BottomSheetContainer;
