import React, { useEffect, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import Select from "../../components/select";
import useBoardStore from "../../stores/boardStore";
import { apiFetch } from "../../utils/fetch";
import queryString from "query-string";

const PageWrapper = styled.div`
  padding: 0 0px 20% 0;
`;
const Wrapper = styled.div`
  padding-bottom: 7%;
`;

const WrapperHeader = styled.div`
  ${fontSize(14, "L", "#777777")}
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 38%;
  margin: 2.5% 0px;
  cursor: pointer;
`;
const ContentImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const ContentTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
`;

const ContentTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ContentDate = styled.div`
  ${fontPSize(14, "R", "white")}
`;
const ContentTitle = styled.div`
  ${fontSize(16, "M", "white")}
  padding: 1% 3%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  width: 94%;
  text-align: center;
`;
const ContentDesc = styled.div`
  padding-top: 8%;
  ${fontSize(20, "L", "#777777")}
  ${lineHeight(38)}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const PageNationWrapper = styled.div`
  display: flex;
  justify-content: center;
  // width: 50%;
  align-items: center;
  margin: auto;
  // gap: 5%;
`;
const PageNationContent = styled.div<{ disabled?: boolean }>`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  background: rgba(119, 119, 119, 0.08);
  padding: 3% 0;
  ${(props) => (props.disabled ? "opacity:0.5" : "")};
`;

const PageNationTextContent = styled.div<{ disabled?: boolean }>`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  & > div {
    padding-bottom: 37%;
    & > div {
      justify-content: center;
      align-items: center;
      &:first-child {
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 5%;
        & > div {
          color: rgba(0, 175, 206, 1);
          & > img {
            width: 26%;
            filter: invert(45%) sepia(98%) saturate(1800%) hue-rotate(155deg)
              brightness(98%) contrast(101%);
          }
        }
      }
      &:last-child {
        max-height: 500%;
        overflow: scroll;
        & > div {
          align-items: center;
          width: calc(100% - 2px);
          justify-content: center;
          gap: 5%;
          & > img {
            width: 26%;
          }
        }
      }
    }
  }
`;
const PageNationText = styled.div`
  ${fontSize(14, "L", "#777777")}
  &.active {
    ${fontSize(16, "M", "rgba(19, 127, 185, 1)")}
  }
`;

const PageNationIcon = styled.img`
  width: 19%;

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(270deg);
  }
`;

const NewsContentView = () => {
  const { search } = useLocation();
  const navigator = useNavigate();
  const [index, setIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(1); // 총페이지수
  const { boards, boardTotalCount, setBoards, setBoardTotalCount } =
    useBoardStore();

  const imageParser = (desc: string) => {
    const regexr = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/;
    const imageSrcArray = desc.match(regexr);
    const imageSrc = imageSrcArray ? imageSrcArray[1] : "";
    return imageSrc;
  };
  const tagParser = (desc: string) => {
    const regexr2 = /(<([^>]+)>)/g;
    const removeTagText = desc.replace(regexr2, "");
    return removeTagText;
  };
  useEffect(() => {
    const _query =
      search && search !== "" ? queryString.parse(search) : undefined;
    if (_query?.index !== undefined && _query.index !== null) {
      const _index = parseInt(_query.index.toString());
      const type = _query.type || "";
      const searchText = _query.search || "";
      apiFetch(
        `/api/v2.0/boardPageApi?type=${type}&search=${searchText}&offset=${
          _index * 6
        }&limit=6`,
        "GET"
      ).then((data) => {
        setBoards(data[0]);
        setBoardTotalCount(data[1]);
      });
      setIndex(_index);
      window.scrollTo(0, 0);
    }
  }, [search]);
  useEffect(() => {
    setMaxIndex(Math.ceil(boardTotalCount / 6));
  }, [boardTotalCount]);

  const renderPageNation = () => {
    const page = Math.floor(index / 10);
    const result = [];
    const selectData = [];
    const first = page * 10;
    const last = page * 10 + 10;
    // if (index > 0) {
    result.push(
      <PageNationContent
        key={"prev"}
        disabled={index <= 0}
        onClick={() => index > 0 && navigator(`/news?index=${index - 1}`)}
      >
        <PageNationIcon
          className="left"
          src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
        />
        <PageNationText>이전</PageNationText>
      </PageNationContent>
    );
    // }
    for (let i = first; i < last; i++) {
      if (i + 1 > maxIndex) break;
      selectData.push({
        value: i,
        label: `${i + 1} 페이지`,
      });
      // result.push(
      //   <PageNationContent
      //     onClick={() => {
      //       setIndex(i);
      //     }}
      //   >
      //     <PageNationText className={index === i ? "active" : ""}>
      //       {i + 1}
      //     </PageNationText>
      //   </PageNationContent>
      // );
    }
    result.push(
      <PageNationTextContent key={"page"}>
        <Select
          borderDisabled={true}
          selectOptions={selectData}
          search={{ value: index, label: `${index + 1} 페이지` }}
          onChange={(value) => navigator(`/news?index=${value.value}`)}
        />
      </PageNationTextContent>
    );
    // if (index < maxIndex - 1) {
    result.push(
      <PageNationContent
        key={"next"}
        disabled={index >= maxIndex - 1}
        onClick={() =>
          index < maxIndex - 1 && navigator(`/news?index=${index + 1}`)
        }
      >
        <PageNationText>다음</PageNationText>
        <PageNationIcon
          className="right"
          src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
        />
      </PageNationContent>
    );
    // }

    return result;
  };
  return (
    <section
      style={{
        overflow: "visible",
      }}
    >
      <PageWrapper>
        <Layout>
          <Wrapper>
            <WrapperHeader>총 개시글({boardTotalCount})</WrapperHeader>
            <Container>
              {boards.map(
                (d) =>
                  d && (
                    <Content
                      onClick={() => {
                        navigator(`/news?id=${d.id}`);
                      }}
                      key={`content_${d.id}`}
                    >
                      <ContentImageWrapper>
                        <img src={imageParser(d.desc)} />
                      </ContentImageWrapper>
                      <ContentTextWrapper>
                        <ContentTextContainer>
                          <ContentTitle>{d.title}</ContentTitle>
                          <ContentDate>{d.date}</ContentDate>
                        </ContentTextContainer>
                      </ContentTextWrapper>
                    </Content>
                  )
              )}
            </Container>
          </Wrapper>
        </Layout>
        <PageNationWrapper>{renderPageNation()}</PageNationWrapper>
      </PageWrapper>
    </section>
  );
};

export default NewsContentView;
