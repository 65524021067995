import React, { useRef, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize } from "../../styles/Font";
import Select from "../../components/select";
import { useNavigate } from "react-router";
import useBottomSheetStore from "../../stores/bottomSheetStore";
import { searchTypeOptions } from "../../utils/datas";

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 7%;

  padding: 2% 5%;
  width: 90%;
`;

const SearchTitle = styled.div`
  ${fontSize(14, "L", "black")}
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SearchInput = styled.input`
  padding: 3% 5%;
  border: 1px solid rgba(119, 119, 119, 0.5);
  width: 90%;
  ${fontPSize(14, "R", "#777777")}
`;
const SelectContainer = styled.div`
  width: 100%;
  & > div > div:first-child {
    width: 90%;
    padding: 0 5%;
    & > div {
      ${fontPSize(14, "R", "#777777")}
      justify-content: space-between;
      & > img {
        width: 7%;
      }
    }
  }
  & > div > div:last-child {
    height: 220%;
    overflow: hidden scroll !important;
    -webkit-overflow-scrolling: touch;
    & > div {
      ${fontPSize(14, "R", "#777777")}
      width: calc(90% - 2px);
      height: auto;
      padding: 2% 5%;
    }
  }
`;
const SearchButton = styled.div`
  ${fontSize(14, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 5% 0;
`;

const NewsContentSearch = () => {
  const navigator = useNavigate();
  const { hideBottomSheet } = useBottomSheetStore();
  const [search, setSearch] = useState({
    value: "title",
    label: "제목",
  });
  const [text, setText] = useState("");

  const searchFn = () => {
    const url = `/news?index=0&type=${search.value}&search=${text}`;
    navigator(url);
    hideBottomSheet();
  };
  return (
    <section style={{ overflow: "visible" }}>
      <SearchWrapper>
        <SelectWrapper>
          <SearchTitle>검색</SearchTitle>
        </SelectWrapper>
        <SelectWrapper>
          {/* <SelectLabel>도 구분</SelectLabel> */}
          <SelectContainer>
            <Select
              imageWidth={"27%"}
              height={"10%"}
              selectOptions={searchTypeOptions}
              search={search}
              onChange={(option) => setSearch(option)}
            />
          </SelectContainer>
        </SelectWrapper>
        <SelectWrapper>
          {/* <SelectLabel>시/군/구 구분</SelectLabel> */}
          <SelectContainer>
            <SearchInput
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="키워드"
            />
          </SelectContainer>
        </SelectWrapper>
        <SelectWrapper style={{ paddingBottom: "10%" }}>
          <SearchButton
            onClick={() => {
              searchFn();
            }}
          >
            담당자 검색
          </SearchButton>
        </SelectWrapper>
      </SearchWrapper>
    </section>
  );
};

export default NewsContentSearch;
