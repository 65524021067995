import React, { useEffect, useRef, useState } from "react";
import ToastEditor from "../components/editor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiFetch } from "../utils/fetch";
import useAuthStore from "../stores/authStore";
import { useLocation } from "react-router";
import queryString from "query-string";

type FormValues = {
  title: string;
};

function EditPage() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const ref = useRef<any>(null);
  const { checkAuth } = useAuthStore();
  const { search } = useLocation();
  const [newsId, setNewsId] = useState();
  const [oldContent, setOldContent] = useState<string>();
  useEffect(() => {
    const _query = queryString.parse(search);
    if (_query.id) {
      apiFetch(`/api/v2.0/boardById?id=${_query.id}`, "GET").then((data) => {
        setNewsId(data.id);
        setOldContent(data.desc);
        reset({ title: data.title });
      });
    } else {
      setOldContent(" ");
    }
  }, [search]);
  useEffect(() => {
    checkAuth();
  }, []);
  const htmlParser = (html: string | undefined) => {
    const parseData = html
      ?.replace(/(<p>)|(<\/strong>)|(<\/em>)|(<\/del>)|(<br>)/g, "")
      .replace(
        /(<\/p>)|(<\/h1>)|(<\/h2>)|(<\/h3>)|(<\/h4>)|(<\/h5>)|(<\/h6>)|(" contenteditable="false">)/g,
        "\n"
      )
      .replace(/(<strong>)/g, "bold:")
      .replace(/(<h1>)/g, "h1:")
      .replace(/(<h2>)/g, "h2:")
      .replace(/(<h3>)/g, "h3:")
      .replace(/(<h4>)/g, "h4:")
      .replace(/(<h5>)/g, "h5:")
      .replace(/(<h6>)/g, "h6:")
      .replace(/(<em>)/g, "italic:")
      .replace(/(<del>)/g, "del:")
      .replace(
        /(<img src="https:\/\/d17mmzdc0h66bb.cloudfront.net\/)/g,
        "image:"
      );
    return parseData;
  };

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        try {
          const editorIns = ref?.current?.getInstance();
          // 에디터 작성 내용 markdown으로 저장
          const contentHtml = editorIns.getHTML();
          const parseData = htmlParser(contentHtml);
          // contentMark 길이 체크
          if (parseData?.length === 0) {
            throw new Error("내용을 입력해주세요.");
          }
          if (newsId) {
            const addedData = {
              id: newsId,
              title: data.title,
              content: contentHtml,
              post: `title:${data.title}\n${parseData}`,
            };
            apiFetch("/api/v2.0/boardWrite", "PUT", addedData).then((data) => {
              window.location.href = "/news?index=0&type=&search=";
            });
          } else {
            const addedData = {
              title: data.title,
              content: contentHtml,
              post: `title:${data.title}\n${parseData}`,
            };
            apiFetch("/api/v2.0/boardWrite", "POST", addedData).then((data) => {
              window.location.href = "/news?index=0&type=&search=";
            });
          }
        } catch (e) {
          toast.error(`${e}` || "다시 시도해주세요.", {
            autoClose: 1000,
          });
        }
      })}
      className="h-screen w-full"
    >
      <div className="mx-2 my-4 p-2 md:mx-8 lg:mx-8">
        <div className="relative">
          <label htmlFor="name" className="text-sm leading-7 text-gray-600">
            제목{" "}
            <span className="ml-2 text-xs text-red-500">
              {errors.title?.message}
            </span>
          </label>
          <input
            {...register("title", {
              required: "필수 입력 사항입니다.",
            })}
            type="text"
            id="title"
            name="title"
            placeholder="제목을 입력해주세요"
            className="w-full rounded border border-gray-300 bg-gray-100 bg-opacity-50 py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out placeholder:text-sm focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200"
          />
        </div>
      </div>

      {/* // 에디터 컴포넌트 추가 */}
      {oldContent && <ToastEditor content={oldContent} editorRef={ref} />}
      {/* // 뒤로가기 및 작성 버튼 추가 */}
      <div className="fixed bottom-0 flex h-12 w-full lg:h-14">
        <button
          className="h-full w-[40%] bg-gray-500 text-sm font-medium text-white hover:bg-gray-700 md:text-base lg:text-base"
          // onClick={handleGoBack}
        >
          뒤로가기
        </button>
        <button
          className="h-full w-full bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 md:text-base lg:text-base"
          type="submit"
        >
          작성하기
        </button>
      </div>
    </form>
  );
}

export default EditPage;
