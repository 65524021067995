import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize } from "../../styles/Font";
import { Fade, SlideshowRef } from "react-slideshow-image";
import { slideOptions } from "../../utils/datas";

const PageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 175, 206, 0) 0%,
    rgba(0, 175, 206, 0.3) 100%
  );
`;
const SlideWrapper = styled.div`
  position: relative;
  padding: 100px 0;

  & ul.indicators {
    position: absolute;
    bottom: 100px;
    left: 0;
    padding: 0;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
`;
const ContentTextWrapper = styled.div`
  flex: 1;
`;
const ContentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0 0 10px 10px;
`;
const ContentTitle = styled.div`
  color: rgb(19, 127, 185) !important;
  text-align: center;
  padding-top: 30px;
  ${fontSize(20, "B", "#137FB9")}
`;
const ContentCountWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
  padding-left: 20px;
`;
const ContentCountBig = styled.div`
  ${fontSize(30, "B", "#00AFCE")}
`;
const ContentCountSmall = styled.div`
  ${fontSize(20, "L", "#00AFCE")}
`;
const ContentDescList = styled.ul`
  ${fontPSize(16, "R", "black")}
  padding-right: 20px;
`;
const ContentDesc = styled.li`
  margin-bottom: 10px;
`;
const ContentImageWrapper = styled.div`
  flex: 1;
  padding: 10px 0;
  background: #137fb9;
  margin-top: 10px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  right: 5px;
  z-index: 1;
  justify-content: flex-end;
`;

const Arrow = styled.img`
  margin: 5px;
  cursor: pointer;
  width: 8.5vw;
  min-width: 30px;
  background: #fff;
  border-radius: 100px;
`;

const HomeSlide = () => {
  const slideRef = useRef<SlideshowRef>(null);

  return (
    <section>
      <PageWrapper>
        <Layout>
          <SlideWrapper>
            <Fade
              ref={slideRef}
              arrows={false}
              indicators={false}
              transitionDuration={200}
            >
              {slideOptions.map((d, index) => (
                <ContentWrapper key={d.id}>
                  <ContentTextWrapper>
                    <ContentCountWrapper>
                      <ContentCountBig>0{index + 1}</ContentCountBig>
                      <ContentCountSmall>
                        /0{slideOptions.length}
                      </ContentCountSmall>
                    </ContentCountWrapper>
                    <ContentImageWrapper>
                      <img style={{ width: "100%" }} src={d.imageSrc} />
                    </ContentImageWrapper>
                    <ContentContentWrapper>
                      <ContentTitle>{d.title}</ContentTitle>
                      <ContentDescList>
                        {d.desc.map((_desc, _index) => (
                          <ContentDesc key={`desc_${_index}`}>
                            {_desc}
                          </ContentDesc>
                        ))}
                      </ContentDescList>
                    </ContentContentWrapper>
                  </ContentTextWrapper>
                </ContentWrapper>
              ))}
            </Fade>
            <ArrowWrapper>
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                onClick={() => slideRef.current?.goBack()}
              />
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                onClick={() => slideRef.current?.goNext()}
              />
            </ArrowWrapper>
          </SlideWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default HomeSlide;
