import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { fontPSize, fontSize, lineHeight } from "../styles/Font";

const SearchWrapper = styled.div`
  display: flex;
  gap: 10%;
  position: relative;
  width: 24%;
`;

const SearchSelectWrapper = styled.div<{ height?: string }>`
  position: relative;
  padding-bottom: ${(props) => (props.height ? props.height : "14%")};
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;
const SearchSelectView = styled.div<{ borderDisabled?: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border: ${(props) =>
    props.borderDisabled ? "none" : "1px solid rgba(119, 119, 119, 0.5)"};
  top: 0;
  flex-direction: column;
  cursor: pointer;
`;
const SearchSelect = styled.div<{
  borderDisabled?: boolean;
  imageWidth?: string;
}>`
  display: flex;
  align-items: center;
  ${(props) => (props.borderDisabled ? "" : "justify-content: space-around;")}
  flex: 1;
  ${fontPSize(18, "R", "#777777")}
  & > div {
    word-break: keep-all;
    white-space: nowrap;
  }
  & > img {
    width: 2vw;
    max-width: 30px;
  }
`;
const SearchSelectOptionWrapper = styled.div<{ top?: boolean }>`
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  width: 100%;
  left: 0px;
  ${(props) => (props.top ? "bottom: 96%;" : "top: 103%;")}
  z-index: 1;
  max-height: 30vh;
  overflow-y: scroll;
  &.hide {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchSelectOption = styled.div`
  width: calc(100% - 2px);
  height: 100%;
  padding: min(15%, 0.75vw) 0;
  border: 1px solid rgba(119, 119, 119, 0.5);
  border-top: 0px;
  background: #ffffff;
  cursor: pointer;
  ${fontPSize(18, "R", "#777777")}
  &:hover {
    background: rgba(0, 175, 206, 1);
    color: white;
  }
  &:first-child {
    border: 1px solid rgba(119, 119, 119, 0.5);
  }
  & > div {
    margin-left: min(10%, 0.75vw);
  }
`;

interface Props {
  search: any;
  selectOptions: any[];
  onChange: (option: any) => void;
  height?: string;
  borderDisabled?: boolean;
  imageWidth?: string;
  top?: boolean;
}
const Select = (props: Props) => {
  const {
    search,
    selectOptions,
    onChange,
    height,
    borderDisabled,
    imageWidth,
    top,
  } = props;
  const dropMenuRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
      if (isOpen && !dropMenuRef.current?.contains(e.target)) setIsOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isOpen]);

  return (
    <SearchSelectWrapper height={height} ref={dropMenuRef}>
      <SearchSelectView
        borderDisabled={borderDisabled}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <SearchSelect borderDisabled={borderDisabled}>
          <div>{search.label}</div>
          <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg" />
        </SearchSelect>
      </SearchSelectView>
      <SearchSelectOptionWrapper top={top} className={isOpen ? "" : "hide"}>
        {selectOptions.map((option) => (
          <SearchSelectOption
            key={`options_${option.value}_${option.label}`}
            onClick={() => {
              onChange(option);
              setIsOpen(false);
            }}
          >
            <div>{option.label}</div>
          </SearchSelectOption>
        ))}
      </SearchSelectOptionWrapper>
    </SearchSelectWrapper>
  );
};

export default Select;
