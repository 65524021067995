import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { SlideshowRef, Fade } from "react-slideshow-image";
import { fontSize } from "../../styles/Font";
import { videoOptions } from "../../utils/datas";

const BannerWrapper = styled.div`
  min-height: 60vh;
  position: relative;
`;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const VideoView = styled.video`
  width: 100%;
  height: 60vh;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
`;
const BannerTextWrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  // background-color: transparent;
  // background: linear-gradient(
  //   180deg,
  //   rgba(19, 133, 185, 0.9) 42.71%,
  //   rgba(19, 127, 185, 0) 100%
  // );
  // background-color: rgba(13, 114, 169, 0.4);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  display: flex;
  z-index: 1;
`;

const BannerTextContainer = styled.div`
  justify-content: flex-start;
  align-items: stretch;
  margin: 0px 20px;
  display: flex;
  position: relative;
  font-size: 70px;
  color: white;
  padding-top: min(25%, 100px);
`;

const TextGridContainer = styled.div``;

const TextGrid = styled.div`
  grid-area: span 1 / span 1 / span 1 / span 1;
  height: 100%;
  padding-right: 0px;
  position: relative;
`;
const TextTop = styled.div`
  ${fontSize(30, "L", "white")}
`;
const TextBottom = styled.div`
  ${fontSize(30, "B", "white")}
`;
const TextLogo = styled.div`
  padding-top: 5%;
  display: flex;
  & img {
    width: 47%;
  }
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  border-radius: 100px;
  width: 4vw;
  max-width: 60px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
    brightness(110%) contrast(100%);
`;
const IndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 50px;
  -webkit-box-align: center;
  align-items: center;
  z-index: 10;
  width: 100%;
  justify-content: center;
`;
const Indicators = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Indicator = styled.div`
  border: 1px solid #fff;
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin-right: 1vw;
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  &.active {
    background: #fff;
  }
`;
const PauseAndPlay = styled.img`
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  margin-left: 3vw;
  max-width: 20px;
  max-height: 20px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
    brightness(110%) contrast(100%);
`;
const HomeBanner = () => {
  const slideRef = useRef<SlideshowRef>(null);
  const videoRef = useRef<HTMLVideoElement[]>([]);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [play, setPlay] = useState<boolean>(true);
  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.goTo(slideIndex);
    }
    if (videoRef.current[slideIndex]) {
      if (play) {
        videoRef.current[slideIndex].play();
      } else {
        videoRef.current[slideIndex].pause();
        // videoRef.current[slideIndex].currentTime = 0;
      }

      videoRef.current.map((vRef, index) => {
        if (index !== slideIndex) {
          vRef.pause();
          vRef.currentTime = 0;
        }
      });
    }
  }, [slideIndex, videoRef, slideRef, play]);
  return (
    <section>
      <BannerWrapper>
        <Layout style={{ padding: 0 }}>
          <VideoWrapper>
            <Fade
              ref={slideRef}
              arrows={false}
              autoplay={false}
              transitionDuration={400}
              indicators={false}
            >
              {videoOptions.map((d, index) => (
                <VideoView
                  key={d.id}
                  ref={(el) => {
                    if (el) videoRef.current[index] = el;
                  }}
                  // poster="https://d17mmzdc0h66bb.cloudfront.net/image/video_poster.jpg"
                  src={d.src}
                  onEnded={() => {
                    setSlideIndex((prev) => (prev + 1) % videoOptions.length);
                  }}
                  muted
                  playsInline
                />
              ))}
            </Fade>
          </VideoWrapper>
          <IndicatorWrapper>
            <Arrow
              src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
              onClick={() => {
                if (slideIndex > 0) {
                  setSlideIndex((prev) => (prev + 1) % videoOptions.length);
                } else {
                  setSlideIndex(videoOptions.length - 1);
                }
              }}
            />
            <Indicators>
              {videoOptions.map((item: any, index: number) => (
                <Indicator
                  key={index}
                  className={slideIndex === index ? "active" : ""}
                  onClick={() => {
                    setSlideIndex(index);
                  }}
                />
              ))}
            </Indicators>
            <PauseAndPlay
              src={
                play
                  ? "https://d17mmzdc0h66bb.cloudfront.net/image/pause.svg"
                  : "https://d17mmzdc0h66bb.cloudfront.net/image/play.svg"
              }
              onClick={() => setPlay((prev) => !prev)}
            />
            <Arrow
              src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
              onClick={() =>
                setSlideIndex((prev) => (prev + 1) % videoOptions.length)
              }
            />
          </IndicatorWrapper>
          <BannerTextWrapper>
            <BannerTextContainer>
              <TextGridContainer>
                <TextGrid>
                  <TextTop>고객이 신뢰할 수 있는</TextTop>
                  <TextBottom>주류 유통 전문 기업</TextBottom>
                  <TextLogo>
                    <img src="https://d17mmzdc0h66bb.cloudfront.net/image/logo_white.png" />
                  </TextLogo>
                </TextGrid>
              </TextGridContainer>
            </BannerTextContainer>
          </BannerTextWrapper>
        </Layout>
      </BannerWrapper>
    </section>
  );
};

export default HomeBanner;
