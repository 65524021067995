export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const imageParser = (desc: string) => {
  const regexr = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/;
  const imageSrcArray = desc.match(regexr);
  const imageSrc = imageSrcArray ? imageSrcArray[1] : "";
  return imageSrc;
};
export const tagParser = (desc: string) => {
  const regexr2 = /(<([^>]+)>)/g;
  const removeTagText = desc.replace(regexr2, "");
  return removeTagText;
};
