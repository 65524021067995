import React, { useRef, useState } from "react";
import styled from "styled-components";
import HoverZoomView from "../../components/hoverZoom";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { Slide, SlideshowRef } from "react-slideshow-image";
import useContactStore from "../../stores/contactStore";
import { useNavigate } from "react-router";

const ItemWrapper = styled.div`
  background-color: #fff;
  padding: 50px 10px;
  position: relative;
  & .indicators {
    position: absolute;
    width: 100%;
    align-items: center;
    margin: 0;
    margin-top: 40px;
    padding: 0;
  }
`;
const ItemHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
`;
const ItemHeaderImageWrapper = styled.div`
  margin-right: 2.5vw;
  & > img {
    max-width: 100px;
    width: 7vw;
  }
`;
const ItemHeaderTitleWrapper = styled.div``;
const ItemHeaderTitle = styled.div`
  ${fontSize(60, "B", "black")}
`;
const ItemHeaderDesc = styled.div`
  ${fontSize(30, "L", "black")}
`;
const ItemContent = styled.div`
  flex: 1 1 0%;
  display: flex;
  position: relative;
  margin: 0.3vw;
`;
const ItemContentImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 30px;
  overflow: hidden;
  background-color: #000;
`;
const ItemContentImage = styled.img`
  width: 100%;
  height: 100%;
`;
const ItemContentText = styled.div`
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  mix-blend-mode: normal;
  margin-bottom: 0%;
  padding-bottom: 40px;
  padding-left: 40px;
  font-size: 40px;
  line-height: 48px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
  ${fontSize(40, "B", "white")}
`;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: relative;
`;
const ArrowWWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  width: 4vw;
  max-width: 60px;
`;

const Indicators = styled.div`
  position: absolute;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  margin: 40px 0px 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  height: 100%;
`;

const Indicator = styled.div`
  border: 1px solid #000;
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
  margin-right: 0.5vw;
  cursor: pointer;
  max-width: 14px;
  max-height: 14px;
  &.active {
    background: #000;
  }
`;

const HomeItem = () => {
  const slideRef = useRef<SlideshowRef>(null);
  const [indicator, setIndicator] = useState<number>(0);
  const { liquors } = useContactStore();
  const navigator = useNavigate();
  return (
    <section>
      <Layout>
        <ItemWrapper>
          <ItemHeaderWrapper>
            <ItemHeaderImageWrapper>
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/itemIcon.png" />
            </ItemHeaderImageWrapper>
            <ItemHeaderTitleWrapper>
              <ItemHeaderTitle>취급주류</ItemHeaderTitle>
              <ItemHeaderDesc>
                세왕상사의 취급주류 정보를 제공합니다.
              </ItemHeaderDesc>
            </ItemHeaderTitleWrapper>
          </ItemHeaderWrapper>
          {liquors.length > 0 && (
            <Slide
              ref={slideRef}
              slidesToShow={3}
              // indicators={() => <Indicator />}
              arrows={false}
              transitionDuration={200}
              onChange={(from, to) => {
                setIndicator(to);
              }}
              indicators={false}
            >
              {liquors
                .filter((d) => d.label !== "전체")
                .map((d) => {
                  return (
                    <ItemContent
                      onClick={() => {
                        navigator(
                          `/contact?index=0&prov_id=0&liquors_name=${d.label}`
                        );
                      }}
                      key={d.value}
                    >
                      <ItemContentImageWrapper>
                        <HoverZoomView>
                          <ItemContentImage src={d.image} />
                        </HoverZoomView>
                      </ItemContentImageWrapper>
                      <ItemContentText>{d.label}</ItemContentText>
                    </ItemContent>
                  );
                })}
            </Slide>
          )}
          <BottomWrapper>
            <Indicators>
              {liquors.length > 0 &&
                liquors
                  .filter((d) => d.label !== "전체")
                  .map((item, index) => (
                    <Indicator
                      key={index}
                      className={indicator === index ? "active" : ""}
                      onClick={() => {
                        slideRef.current?.goTo(index);
                      }}
                    />
                  ))}
            </Indicators>
            <ArrowWWrapper>
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                onClick={() => slideRef.current?.goBack()}
              />
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                onClick={() => slideRef.current?.goNext()}
              />
            </ArrowWWrapper>
          </BottomWrapper>
        </ItemWrapper>
      </Layout>
    </section>
  );
};

export default HomeItem;
