import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import HoverZoomView from "../../components/hoverZoom";
import { infoPanoramaData } from "../../utils/datas";

const PageWrapper = styled.div`
  padding: 10% 0;
`;
const HeaderWrapper = styled.div`
  padding: 5% 6%;
  ${fontSize(20, "M", "black")}
`;
const Header = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
`;
const ContentContainer = styled.div`
  width: calc(33% - 20px);
  padding-bottom: 33%;
  position: relative;
  display: flex;
  width: 50%;
  padding-bottom: 50%;
  &:nth-child(-n + 2) {
    width: 100%;
    padding-bottom: 50%;
  }
`;
const Content = styled.div`
  position: absolute;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentText = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 10px #000000cc;
  ${fontSize(18, "L", "white")}
`;
const InfoPanorama = () => {
  return (
    <section>
      <PageWrapper>
        <HeaderWrapper>
          <Header>회사 전경</Header>
        </HeaderWrapper>
        <ContentWrapper>
          {infoPanoramaData.map((d) => (
            <ContentContainer key={d.id}>
              <Content>
                <HoverZoomView>
                  <ContentImage src={d.mobile_image} />
                </HoverZoomView>
                <ContentText>{d.title}</ContentText>
              </Content>
            </ContentContainer>
          ))}
        </ContentWrapper>
      </PageWrapper>
    </section>
  );
};

export default InfoPanorama;
