import React from "react";
import styled from "styled-components";
import { fontPSize, fontSize } from "../../styles/Font";
import Layout from "../../containers/layout";
import { itemContentData } from "../../utils/datas";

const PageWrapper = styled.div`
  padding: 15% 0 0 0;
`;
const BackgroundView = styled.div<{ opacity?: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props) => `rgba(0, 0, 0, ${props.opacity || 0.2})`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  word-break: keep-all;
`;
const TopText = styled.div`
  ${fontSize(25, "L", "black")}
  padding: 5% 0;
  & > span {
    ${fontSize(25, "M", "black")}
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;
const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: fit-content;
  padding-bottom: 39%;
`;
const Content = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const ContentImage = styled.img`
  width: 100%;
  height: 100%;
`;
const ContentTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ContentTitle = styled.div`
  ${fontSize(16, "M", "white")}
  margin-bottom: 5%;
`;
const ContentDesc = styled.div`
  ${fontPSize(14, "R", "white")}
`;
const ItemContent = () => {
  return (
    <section>
      <Layout>
        <PageWrapper>
          <Wrapper>
            <TopText>
              저희 세왕상사 에서는 <span>주류 전 제품 판매</span>가 가능합니다
            </TopText>
          </Wrapper>
        </PageWrapper>
      </Layout>
      <ContentWrapper>
        {itemContentData.map((d) => (
          <ContentContainer key={d.id}>
            <Content>
              <BackgroundView opacity={d.opacity} />
              <ContentImage src={d.mobile_image} />
              <ContentTextWrapper>
                <ContentTitle>{d.title}</ContentTitle>
                <ContentDesc>{d.desc}</ContentDesc>
              </ContentTextWrapper>
            </Content>
          </ContentContainer>
        ))}
      </ContentWrapper>
    </section>
  );
};

export default ItemContent;
