import { create } from "zustand";

interface BoardState {
  boards: Board[];
  boardTotalCount: number;
  setBoards: (boards: Board[]) => void;
  setBoardTotalCount: (boardTotalCount: number) => void;
}

const useBoardStore = create<BoardState>()((set) => ({
  boards: [],
  boardTotalCount: 0,
  setBoards: (boards) => {
    set(() => ({ boards }));
  },
  setBoardTotalCount: (boardTotalCount) => {
    set(() => ({ boardTotalCount }));
  },
}));

export default useBoardStore;
