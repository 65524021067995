import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { infoBannerData } from "../../utils/datas";

const TopBanner = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.4);
`;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const VideoView = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`;
const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`;

const TopPageWrapper = styled.div`
  padding-bottom: 57%;
  position: relative;
`;
const TopBannerContent = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: absolute;
  bottom: 12%;
`;
const TopBannerTopText = styled.div`
  ${fontSize(30, "L", "white")}
`;
const TopBannerBottomText = styled.div`
  ${fontSize(36, "M", "white")}
`;
const MiddleBanner = styled.div`
  position: relative;
  background-color: #137fb9;
`;
const MiddlePageWrapper = styled.div`
  padding-bottom: 42%;
  position: relative;
`;
const MiddleBannerContainer = styled.div`
  padding: 0 20px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
`;
const MiddleBannerContent = styled.div`
  background: #fff;
  height: 117%;
  position: absolute;
  bottom: 17%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 0 20%;
  width: calc(100% - 40px);
`;
const MiddleBannerTopText = styled.div`
  ${fontSize(28, "L", "black")}
  text-align: center;
  width: 60%;
  margin-bottom: 4%;
  & > div {
    ${fontSize(34, "M", "#137fb9")}
  }
`;
const MiddleBannerMiddleText = styled.div`
  ${fontPSize(20, "R", "black")}
  word-break: keep-all;
  width: 60%;
  margin-bottom: 5%;
  ${lineHeight(36)}
`;
const MiddleBannerBottomText = styled.div`
  ${fontPSize(20, "B", "black")}
  text-align: center;
  width: 60%;
`;
const BottomBanner = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.4);
`;
const BottomPageWrapper = styled.div`
  padding-bottom: 49%;
  position: relative;
`;
const BottomBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-gap: 10%;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
`;
const BottomLogoWrapper = styled.div`
  width: 20%;
`;
const BottomLogo = styled.img`
  width: 100%;
`;
const BottomContentWrapper = styled.div`
  display: flex;
  grid-gap: 30px;
  width: 100%;
  height: 47%;
  position: relative;
`;
// const BottomContent = styled.div`
//   background: #f4f5fc;
//   flex: 1 1 0%;
//   border-radius: 100%;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   background: 1px solid #f4f5fc;
//   flex-direction: column;
//   grid-gap: 10%;
// `;
// const BottomImage = styled.img``;
// const BottomTitle = styled.div`
//   ${fontSize(18, "L", "#565656")}
// `;
// const BottomDescWrapper = styled.div`
//   height: 20%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
// `;
// const BottomDesc = styled.div`
//   ${fontSize(25, "M", "#000")}
// `;
// const BottomDescSmall = styled.div`
//   ${fontSize(20, "L", "#000")}
//   ${lineHeight(30)}
// `;

const BottomContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const BottomContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10%;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70%;
`;
const BottomContainer = styled.div`
  background: #f4f5fc;
  padding-bottom: 100%;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BottomImage = styled.img`
  width: 30%;
  max-width: 100px;
`;
const BottomTitle = styled.div`
  ${fontPSize(18, "R", "#565656")}
  margin: 5% 0;
`;
const BottomDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const BottomDesc = styled.div`
  ${fontSize(25, "M", "#000")}
`;
const BottomDescSmall = styled.div`
  ${fontPSize(20, "R", "#000")}
  ${lineHeight(30)}
`;

const InfoBanner = () => {
  return (
    <section>
      <TopBanner>
        <VideoWrapper>
          <VideoView
            poster="https://d17mmzdc0h66bb.cloudfront.net/image/info_poster.jpg"
            src="https://d17mmzdc0h66bb.cloudfront.net/video/info_video.mp4"
            autoPlay
            loop
            muted
            playsInline
          />
        </VideoWrapper>
        <Layout style={{ position: "relative" }}>
          <TopPageWrapper>
            <TopBannerContent>
              <TopBannerTopText>
                고객이 신뢰할 수 있는 주류 유통 전문 기업
              </TopBannerTopText>
              <TopBannerBottomText>세왕상사(주)</TopBannerBottomText>
            </TopBannerContent>
          </TopPageWrapper>
        </Layout>
      </TopBanner>
      <MiddleBanner>
        <Layout style={{ position: "relative" }}>
          <MiddlePageWrapper>
            <MiddleBannerContainer>
              <MiddleBannerContent>
                <MiddleBannerTopText>
                  세왕상사 홈페이지를 방문해 주신 여러분,안녕하십니까.
                  <div>세왕상사 대표이사 정민성입니다.</div>
                </MiddleBannerTopText>
                <MiddleBannerMiddleText>
                  먼저 세왕상사 주식회사에 아낌없는 사랑과 격려를 주신 고객
                  여러분께진심으로 감사의 말씀을 드립니다. 저희 세왕상사는
                  1978년 창립 이래고객의 관심과 성원으로 매년 끊임없이 성장을
                  거듭해 왔으며,현재 서울 및 수도권 지역의 주류유통을 선도하는
                  종합주류유통전문기업으로 도약하였습니다. <br />
                  그러나 저희 세왕상사는 이에 만족하지 않고 변화와 혁신에
                  힘쓰며,핵심역량 및 인프라 강화에 끊임없는 투자를 통하여 21세기
                  주류유통의 선두기업으로 제2의 도약을 준비하고
                  있습니다.&nbsp;&nbsp;“웃음을 전하는 명품 서비스” 세왕상사는
                  고객의 믿음을 바탕으로한 기업,최고이면서도 정직한 서비스
                  기업으로 고객님들의 사업 파트너에 그치지 않고 인생의 동반자가
                  될 것을 약속드립니다.
                </MiddleBannerMiddleText>
                <MiddleBannerBottomText>
                  세왕상사 대표이사 정 민 성
                </MiddleBannerBottomText>
              </MiddleBannerContent>
            </MiddleBannerContainer>
          </MiddlePageWrapper>
        </Layout>
      </MiddleBanner>
      <BottomBanner>
        <VideoWrapper>
          <ImageView src="https://d17mmzdc0h66bb.cloudfront.net/image/info_back.png" />
        </VideoWrapper>
        <Layout style={{ position: "relative" }}>
          <BottomPageWrapper>
            <BottomBannerContent>
              <BottomLogoWrapper>
                <BottomLogo src="https://d17mmzdc0h66bb.cloudfront.net/image/logo_white.png" />
              </BottomLogoWrapper>
              <BottomContentWrapper>
                {infoBannerData.map((d) => (
                  <BottomContentContainer key={`banner_${d.id}`}>
                    <BottomContainer>
                      <BottomContent>
                        <BottomImage src={d.image} />
                        <BottomTitle>{d.title}</BottomTitle>
                        <BottomDescWrapper>
                          {d.desc.map((_desc, index) =>
                            index === 0 ? (
                              <BottomDesc key={`desc_${index}`}>
                                {_desc}
                              </BottomDesc>
                            ) : (
                              <BottomDescSmall key={`desc_${index}`}>
                                {_desc}
                              </BottomDescSmall>
                            )
                          )}
                        </BottomDescWrapper>
                      </BottomContent>
                    </BottomContainer>
                  </BottomContentContainer>
                ))}
              </BottomContentWrapper>
            </BottomBannerContent>
          </BottomPageWrapper>
        </Layout>
      </BottomBanner>
    </section>
  );
};

export default InfoBanner;
