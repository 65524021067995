import React from "react";
import { Default, Mobile } from "../styles/responsive";
import InfoBanner from "../sections/info/infoBanner";
import InfoManagement from "../sections/info/infoManagement";
import InfoMotto from "../sections/info/infoMotto";
import InfoCi from "../sections/info/infoCi";
import InfoPanorama from "../sections/info/infoPanorama";
import InfoPlace from "../sections/info/infoPlace";
import MInfoBanner from "../mobileSections/info/infoBanner";
import MInfoManagement from "../mobileSections/info/infoManagement";
import MInfoMotto from "../mobileSections/info/infoMotto";
import MInfoCi from "../mobileSections/info/infoCi";
import MInfoPanorama from "../mobileSections/info/infoPanorama";
import MInfoPlace from "../mobileSections/info/infoPlace";

const InfoPage = () => {
  return (
    <>
      <Default>
        <InfoBanner />
        <InfoManagement />
        <InfoMotto />
        <InfoCi />
        <InfoPanorama />
        <InfoPlace />
      </Default>
      <Mobile>
        <MInfoBanner />
        <MInfoManagement />
        <MInfoMotto />
        <MInfoCi />
        <MInfoPanorama />
        <MInfoPlace />
      </Mobile>
    </>
  );
};

export default InfoPage;
