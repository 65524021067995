import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import useInterval from "../../hooks/useInterval";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";

const DescWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const TypingTextWrapper = styled.div`
  // height: 15vh;
  // height: 80px;
  height: calc(${30}px + (100vw * 2 - 360px) * (${30} / (360)));
  margin-bottom: 20px;
`;
const TypingText = styled.pre`
  display: inline;
  animation: typing-cursor 0.7s 0s infinite;
  ${fontSize(25, "B", "white")}
`;

const DescText = styled.pre`
  white-space: pre-wrap;
  word-break: keep-all;
  ${fontPSize(16, "R", "white")}
  ${lineHeight(30)}
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.2s ease-in;
  &.close {
    max-height: 162px;
  }
`;

const TextWrapper = styled.div`
  padding: 60px 0;
`;
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70vh;
  z-index: -100;
`;
const ImageView = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(19, 127, 185, 0.9);
  z-index: -100;
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, rgba(19, 127, 185, 0) 0%, #137fb9 100%);
  bottom: calc(30px + 8.5%);
`;

const ArrowWrapper = styled.div<{ close: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  background: #137fb9;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 15px 0;
  & > img {
    width: 8.5%;
    min-width: 30px;
    ${(props) => (props.close ? "" : "transform: rotate(180deg);")}
  }
  ${(props) => (props.close ? "" : "")}
`;
const HomeDesc = () => {
  const [count, setCount] = useState(0);
  const [landingTitle, setLandingTitle] = useState("");
  const [close, setClose] = useState(true);
  const sectionRef = useRef<HTMLInputElement | null>(null);
  const { inViewPort, percentage } = useScrollAnimation(sectionRef, {
    min: 30,
    max: 300,
    log: true,
  });
  const completedTitle = "고객이 신뢰할 수 있도록\n저희는 세왕상사입니다.";
  const descText =
    "빠르고 친절하게, 정확한 배송을 실시하며 고객의 이익을 최우선으로 배려하기 위해 끈임없는 고민과 노력을 다하고 있습니다.\n언제나 고객 입장에서 분석하여 고객의 이익 창출을 위해 책임감을 가지고 최대한의 효과를 만들 수 있도록 도움을 드리겠습니다.\n믿음 있는 파트너가 되고자 열심히 노력하는 모습 지켜봐 주시고 지속적인 관심 부탁드립니다. 감사합니다.";
  useInterval(
    () => {
      if (count >= completedTitle.length) {
        return;
      }

      setLandingTitle((prev) => {
        let result = prev ? prev + completedTitle[count] : completedTitle[0];
        setCount((prev) => prev + 1);
        return result;
      });
    },
    1000 / 30,
    inViewPort && percentage > 0
  );

  return (
    <section ref={sectionRef}>
      <DescWrapper>
        <ImageWrapper>
          <ImageView src="https://d17mmzdc0h66bb.cloudfront.net/image/descBack.jpg" />
        </ImageWrapper>
        <BackgroundView />
        {close && <Overlay />}
        <Layout>
          <TextWrapper>
            <TypingTextWrapper>
              <TypingText>{landingTitle}</TypingText>
            </TypingTextWrapper>
            <DescText className={close ? "close" : ""}>{descText}</DescText>
            <ArrowWrapper
              close={close}
              onClick={() => setClose((prev) => !prev)}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg" />
            </ArrowWrapper>
          </TextWrapper>
        </Layout>
      </DescWrapper>
    </section>
  );
};

export default HomeDesc;
