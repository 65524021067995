import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { ciData } from "../../utils/datas";

const PageWrapper = styled.div`
  position: relative;
`;
const PageBackWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const PageBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 159, 187, 0.9);
  z-index: -100;
`;
const MainWrapper = styled.div`
  padding: 10% 0;
`;
const HeaderWrapper = styled.div`
  ${fontSize(34, "M", "white")}
  padding: 3% 0;
`;
const Header = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
`;
const DescWrapper = styled.div`
  flex: 4;
`;
const Desc = styled.div`
  ${fontPSize(22, "R", "white")}
  ${lineHeight(36)}

&.span {
    ${fontPSize(22, "B", "white")}
  }
`;
const CiWrapper = styled.div`
  flex: 6;
  display: flex;
  position: relative;
`;
// const CiContainer = styled.div`
//   position: relative;
//   padding-bottom: 33%;
//   flex: 1;
//   &:first-child > div {
//     left: 20%;
//   }
//   &:last-child > div {
//     left: -20%;
//   }
// `;
// const CiContent = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-radius: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgb(255, 255, 255, 0.9);
//   flex-direction: column;
//   grid-gap: 20px;

//   &:nth-child(1) > img {
//     width: 55%;
//   }
// `;
// const CiImage = styled.img`
//   width: 45%;
// `;

const CiContainer = styled.div`
  position: relative;
  flex: 1;
  &:first-child > div {
    left: 23%;
    & > div > img {
      width: 45%;
    }
  }
  &:last-child > div {
    left: -23%;
    & > div > img {
      width: 45%;
    }
  }
`;
const CiContent = styled.div`
  position: relative;
  padding-bottom: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
`;
const CIImageWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const CiImage = styled.img`
  width: 50%;
`;

const InfoCi = () => {
  return (
    <section>
      <PageWrapper>
        <PageBackWrapper>
          <PageBackground src="https://d17mmzdc0h66bb.cloudfront.net/image/info_ci_back.jpg" />
        </PageBackWrapper>
        <BackgroundView />
        <Layout>
          <MainWrapper>
            <HeaderWrapper>
              <Header>CI 소개</Header>
            </HeaderWrapper>
            <ContentWrapper>
              <DescWrapper>
                <Desc>
                  <span className="text-wrapper">세왕상사의 CI는 </span>
                  <span className="span">
                    두 마리의 용이 서로 협력하여 승천하는 모습
                  </span>
                  <span className="text-wrapper">
                    을 형상화 한 것으로 고객과 함께 성장하려는 의지를 담고
                    있습니다.
                    <br />
                    &#34;용&#34;은 신성한 힘을 지닌 상서로운 존재로 과거로부터
                    &#39;왕&#39;을 상징하는 상상의 영수(領袖)로 숭배 되었으며,
                    우리나라의 사신도에는 바다를 지키는 수호신으로 등장하기도
                    합니다. <br />
                    고객과 세왕상사를 두 마리의 용으로 형상화하여 함께 승천하는
                    모습을 표현함으로써{" "}
                  </span>
                  <span className="span">고객과 함께 발전 하겠다는 의지</span>
                  <span className="text-wrapper">를 표현하고 있습니다.</span>
                </Desc>
              </DescWrapper>
              <CiWrapper>
                {ciData.map((d) => (
                  <CiContainer key={d.id}>
                    <CiContent>
                      <CIImageWrapper>
                        <CiImage src={d.ciImage} />
                      </CIImageWrapper>
                    </CiContent>
                  </CiContainer>
                ))}
              </CiWrapper>
            </ContentWrapper>
          </MainWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoCi;
