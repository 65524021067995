import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import useInterval from "../../hooks/useInterval";
import Layout from "../../containers/layout";
import { fontSize, lineHeight } from "../../styles/Font";

const DescWrapper = styled.div`
  position: relative;
`;

const TypingTextWrapper = styled.div`
  height: 15vh;
  max-height: 160px;
`;
const TypingText = styled.pre`
  display: inline;
  animation: typing-cursor 0.7s 0s infinite;
  ${fontSize(60, "B", "white")}
`;

const DescText = styled.pre`
  ${fontSize(25, "L", "white")}
  ${lineHeight(40)}
  white-space: pre-wrap;
  word-break: keep-all;
  width: 60%;
`;

const TextWrapper = styled.div`
  padding: 200px 10px;
`;
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
const ImageView = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
`;
const BackgroundView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(19, 127, 185, 0.9);
  z-index: -100;
`;
const HomeDesc = () => {
  const [count, setCount] = useState(0);
  const [landingTitle, setLandingTitle] = useState("");
  const sectionRef = useRef<HTMLInputElement | null>(null);
  const { inViewPort, percentage } = useScrollAnimation(sectionRef, {
    min: 30,
    max: 300,
    log: true,
  });
  const completedTitle = "고객이 신뢰할 수 있도록\n저희는 세왕상사입니다.";
  const descText =
    "빠르고 친절하게, 정확한 배송을 실시하며 고객의 이익을 최우선으로 배려하기 위해 끈임없는 고민과 노력을 다하고 있습니다.\n언제나 고객 입장에서 분석하여 고객의 이익 창출을 위해 책임감을 가지고 최대한의 효과를 만들 수 있도록 도움을 드리겠습니다.\n믿음 있는 파트너가 되고자 열심히 노력하는 모습 지켜봐 주시고 지속적인 관심 부탁드립니다. 감사합니다.";
  useInterval(
    () => {
      if (count >= completedTitle.length) {
        return;
      }

      setLandingTitle((prev) => {
        let result = prev ? prev + completedTitle[count] : completedTitle[0];
        setCount((prev) => prev + 1);
        return result;
      });
    },
    1000 / 30,
    inViewPort && percentage > 0
  );

  return (
    <section ref={sectionRef}>
      <DescWrapper>
        <ImageWrapper>
          <ImageView src="https://d17mmzdc0h66bb.cloudfront.net/image/descBack.jpg" />
        </ImageWrapper>
        <BackgroundView />
        <Layout>
          <TextWrapper>
            <TypingTextWrapper>
              <TypingText>{landingTitle}</TypingText>
            </TypingTextWrapper>
            <DescText>{descText}</DescText>
          </TextWrapper>
        </Layout>
      </DescWrapper>
    </section>
  );
};

export default HomeDesc;
