import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import useModalStore from "../../stores/modalStore";
import { ciData } from "../../utils/datas";

const PageWrapper = styled.div`
  position: relative;
`;
const PageBackWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: static;
`;
const PageBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
`;
const MainWrapper = styled.div`
  padding: 15% 0px;
`;
const HeaderWrapper = styled.div`
  ${fontSize(20, "M", "white")}
  padding: 5% 0px;
`;
const Header = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
  background: #fff;
  border-radius: 5vw;
  padding: 6%;
  gap: 6%;
`;

const CiContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
`;

const CiImage = styled.img`
  width: 100%;
`;

const MoreWrapper = styled.div`
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5%;
  margin: 5% 0;
  ${fontSize(14, "M", "white")}
  & > span {
    margin-left: 3%;
    & > img {
      transform: rotate(270deg);
    }
  }
`;
const ModalWrapper = styled.div`
  padding: 5% 0;
`;
const DescWrapper = styled.div`
  padding: 5% 0;
`;
const Desc = styled.div`
  ${fontPSize(16, "R", "black")}
  ${lineHeight(25)}
& > .span {
    ${fontPSize(16, "B", "black")}
  }
`;

const InfoCi = () => {
  const { showModal } = useModalStore();

  const ModalContent = (
    <ModalWrapper>
      <ContentWrapper>
        {ciData.map((d) => (
          <CiContent key={d.id}>
            <CiImage src={d.ciImage} />
          </CiContent>
        ))}
      </ContentWrapper>
      <DescWrapper>
        <Desc>
          <span className="text-wrapper">세왕상사의 CI는 </span>
          <span className="span">
            두 마리의 용이 서로 협력하여 승천하는 모습
          </span>
          <span className="text-wrapper">
            을 형상화 한 것으로 고객과 함께 성장하려는 의지를 담고 있습니다.
            <br />
            &#34;용&#34;은 신성한 힘을 지닌 상서로운 존재로 과거로부터
            &#39;왕&#39;을 상징하는 상상의 영수(領袖)로 숭배 되었으며,
            우리나라의 사신도에는 바다를 지키는 수호신으로 등장하기도 합니다.{" "}
            <br />
            고객과 세왕상사를 두 마리의 용으로 형상화하여 함께 승천하는 모습을
            표현함으로써{" "}
          </span>
          <span className="span">고객과 함께 발전 하겠다는 의지</span>
          <span className="text-wrapper">를 표현하고 있습니다.</span>
        </Desc>
      </DescWrapper>
    </ModalWrapper>
  );
  return (
    <section>
      <PageWrapper>
        <PageBackWrapper>
          <PageBackground src="https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_ci_back.png" />
        </PageBackWrapper>
        <Layout>
          <MainWrapper>
            <HeaderWrapper>
              <Header>CI 소개</Header>
            </HeaderWrapper>
            <ContentWrapper>
              {ciData.map((d) => (
                <CiContent key={d.id}>
                  <CiImage src={d.ciImage} />
                </CiContent>
              ))}
            </ContentWrapper>
            <MoreWrapper onClick={() => showModal(ModalContent)}>
              설명 자세히 보기
              <span>
                <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg" />
              </span>
            </MoreWrapper>
          </MainWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoCi;
