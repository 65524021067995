const font = {
  AggroL: (size: number, color: "white" | "black") => {
    return {
      // fontSize: `${size / 16}rem`,
      fontSize: `calc(${size}px + (100vw - 1920px) * ${size} / 1920)`,
      fontFamily: "SB_Aggro_L",
      color: color,
    };
  },
  AggroM: (size: number, color: "white" | "black") => {
    return {
      // fontSize: `${size / 16}rem`,
      fontSize: `calc(${size}px + (100vw - 1920px) * ${size} / 1920)`,
      fontFamily: "SB_Aggro_M",
      color: color,
    };
  },
  AggroB: (size: number, color: "white" | "black") => {
    return {
      // fontSize: `${size / 16}rem`,
      fontSize: `calc(${size}px + (100vw - 1920px) * ${size} / 1920)`,
      fontFamily: "SB_Aggro_B",
      color: color,
    };
  },
};

export const fontSize = (
  size: number,
  fontFamily: "B" | "M" | "L",
  color?: string,
  lineHeight?: number
) => {
  return `
  font-size: ${size}px;
  font-family: SB_Aggro_${fontFamily};
  color: ${color || "black"};
  @media screen and (min-width: 360px) {
    font-size: calc(${size / 2}px + (100vw * 2 - 360px) * (${
    size / 2
  } / (360)));
    ${
      lineHeight &&
      `font-size: calc(16px + (100vw - 767px) * ((${lineHeight} - 16) / (1440 - 767)));;`
    }
  }
  @media screen and (min-width: 767px) {
    font-size: calc(${size / 2}px + (100vw - 767px) * (${
    size / 2
  } / (1440 - 767)));
    ${
      lineHeight &&
      `font-size: calc(16px + (100vw - 767px) * ((${lineHeight} - 16) / (1440 - 767)));;`
    }
  }
  @media screen and (min-width: 1440px) {
    font-size: ${size}px;
  }
  `;
};

export const lineHeight = (acc: number) => {
  return `
  line-height: ${acc}px;
  @media screen and (min-width: 360px) {
    line-height: calc(${acc / 2}px + (100vw * 2 - 360px) * (${
    acc / 2
  } / (360)));
  }
  @media screen and (min-width: 767px) {
    line-height: calc(16px + (100vw - 767px) * ((${acc} - 16) / (1440 - 767)));;
  }
  @media screen and (min-width: 1440px) {
    line-height: ${acc}px;
  }
  `;
};

export const fontPSize = (
  size: number,
  fontFamily: "B" | "M" | "R",
  color?: string,
  lineHeight?: number
) => {
  return `
  font-size: ${size}px;
  font-family: Pretendard-Variable-${fontFamily};
  color: ${color || "black"};
  @media screen and (min-width: 360px) {
    font-size: calc(${size / 2}px + (100vw * 2 - 360px) * (${
    size / 2
  } / (360)));
    ${
      lineHeight &&
      `font-size: calc(16px + (100vw - 767px) * ((${lineHeight} - 16) / (1440 - 767)));;`
    }
  }
  @media screen and (min-width: 767px) {
    font-size: calc(${size / 2}px + (100vw - 767px) * (${
    size / 2
  } / (1440 - 767)));
    ${
      lineHeight &&
      `font-size: calc(16px + (100vw - 767px) * ((${lineHeight} - 16) / (1440 - 767)));;`
    }
  }
  @media screen and (min-width: 1440px) {
    font-size: ${size}px;
  }
  `;
};

export default font;
