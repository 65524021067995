import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { infoBannerData } from "../../utils/datas";
import { useNavigate } from "react-router";

const TopBanner = styled.div`
  position: relative;
  background: rgba(8, 84, 124, 1);
`;
const TopPageWrapper = styled.div`
  ${fontPSize(14, "M", "white")}
  padding: 2% 0;
  display: flex;
  align-items: center;
`;
const MiddleBanner = styled.div`
  position: relative;
  background-color: #137fb9;
`;
const MiddlePageWrapper = styled.div`
  padding: 13% 0;
`;
const MiddleBannerContent = styled.div`
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.2s;
  &.close {
    max-height: 270px;
  }
`;
const MiddleBannerTopText = styled.div`
  ${fontSize(25, "L", "white")}
  padding: 20px 0;
  & > div > span {
    ${fontSize(25, "M", "white")}
  }
`;
const MiddleBannerMiddleText = styled.div`
  ${fontPSize(16, "R", "white")}
  ${lineHeight(25)}
`;
const MiddleBannerBottomText = styled.div`
  ${fontPSize(16, "B", "white")}
  padding:20px 0;
`;
const BottomBanner = styled.div`
  position: relative;
  background: #fff;
`;
const BottomPageWrapper = styled.div`
  padding: 10% 0;
`;
const BottomHeaderWrapper = styled.div`
  ${fontSize(20, "M", "black")}
  padding: 5% 0;
`;
const BottomHeader = styled.div``;
const BottomContentWrapper = styled.div`
  display: flex;
  gap: 5vw;
  flex-direction: column;
`;
const BottomContentContainer = styled.div`
  flex: 1;
  position: relative;
  background: rgb(244, 245, 252);
  border-radius: 2.5vw;
  padding: 6% 5%;
`;
const BottomContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5vw;
`;
const BottomImageWrapper = styled.div`
  flex: 1;
  position: relative;
`;
const BottomImage = styled.img`
  width: 100%;
`;
const BottomTextWrapper = styled.div`
  flex: 5 1 0%;
  display: flex;
  flex-direction: column;
  gap: 2vw;
`;
const BottomTitle = styled.div`
  ${fontPSize(14, "R", "#565656")}
`;
const BottomDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomDesc = styled.div`
  ${fontSize(16, "M", "#000")}
`;
const BottomDescSmall = styled.div`
  ${fontSize(14, "L", "#000")}
`;
const ArrowWrapper = styled.div<{ close: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  background: #137fb9;
  width: 100%;
  padding: 15px 0;
  & > img {
    width: 8.5%;
    min-width: 30px;
    ${(props) => (props.close ? "" : "transform: rotate(180deg);")}
  }
  ${(props) => (props.close ? "" : "")}
`;
const InfoBanner = () => {
  const [close, setClose] = useState(true);
  const navigator = useNavigate();

  return (
    <section>
      <TopBanner>
        <Layout style={{ position: "relative" }}>
          <TopPageWrapper>
            <img
              style={{
                cursor: "pointer",
                filter:
                  "invert(100%) sepia(0%) saturate(7450%) hue-rotate(191deg) brightness(300%) contrast(96%)",
              }}
              onClick={() => {
                navigator("/");
              }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/home.svg"
            />
            <img
              style={{ filter: "brightness(100)" }}
              src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg"
            />
            <div>회사소개</div>
          </TopPageWrapper>
        </Layout>
      </TopBanner>
      <MiddleBanner>
        <Layout style={{ position: "relative" }}>
          <MiddlePageWrapper>
            <MiddleBannerContent className={close ? "close" : ""}>
              <MiddleBannerTopText>
                안녕하십니까.
                <div>세왕상사 대표이사</div>
                <div>
                  <span>정민성</span>입니다.
                </div>
              </MiddleBannerTopText>
              <MiddleBannerMiddleText>
                먼저 세왕상사 주식회사에 아낌없는 사랑과 격려를 주신 고객
                여러분께진심으로 감사의 말씀을 드립니다. 저희 세왕상사는 1978년
                창립 이래고객의 관심과 성원으로 매년 끊임없이 성장을 거듭해
                왔으며,현재 서울 및 수도권 지역의 주류유통을 선도하는
                종합주류유통전문기업으로 도약하였습니다. <br />
                그러나 저희 세왕상사는 이에 만족하지 않고 변화와 혁신에
                힘쓰며,핵심역량 및 인프라 강화에 끊임없는 투자를 통하여 21세기
                주류유통의 선두기업으로 제2의 도약을 준비하고
                있습니다.&nbsp;&nbsp;“웃음을 전하는 명품 서비스” 세왕상사는
                고객의 믿음을 바탕으로한 기업,최고이면서도 정직한 서비스
                기업으로 고객님들의 사업 파트너에 그치지 않고 인생의 동반자가 될
                것을 약속드립니다.
              </MiddleBannerMiddleText>
              <MiddleBannerBottomText>
                세왕상사 대표이사 정 민 성
              </MiddleBannerBottomText>
            </MiddleBannerContent>
            <ArrowWrapper
              close={close}
              onClick={() => setClose((prev) => !prev)}
            >
              <img src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_right.svg" />
            </ArrowWrapper>
          </MiddlePageWrapper>
        </Layout>
      </MiddleBanner>
      <BottomBanner>
        <Layout style={{ position: "relative" }}>
          <BottomPageWrapper>
            <BottomHeaderWrapper>
              <BottomHeader>회사 정보</BottomHeader>
            </BottomHeaderWrapper>
            <BottomContentWrapper>
              {infoBannerData.map((d) => (
                <BottomContentContainer key={`banner_${d.id}`}>
                  <BottomContent>
                    <BottomImageWrapper>
                      <BottomImage src={d.mobile_image} />
                    </BottomImageWrapper>
                    <BottomTextWrapper>
                      <BottomTitle>{d.title}</BottomTitle>
                      <BottomDescWrapper>
                        {d.desc.map((_desc, index) =>
                          index === 0 ? (
                            <BottomDesc key={`desc_${index}`}>
                              {_desc}
                            </BottomDesc>
                          ) : (
                            <BottomDescSmall key={`desc2_${index}`}>
                              {_desc}
                            </BottomDescSmall>
                          )
                        )}
                      </BottomDescWrapper>
                    </BottomTextWrapper>
                  </BottomContent>
                </BottomContentContainer>
              ))}
            </BottomContentWrapper>
          </BottomPageWrapper>
        </Layout>
      </BottomBanner>
    </section>
  );
};

export default InfoBanner;
