import React, { useEffect, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize, lineHeight } from "../../styles/Font";
import { useLocation, useNavigate } from "react-router";
import { imageParser, tagParser } from "../../utils/string";
import useBoardStore from "../../stores/boardStore";
import { apiFetch } from "../../utils/fetch";
import queryString from "query-string";
import useAuthStore from "../../stores/authStore";

const PageWrapper = styled.div`
  padding: 0 0px 20% 0;
`;
const Wrapper = styled.div`
  padding-bottom: 7%;
`;

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WrapperHeaderText = styled.div`
  ${fontSize(18, "M", "black")}
`;
const Container = styled.div`
  display: flex;
  gap: 6%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 47%;
  position: relative;
  padding-bottom: 28%;
  margin: 1.5% 0;
  cursor: pointer;
`;
const ContentImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 36%;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const ContentTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 90%;
  width: 94%;
  background-color: #f3f5fb;
  display: flex;
  justify-content: flex-end;
`;

const ContentTextContainer = styled.div`
  width: 55%;
  margin: 5% 7%;
  overflow: hidden;
`;
const ContentDate = styled.div`
  ${fontPSize(18, "R", "rgba(86, 86, 86, 1)")}
`;
const ContentTitle = styled.div`
  ${fontSize(22, "M", "black")}
  padding: 3% 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;
const ContentDesc = styled.div`
  padding-top: 8%;
  ${fontSize(20, "L", "#777777")}
  ${lineHeight(38)}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const PageNationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
  margin: auto;
  gap: 5%;
`;
const PageNationContent = styled.div`
  cursor: pointer;
`;
const PageNationText = styled.div`
  ${fontSize(16, "M", "#b1b1b1")}
  &.active {
    ${fontSize(16, "M", "rgba(19, 127, 185, 1)")}
  }
`;

const PageNationIcon = styled.img`
  width: 100%;

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(270deg);
  }
`;

const EditButton = styled.div`
  ${fontSize(16, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1% 2%;
`;

const NewsContentView = () => {
  const { search } = useLocation();
  const navigator = useNavigate();
  const { loginState } = useAuthStore();
  const { boards, boardTotalCount, setBoards, setBoardTotalCount } =
    useBoardStore();
  const [index, setIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(1); // 총페이지수

  useEffect(() => {
    const _query =
      search && search !== "" ? queryString.parse(search) : undefined;
    if (_query?.index !== undefined && _query.index !== null) {
      const _index = parseInt(_query.index.toString());
      const type = _query.type || "";
      const searchText = _query.search || "";
      apiFetch(
        `/api/v2.0/boardPageApi?type=${type}&search=${searchText}&offset=${
          _index * 6
        }&limit=6`,
        "GET"
      ).then((data) => {
        setBoards(data[0]);
        setBoardTotalCount(data[1]);
      });
      setIndex(_index);
      window.scrollTo(0, 0);
    }
  }, [search]);

  useEffect(() => {
    setMaxIndex(Math.ceil(boardTotalCount / 6));
  }, [boardTotalCount]);

  const renderPageNation = () => {
    const page = Math.floor(index / 10);
    const result = [];
    const first = page * 10;
    const last = page * 10 + 10;
    if (index > 0) {
      result.push(
        <PageNationContent
          key={"prev"}
          onClick={() => navigator(`/news?index=${index - 1}`)}
        >
          <PageNationIcon
            className="left"
            src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
          />
        </PageNationContent>
      );
    }
    for (let i = first; i < last; i++) {
      if (i + 1 > maxIndex) break;
      result.push(
        <PageNationContent
          key={`page_${i}`}
          onClick={() => {
            navigator(`/news?index=${i}`);
          }}
        >
          <PageNationText className={index === i ? "active" : ""}>
            {i + 1}
          </PageNationText>
        </PageNationContent>
      );
    }
    if (index < maxIndex - 1) {
      result.push(
        <PageNationContent
          key={"next"}
          onClick={() => navigator(`/news?index=${index + 1}`)}
        >
          <PageNationIcon
            className="right"
            src="https://d17mmzdc0h66bb.cloudfront.net/image/chevron_down.svg"
          />
        </PageNationContent>
      );
    }

    return result;
  };
  return (
    <section>
      <Layout>
        <PageWrapper>
          <Wrapper>
            <WrapperHeader>
              <WrapperHeaderText>
                총 개시글({boardTotalCount})
              </WrapperHeaderText>
              {loginState && (
                <EditButton onClick={() => navigator("/newsEdit")}>
                  글쓰기
                </EditButton>
              )}
            </WrapperHeader>
            <Container>
              {boards.map(
                (d) =>
                  d && (
                    <Content
                      onClick={() => {
                        navigator(`/news?id=${d.id}`);
                      }}
                      key={d.id}
                    >
                      <ContentTextWrapper>
                        <ContentTextContainer>
                          <ContentDate>{d.date}</ContentDate>
                          <ContentTitle>{d.title}</ContentTitle>
                          <ContentDesc>{tagParser(d.desc)}</ContentDesc>
                        </ContentTextContainer>
                      </ContentTextWrapper>
                      <ContentImageWrapper>
                        <img src={imageParser(d.desc)} />
                      </ContentImageWrapper>
                    </Content>
                  )
              )}
            </Container>
          </Wrapper>
          <PageNationWrapper>{renderPageNation()}</PageNationWrapper>
        </PageWrapper>
      </Layout>
    </section>
  );
};

export default NewsContentView;
