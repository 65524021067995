import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { Slide, SlideshowRef } from "react-slideshow-image";
import { partnerData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: rgba(74, 80, 107, 1);
`;
const BottomBannerWrapper = styled.div`
  padding: 15px 10px;
  position: relative;
  // display: flex;
  align-items: center;
`;
const BottomBannerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BottomBannerTitle = styled.div`
  ${fontSize(14, "L", "white")}
`;
const SlideWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 10px 0;
`;
const ItemContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const ItemContentImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 30px;
  max-width: min(25vw, 170px);
  object-fit: contain;
`;

const ArrowWWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  background: #fff;
  width: 8.5vw;
  min-width: 30px;
  border-radius: 100px;
`;

const HomeBottomBanner = () => {
  const slideRef = useRef<SlideshowRef>(null);

  return (
    <section>
      <PageWrapper>
        <Layout>
          <BottomBannerWrapper>
            <BottomBannerHeader>
              <BottomBannerTitle>고객사 및 협력사</BottomBannerTitle>
              <ArrowWWrapper>
                <Arrow
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                  onClick={() => slideRef.current?.goBack()}
                />
                <Arrow
                  src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                  onClick={() => slideRef.current?.goNext()}
                />
              </ArrowWWrapper>
            </BottomBannerHeader>
            <SlideWrapper>
              <Slide
                ref={slideRef}
                slidesToShow={3}
                indicators={false}
                arrows={false}
                transitionDuration={200}
              >
                {partnerData().map((d) => (
                  <ItemContent key={d.id}>
                    <ItemContentImage src={d.imageSrc} />
                  </ItemContent>
                ))}
              </Slide>
            </SlideWrapper>
          </BottomBannerWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default HomeBottomBanner;
