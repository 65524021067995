import React, { useEffect, useState } from "react";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { fontPSize, fontSize } from "../../styles/Font";
import Select from "../../components/select";
import { useLocation, useNavigate } from "react-router";
import useBottomSheetStore from "../../stores/bottomSheetStore";
import useContactStore from "../../stores/contactStore";
import { apiFetch } from "../../utils/fetch";
import queryString from "query-string";
import _ from "lodash";

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 7%;

  padding: 2% 5%;
  width: 90%;
`;
const SearchTitle = styled.div`
  ${fontSize(14, "L", "black")}
  display: flex;
  width: 100%;
  justify-content: center;
`;
const SelectContainer = styled.div`
  width: 100%;
  & > div > div:first-child {
    width: 90%;
    padding: 0 5%;
    & > div {
      justify-content: space-between;
      ${fontPSize(14, "R", "#777777")}
      & > img {
        width: 7%;
      }
    }
  }
  & > div > div:last-child {
    max-height: 40vh;
    overflow: hidden scroll !important;
    // -webkit-overflow-scrolling: touch;
    & > div {
      ${fontPSize(14, "R", "#777777")}
      width: calc(90% - 2px);
      height: auto;
      padding: 2% 5%;
    }
  }
`;
const SearchButton = styled.div`
  ${fontSize(14, "M", "white")}
  background: rgba(0, 175, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 5% 0;
`;

const ContactSearch = () => {
  const { search } = useLocation();
  const { hideBottomSheet } = useBottomSheetStore();
  const navigator = useNavigate();
  const {
    provs,
    dos,
    sigungoos,
    liquors,
    setProvs,
    setDos,
    setSigungoos,
    setLiquors,
  } = useContactStore();
  const [sigungoo, setSigungoo] = useState({
    value: "0",
    label: "전지역",
  });
  const [liquor, setLiquor] = useState({
    value: "0",
    label: "전체",
  });
  const [selectDo, setSelectDo] = useState({ value: "0", label: "전지역" });
  useEffect(() => {
    apiFetch("/api/v2.0/province", "GET").then((data) => {
      setProvs(data);
      const _dos = _.filter(data, (prov) => prov.value.length <= 2);
      setDos(_dos);
    });
    apiFetch("/api/v2.0/liquors", "GET").then((data) => {
      setLiquors(data);
    });
  }, []);
  useEffect(() => {
    const _query = queryString.parse(search);
    if (_query.prov_id) {
      const _prov_id = _query.prov_id;
      if (_prov_id.length <= 2) {
        const _selectDo = provs.find((prov) => prov.value === _prov_id);
        _selectDo && setSelectDo(_selectDo);
      } else {
        const _selectDo = provs.find(
          (prov) => prov.value === _prov_id.slice(0, 2)
        );
        _selectDo && setSelectDo(_selectDo);
        const _sigungoo = provs.find((prov) => prov.value === _prov_id);
        _sigungoo && setSigungoo(_sigungoo);
      }
    }
    if (_query.liquors_name) {
      const liquor_name = _query.liquors_name;
      const _liquor = liquors.find((liquor) => liquor.label === liquor_name);
      _liquor && setLiquor(_liquor);
    }
  }, [search, provs, liquors]);

  useEffect(() => {
    const _sigungoo = _.filter(
      provs,
      (prov) => prov.value.indexOf(selectDo.value) === 0 || prov.value === "0"
    );
    setSigungoos(_sigungoo);
  }, [selectDo, provs]);

  const searchFn = () => {
    const url = `/contact?index=0&prov_id=${
      sigungoo.value !== "0" ? sigungoo.value : selectDo.value
    }&liquors_name=${liquor.label !== "전체" ? liquor.label : ""}`;
    navigator(url);
    hideBottomSheet();
  };
  return (
    <section className="bottom_sheet" style={{ overflow: "visible" }}>
      <SearchWrapper>
        <SelectWrapper>
          <SearchTitle>담당자 검색</SearchTitle>
        </SelectWrapper>
        <SelectWrapper>
          {/* <SelectLabel>도 구분</SelectLabel> */}
          <SelectContainer>
            <Select
              imageWidth={"27%"}
              top
              height={"10%"}
              selectOptions={dos}
              search={selectDo}
              onChange={(option) => {
                setSelectDo(option);
                setSigungoo({ value: "0", label: "전지역" });
              }}
            />
          </SelectContainer>
        </SelectWrapper>
        <SelectWrapper>
          {/* <SelectLabel>시/군/구 구분</SelectLabel> */}
          <SelectContainer>
            <Select
              height={"10%"}
              top
              imageWidth={"27%"}
              selectOptions={sigungoos}
              search={sigungoo}
              onChange={(option) => setSigungoo(option)}
            />
          </SelectContainer>
        </SelectWrapper>
        <SelectWrapper>
          {/* <SelectLabel>주종 구분</SelectLabel> */}
          <SelectContainer>
            <Select
              height={"10%"}
              top
              imageWidth={"27%"}
              selectOptions={liquors}
              search={liquor}
              onChange={(option) => setLiquor(option)}
            />
          </SelectContainer>
        </SelectWrapper>
        <SelectWrapper style={{ paddingBottom: "10%" }}>
          <SearchButton onClick={searchFn}>검색</SearchButton>
        </SelectWrapper>
      </SearchWrapper>
    </section>
  );
};

export default ContactSearch;
