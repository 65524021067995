import React from "react";
import { Default, Mobile } from "../styles/responsive";
import ItemHeader from "../sections/item/itemHeader";
import ItemContent from "../sections/item/itemContent";
import ItemFooter from "../sections/item/itemFooter";
import MItemHeader from "../mobileSections/item/itemHeader";
import MItemContent from "../mobileSections/item/itemContent";
import MItemFooter from "../mobileSections/item/itemFooter";
const ItemPage = () => {
  return (
    <>
      <Default>
        <ItemHeader />
        <ItemContent />
        <ItemFooter />
      </Default>
      <Mobile>
        <MItemHeader />
        <MItemContent />
        <MItemFooter />
      </Mobile>
    </>
  );
};

export default ItemPage;
