import { useRef } from "react";

// Toast 에디터
import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
interface Props {
  content: string;
  editorRef: React.MutableRefObject<any>;
}

export default function ToastViewer({ content = " ", editorRef }: Props) {
  return (
    <Viewer
      ref={editorRef} // DOM 선택용 useRef
      initialValue={content}
    ></Viewer>
  );
}
