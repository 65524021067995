import React from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { infoManagementData } from "../../utils/datas";

const PageWrapper = styled.div`
  background: #fff;
  padding: 50px 0;
`;
const HeaderWrapper = styled.div`
  margin: 40px 0;
`;
const Header = styled.div`
  ${fontSize(34, "M", "black")}
`;
const ContentWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const Content = styled.div`
  padding-bottom: 142%;

  &.down {
    transform: translateY(10%);
  }
`;
const ContentImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;
const ContentTitle = styled.div`
  position: absolute;
  bottom: 10%;
  left: 0;
  font-size: 25px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  ${fontSize(25, "M", "white")}
`;

const InfoManagement = () => {
  return (
    <section>
      <PageWrapper>
        <Layout>
          <HeaderWrapper>
            <Header>경영 방침</Header>
          </HeaderWrapper>
          <ContentWrapper>
            {infoManagementData.map((d, index) => (
              <ContentContainer key={d.id}>
                <Content className={index % 2 === 1 ? "down" : ""}>
                  <ContentImage src={d.image} />
                  <ContentTitle>{d.title}</ContentTitle>
                </Content>
              </ContentContainer>
            ))}
          </ContentWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default InfoManagement;
