import useWindowSize from "../hooks/useWindowSize";
export const mobileBreakPoint = 767;

export const Mobile = ({ children }: { children: any }) => {
  const size = useWindowSize();
  return size && (size > mobileBreakPoint ? null : children);
};

export const Default = ({ children }: { children: any }) => {
  const size = useWindowSize();
  return size && (size > mobileBreakPoint ? children : null);
};
