import { useRef } from "react";

// Toast 에디터
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";

interface Props {
  content?: string;
  editorRef: React.MutableRefObject<any>;
}

export default function ToastEditor({ content = " ", editorRef }: Props) {
  const onUploadImage = async (blob: any, callback: any) => {
    console.log(blob);
    let send_data = new FormData();
    send_data.append("file", blob);
    const uploadurl = "/api/upload";
    fetch(uploadurl, {
      method: "POST",
      body: send_data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.path);
        callback(res.path);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Editor
      ref={editorRef} // DOM 선택용 useRef
      placeholder="내용을 입력해주세요."
      previewStyle="vertical" // 미리보기 스타일 지정
      height="800px" // 에디터 창 높이
      initialEditType="wysiwyg" //
      initialValue={content}
      hideModeSwitch={true}
      toolbarItems={[
        // 툴바 옵션 설정
        ["heading", "bold", "italic", "strike"],
        ["image"],
      ]}
      useCommandShortcut={false} // 키보드 입력 컨트롤 방지
      hooks={{
        addImageBlobHook: onUploadImage,
      }}
    ></Editor>
  );
}
