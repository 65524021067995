import { create } from "zustand";

interface ContactState {
  provs: SelectType[];
  dos: SelectType[];
  sigungoos: SelectType[];
  liquors: (SelectType & { image: string })[];
  contactPeoples: ContactPeople[];
  contactPeopleTotalCount: number;
  setContactPeoples: (contactPeoples: ContactPeople[]) => void;
  setContactPeopleTotalCount: (contactPeopleTotalCount: number) => void;
  setProvs: (provs: SelectType[]) => void;
  setDos: (dos: SelectType[]) => void;
  setSigungoos: (sigungoos: SelectType[]) => void;
  setLiquors: (liquors: (SelectType & { image: string })[]) => void;
}

const useContactStore = create<ContactState>()((set) => ({
  provs: [],
  dos: [],
  sigungoos: [],
  liquors: [],
  contactPeoples: [],
  contactPeopleTotalCount: 0,
  setContactPeoples: (contactPeoples) => {
    set(() => ({ contactPeoples }));
  },
  setContactPeopleTotalCount: (contactPeopleTotalCount) => {
    set(() => ({ contactPeopleTotalCount }));
  },
  setProvs: (provs) => {
    set(() => ({ provs }));
  },
  setDos: (dos) => {
    set(() => ({ dos }));
  },
  setSigungoos: (sigungoos) => {
    set(() => ({ sigungoos }));
  },
  setLiquors: (liquors) => {
    set(() => ({ liquors }));
  },
}));

export default useContactStore;
