import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import useCountNum from "../../hooks/useCountNum";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { numberWithCommas } from "../../utils/string";
import { apiFetch } from "../../utils/fetch";

const BottleWrapper = styled.div`
  background-color: #fff;
  font-size: 50px;
  position: relative;
  margin: 60px 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const TextContent = styled.div`
  padding: 8px 0;
  ${fontSize(16, "L", "black")}
`;
const TextCount = styled.div`
  padding: 8px 0;
  ${fontSize(30, "B", "black")}
`;

const HomeBottle = () => {
  // const animationRef = useScrollAnimation({})
  const sectionRef = useRef<HTMLInputElement | null>(null);
  const { inViewPort, percentage } = useScrollAnimation(sectionRef, {
    min: 30,
    max: 130,
  });
  const { setIsStart, count, setEnd } = useCountNum();

  useEffect(() => {
    apiFetch("api/v2.0/count", "GET").then((data) => {
      setEnd(data.result.bottleCount);
    });
  }, []);

  useEffect(() => {
    if (inViewPort) {
      if (percentage > 0) {
        setIsStart(true);
      }
    }
  }, [inViewPort, percentage]);
  
  return (
    <section ref={sectionRef}>
      <BottleWrapper>
        <Layout>
          <TextWrapper>
            <TextContent>오늘의 주류 출고수</TextContent>
            <TextCount>{numberWithCommas(count)}</TextCount>
          </TextWrapper>
        </Layout>
      </BottleWrapper>
      {/* <BottleWrapper {...animationRef}>HomeBottle</BottleWrapper> */}
    </section>
  );
};

export default HomeBottle;
