export const searchTypeOptions = [
  {
    value: "title",
    label: "제목",
  },
  {
    value: "desc",
    label: "내용",
  },
];

export const doOptions = [
  {
    value: "11",
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/seoul.jpeg",
    label: "서울",
  },
  {
    value: "41",
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/gyunggi.jpg",
    label: "경기/인천",
  },
  {
    value: "30",
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/daejean.webp",
    label: "대전",
  },
  {
    value: "44",
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/chungnam.avif",
    label: "충남",
  },
  {
    value: "43",
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/chungbuk.jpg",
    label: "충북",
  },
];
export const sigungooOptions = [
  {
    value: "0",
    label: "전체",
  },
  {
    value: "10010",
    label: "용산구",
  },
  {
    value: "10020",
    label: "중구",
  },
];

export const partnerData = () => {
  const result = [];
  for (let i = 1; i <= 36; i++) {
    result.push({
      id: i,
      imageSrc: `https://d17mmzdc0h66bb.cloudfront.net/company_logo/company_logo_${i}.png`,
    });
  }
  return result;
};
export const videoOptions = [
  {
    id: 1,
    src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video01.mp4",
    poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster01.jpg",
  },
  // {
  //   id: 2,
  //   src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video02.mp4",
  //   poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster02.jpg",
  // },
  {
    id: 3,
    src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video03.mp4",
    poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster03.jpg",
  },
  {
    id: 4,
    src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video04.mp4",
    poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster04.jpg",
  },
  {
    id: 5,
    src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video05.mp4",
    poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster05.jpg",
  },
  {
    id: 6,
    src: "https://d17mmzdc0h66bb.cloudfront.net/video/home_video06.mp4",
    poster: "https://d17mmzdc0h66bb.cloudfront.net/image/home_poster06.jpg",
  },
];

export const slideOptions = [
  {
    id: 1,
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/slide1.jpg",
    title: "유통 질서 준수",
    desc: [
      "원칙에 입각한 유통질서 준수",
      "최고의 신용과 A/S",
      "2015년 서울 지방 국세청 모범 납세자 수상 기업",
    ],
  },
  {
    id: 2,
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/slide2.jpg",
    title: "인재양성",
    desc: [
      "원칙에 입각한 유통질서 준수",
      "임직원 내,외부 교육을 통한 우수 인재 양성",
    ],
  },
  {
    id: 3,
    imageSrc: "https://d17mmzdc0h66bb.cloudfront.net/image/slide3.jpg",
    title: "양질의 판매",
    desc: [
      "국내 도매장 최고의 물류 시스템 구축",
      "양질의 거래처 확보",
      "서울 및 경기지역 주요 상권에 대한 경쟁 우위력 확보",
    ],
  },
];

export const infoBannerData = [
  {
    id: 1,
    title: "회사명",
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_1.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_apartment.png",
    desc: ["세왕상사(주)"],
  },
  {
    id: 2,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_2.png",
    image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/info_calendar_month.png",
    title: "창립일",
    desc: ["1978/04/20"],
  },
  {
    id: 3,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_3.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_cases.png",
    title: "대표이사",
    desc: ["정민성"],
  },
  {
    id: 4,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_4.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_liquor.png",
    title: "사업영역",
    desc: ["종합주류 도매업", "(맥주/소주/양주/사케/기타주류)"],
  },
];

export const ciData = [
  {
    id: 1,
    ciImage: "https://d17mmzdc0h66bb.cloudfront.net/image/ci_image1.png",
  },
  {
    id: 2,
    ciImage: "https://d17mmzdc0h66bb.cloudfront.net/image/ci_image2.png",
  },
  {
    id: 3,
    ciImage: "https://d17mmzdc0h66bb.cloudfront.net/image/ci_image3.png",
  },
];

export const infoManagementData = [
  {
    id: 1,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_management_1.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/management_1.png",
    title: "양질의 판매",
  },
  {
    id: 2,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_management_2.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/management_2.png",
    title: "최고의 신용과 A/S",
  },
  {
    id: 3,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_management_3.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/management_3.png",
    title: "유통질서 절대준수",
  },
  {
    id: 4,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_management_4.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/management_4.png",
    title: "부드러운 대화",
  },
  {
    id: 5,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_management_5.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/management_5.png",
    title: "거래처 확보",
  },
];

export const infoMottoData = [
  {
    id: 1,
    subTitle: "첫 번째,",
    title: "책임을 완수하자.",
    desc: "세왕상사는 어떠한 상황에서도 맡은 책임을 완수하는 자세에서부터 고객의 신뢰가 시작된다고 생각합니다. 그렇기 때문에 고객의 약속에 어긋나는 일이 없도록 주인의식을 갖고 항상 책임을 완수하는 기업이 되겠습니다.",
  },
  {
    id: 2,
    subTitle: "두 번째,",
    title: "최선을 다하자.",
    desc: "현재 각 부서의 전문적인 직원들이 직접 상담 및 도움을 드리고 있으며, 원활하고 유기적인 관계를 위해 최선을 다하고 있습니다. 최고의 서비스를 창출하여 사랑받는 기업이 되도록 노력하겠습니다.",
  },
  {
    id: 3,
    subTitle: "세 번째,",
    title: "서로 돕자.",
    desc: "세왕상사의 젊은 사고방식과 협력을 바탕으로 지금의 세왕상사가 되었다는 점을 잊지 않고 전직원들이 서로 돕는 경영방식을 통해 더욱 차별화 되고 고객의 곁에서 한걸음 더 다가가는 최고의 기업이 되겠습니다.",
  },
];

export const infoPanoramaData = [
  {
    id: 1,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_1.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_1.jpg",
    title: "사옥",
  },
  {
    id: 2,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_2.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_2.jpg",
    title: "하역장",
  },
  {
    id: 3,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_3.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_3.jpg",
    title: "지하창고",
  },
  {
    id: 4,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_4.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_4.jpg",
    title: "사무실",
  },
  {
    id: 5,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_5.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_5.jpg",
    title: "식당",
  },
  {
    id: 6,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_info_panorama_6.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/info_panorama_6.jpg",
    title: "교육강당",
  },
];

export const itemContentData = [
  {
    id: 1,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content1.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content1.png",
    title: "와인",
    desc: "레드, 화이트 등 모든 종류의 와인",
    style: {
      right: "0",
      bottom: "0",
      width: "75%",
    },
  },
  {
    id: 2,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content2.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content2.png",
    title: "보드카, 위스키",
    desc: "보드카, 위스키 등 모든 양주",
    style: {
      left: "0",
      top: "40%",
      width: "86%",
    },
  },
  {
    id: 3,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content3.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content3.png",
    title: "국산맥주",
    desc: "모든 국내 생산 맥주",
    opacity: 0.5,
    style: {
      right: "0",
      top: "0",
      width: "90%",
    },
  },
  {
    id: 4,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content4.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content4.png",
    title: "소주",
    desc: "모든 국내 증류주 종류",
    style: {
      left: "0",
      top: "21%",
      width: "67%",
    },
  },
  {
    id: 5,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content5.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content5.png",
    title: "수입맥주",
    desc: "모든 해외 수입맥주",
    style: {
      right: "0",
      top: "-13%",
      width: "52%",
    },
  },
  {
    id: 6,
    mobile_image:
      "https://d17mmzdc0h66bb.cloudfront.net/image/mobile_item_content6.png",
    image: "https://d17mmzdc0h66bb.cloudfront.net/image/item_content6.png",
    title: "사케",
    desc: "모든 일본주 종류",
    style: {
      left: "0",
      bottom: "15%",
      width: "56%",
    },
  },
];
