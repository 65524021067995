import { create } from "zustand";
import { apiFetch } from "../utils/fetch";
import { redirect } from "react-router";

interface AuthState {
  loginState: boolean;
  setLoginState: (loginState: boolean) => void;
  checkAuth: () => void;
}

const useAuthStore = create<AuthState>()((set) => ({
  loginState: false,
  setLoginState: (loginState) => {
    set(() => ({ loginState }));
  },
  checkAuth: () => {
    apiFetch("/api/v2.0/auth", "PUT").then((data) => {
      if (!data.loginState) {
        set(() => ({ loginState: false }));
        localStorage.removeItem("loginState");
        if (data.message) {
          alert(data.message);
        }
        redirect("/");
      }
    });
  },
}));

export default useAuthStore;
