import React from "react";
import styled from "styled-components";
import useModalStore from "../stores/modalStore";
import Layout from "./layout";

const ModalContainer = styled.div`
  position: absolute;
  z-index: 999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ModalBackground = styled.div`
  position: absolute;
  z-index: 999998;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ModalWrapper = styled.div`
  z-index: 999999;
  background: #fff;
  border-radius: 5vw;
  display: flex;
  flex-direction: column;
  padding: 5%;
  position: sticky;
  top: 5%;
  bottom: 5%;
  overflow: visible;
  max-width: 1440px;
  margin: auto;
`;
const ModalCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ModalContent = styled.div<{ overflow?: boolean }>`
  overflow: ${(props) => (props.overflow ? "visible" : "scroll")};
  max-height: 75vh;
`;

const Modal = () => {
  const { isShow, modalData, hideModal, overflow } = useModalStore();
  return isShow ? (
    <ModalContainer>
      <ModalBackground onClick={() => hideModal()} />
      <ModalWrapper>
        <ModalCloseWrapper onClick={() => hideModal()}>
          <img src="https://d17mmzdc0h66bb.cloudfront.net/image/close.png" />
        </ModalCloseWrapper>
        <ModalContent overflow={overflow}>{modalData}</ModalContent>
      </ModalWrapper>
    </ModalContainer>
  ) : (
    <div></div>
  );
};

export default Modal;
