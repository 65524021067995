import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../../containers/layout";
import { fontSize } from "../../styles/Font";
import { Slide, SlideshowRef, Fade } from "react-slideshow-image";
import { slideOptions } from "../../utils/datas";

const PageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 175, 206, 0) 0%,
    rgba(0, 175, 206, 0.3) 100%
  );
`;
const SlideWrapper = styled.div`
  position: relative;
  padding: 100px 10px;

  & ul.indicators {
    position: absolute;
    bottom: 100px;
    left: 10px;
    padding: 0;
    z-index: 1;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
`;
const ContentTextWrapper = styled.div`
  flex: 1;
`;
const ContentTitle = styled.div`
  color: rgb(19, 127, 185) !important;
  ${fontSize(60, "B", "white")}
`;
const ContentCountWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  color: rgb(0, 175, 206) !important;
  margin-right: 40px;
  min-height: 60px;
  ${fontSize(40, "B", "white")}
`;
const ContentCountBig = styled.div`
  ${fontSize(120, "B", "rgb(0, 175, 206)")}
`;
const ContentCountSmall = styled.div`
  ${fontSize(40, "L", "rgb(0, 175, 206)")}
`;
const ContentDescList = styled.ul`
  ${fontSize(25, "L", "black")}
`;
const ContentDesc = styled.li``;
const ContentImageWrapper = styled.div`
  flex: 1;
  padding: 10px 0;
  background: #137fb9;
`;

const ArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 1;
`;

const Arrow = styled.img`
  margin: 10px;
  cursor: pointer;
  width: 4vw;
  max-width: 60px;
`;

const Indicator = styled.div`
  border: 1px solid #000;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  margin-right: 10px;
  cursor: pointer;
  max-width: 14px;
  max-height: 14px;
  &.active {
    background: #000;
  }
`;

const HomeSlide = () => {
  const slideRef = useRef<SlideshowRef>(null);

  return (
    <section>
      <PageWrapper>
        <Layout>
          <SlideWrapper>
            <Fade
              ref={slideRef}
              arrows={false}
              transitionDuration={200}
              indicators={() => <Indicator />}
            >
              {slideOptions.map((d, index) => (
                <ContentWrapper key={d.id}>
                  <ContentTextWrapper>
                    <ContentCountWrapper>
                      <ContentCountBig>0{index + 1}</ContentCountBig>
                      <ContentCountSmall>
                        /0{slideOptions.length}
                      </ContentCountSmall>
                    </ContentCountWrapper>
                    <ContentTitle>{d.title}</ContentTitle>
                    <ContentDescList>
                      {d.desc.map((_desc, i) => (
                        <ContentDesc key={`desc${i}`}>{_desc}</ContentDesc>
                      ))}
                    </ContentDescList>
                  </ContentTextWrapper>
                  <ContentImageWrapper>
                    <img style={{ width: "100%" }} src={d.imageSrc} />
                  </ContentImageWrapper>
                </ContentWrapper>
              ))}
            </Fade>
            <ArrowWrapper>
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideBack.svg"
                onClick={() => slideRef.current?.goBack()}
              />
              <Arrow
                src="https://d17mmzdc0h66bb.cloudfront.net/image/slideNext.svg"
                onClick={() => slideRef.current?.goNext()}
              />
            </ArrowWrapper>
          </SlideWrapper>
        </Layout>
      </PageWrapper>
    </section>
  );
};

export default HomeSlide;
